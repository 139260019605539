.featured-carousel {
    $slide-margin__desktop: 25px;
    $slide-margin__mobile: $indent__s;

    [data-content-type="column-group"] {
        $mobile-overlap: 30px;

        [data-content-type="column"]:first-child {
            background: $smoke-gradient;
            box-shadow: $midnight-shadow;

            h1,
            h2 {
                margin: 0;
            }

            h4 {
                line-height: $line-height__base;
            }

            .pagebuilder-button-link {
                padding: 0;
                color: $midnight;
                font-weight: $font-weight__bold;
                text-transform: uppercase;

                @include hover {
                    color: $azule;
                }
            }

            @include max-screen($screen__m) {
                width: 80%;
                flex-basis: 80%;
                padding: 20px 20px calc(#{$mobile-overlap} + 20px) !important; // override Page Builder
            }

            @include min-screen($screen__m) {
                margin-right: -#{$slide-margin__desktop} !important; // override Page Builder
                width: calc(25% + #{$slide-margin__desktop}) !important; // override Page Builder
            }
        }

        [data-content-type="column"]:last-child {
            .slick-list::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                width: 5px;
                height: 100%;
                background: linear-gradient(to top, $ice, #6DD2FD);
                box-shadow: -5px 0 20px 25px rgba($smoke, .6);
            }

            .slick-arrow {
                position: absolute;

                // Desktop arrows:
                @include min-screen($screen__m) {
                    left: -50px;
                    top: unset;

                    &.slick-prev {
                        bottom: 80px;
                    }

                    &.slick-next {
                        bottom: 40px;
                    }
                }

                // Mobile arrows:
                @include max-screen($screen__m) {
                    top: -50px;
                    right: calc((20% - 25px) / 2);
                    left: unset;

                    &.slick-prev {
                        top: -80px;
                    }

                    &.slick-next {
                        top: -40px;
                    }
                }
            }

            .slick-slide {
                margin: 0 0 0 $slide-margin__desktop;

                @include max-screen($screen__m) {
                    margin: 0 $slide-margin__mobile;
                }

                .product-item {
                    box-shadow: -10px 10px 13px -2px rgba($midnight, .1);
                }

                .product-item-inner {
                    display: none; // hide action buttons
                }
            }

            @include max-screen($screen__m) {
                margin-top: -#{$mobile-overlap} !important; // override Page Builder
            }

            // News slider:
            [data-content-type="fullslider"] {
                color: $white;

                h2 {
                    @include gradient-heading();
                    margin-top: 15px;
                    margin-bottom: 15px;
                }

                .pagebuilder-button-link {
                    padding: 0 !important; // override Page Builder
                    color: $white;
                }

                @include max-screen($screen__m) {
                    .slick-list::after {
                        display: none;
                    }
                }

                .slick-track {
                    float: left;

                    .slick-slide {
                        box-shadow: -10px 13px 16px $midnight;
                    }
                }

                .pagebuilder-overlay {
                    align-items: flex-end !important; // override Page Builder
                    width: 100%;
                    background: linear-gradient(to top, #13171C, transparent);
                    background-position: 0 80px;
                    background-repeat: no-repeat;
                    transition: background-position 100ms;

                    @include hover {
                        background-position: 0 0;

                        h2 {
                            @include gradient-heading($active: true);
                        }

                        .pagebuilder-button-link {
                            color: $ice;
                        }
                    }
                }
            }
        }
    }

    [data-content-type="products"] .slick-track {
        display: flex;
        align-items: stretch;

        .slick-slide {
            height: unset;

            > div,
            .product-item {
                height: 100%;
            }
        }
    }

    // Dark appearance:
    &.dark {
        [data-content-type="column-group"] {
            [data-content-type="column"]:first-child {
                background: $midnight-gradient;
                color: $white;
            }
            [data-content-type="column"]:last-child {
                .slick-list::after {
                    box-shadow: -5px 0 30px 30px rgba($midnight, .6);
                }

                .slick-dots li:not(.slick-active) button {
                    background: $white;
                }
            }
        }
    }
}
