.pagebuilder-faq {
    [data-content-type="heading"] {
        color: $white;
    }

    [data-content-type="image"] img {
        border-radius: 10px !important; // override Page Builder
        box-shadow: $midnight-shadow;
    }

    [data-content-type="faq"] {
        margin: 0 15px !important; // override Page Builder
        list-style: none;
        color: $white;

        [data-content-type="faq-item"] {
            position: relative;
            margin-bottom: $indent__s !important; // overide Page Builder
            padding: $indent__s $indent__s $indent__s 20px !important; // override Page Builder

            &.expanded::before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 2px;
                background: $ice-gradient;
            }
        }

        [data-element="question"] {
            line-height: 26px;
            cursor: pointer;
        }

        [data-element="answer"] {
            margin-top: $indent__s !important; // overide Page Builder
            line-height: 24px;

            a {
                color: $ice;
                font-weight: $font-weight__bold;
                word-break: break-all;

                @include hover {
                    color: $white;
                }
            }
        }
    }

    @include max-screen($screen__m) {
        [data-content-type="heading"] {
            margin-bottom: 30px !important; // override Page Builder
        }

        [data-content-type="column-group"] [data-content-type="column"] {
            &:first-child {
                order: 2;
            }

            &:last-child {
                order: 1;
            }
        }

        [data-content-type="faq"] {
            margin: 0 !important; // override Page Builder
        }

        [data-element="question"] {
            @include lib-heading(h2);
        }
    }
}
