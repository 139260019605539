.table-wrapper {
    margin-bottom: $indent__base;
}

table {
    @include lib-table-caption();
}

th {
    text-align: left;
}

th,
td {
    padding: $indent__s;
    vertical-align: top;
}

.table {
    &:not(.cart) {
        &:not(.totals) {
            @include lib-table-bordered(
                $_table_type        : light,
                $_table_border-width: $table__border-width
            );

            tfoot {
                > tr {
                    &:first-child {
                        th,
                        td {
                            border-top: $table__border-width $table__border-style $table__border-color;
                            padding-top: $indent__base;
                        }
                    }
                }

                .mark {
                    font-weight: $font-weight__regular;
                    text-align: right;
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {
    .table-wrapper {
        @include lib-table-overflow();
        position: relative; // To hide unnecessary horizontal scrollbar in Safari

        .table:not(.cart):not(.totals):not(.table-comparison) {
            &:not(.additional-attributes) {
                @include lib-table-responsive();
            }

            tbody > tr > td {
                &:first-child {
                    padding-top: $indent__base;
                }

                &:last-child {
                    border-bottom: $table__border-width $table__border-style $table__border-color;
                    padding-bottom: $indent__base;
                }
            }

            &.additional-attributes {
                tbody {
                    td {
                        &:last-child {
                            border: none;
                            padding: $indent__xs 0;
                        }
                    }
                }
            }
        }

        .table {
            &:not(.totals) {
                &:not(.table-comparison) {
                    tfoot {
                        display: block;

                        tr {
                            @include lib-clearfix();
                            display: block;

                            &:first-child {
                                th,
                                td {
                                    padding-top: $indent__base;
                                }
                            }
                        }

                        th {
                            box-sizing: border-box;
                            float: left;
                            padding-left: 0;
                            padding-right: 0;
                            text-align: left;
                            width: 70%;
                        }

                        td {
                            box-sizing: border-box;
                            float: left;
                            padding-left: 0;
                            padding-right: 0;
                            text-align: right;
                            width: 30%;
                        }
                    }
                }
            }
        }
    }

    .data-table-definition-list {
        thead {
            display: none;
        }

        tbody th {
            padding-bottom: 0;
        }

        tbody th,
        tbody td {
            display: block;
            padding-left: 0;
            padding-right: 0;
        }
    }
}
