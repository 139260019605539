.featured-reviews {
    [data-content-type="column-group"] {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    [data-content-type="column"] {
        $image-diameter: 100px;
        $image-offset-left: 30px;
        $image-offset-top: $image-diameter / 2;
        $image-border-width: 8px;

        position: relative;
        margin: $image-offset-top 12.5px 25px !important; // override Page Builder
        padding: $image-offset-left !important; // override Page Builder
        max-width: 100%;
        color: $white;
        background: linear-gradient(45deg, $deep-sea 0%, $midnight 100%);
        box-shadow: $midnight-shadow;

        @include max-screen($screen__m) {
            border-radius: 4px !important; // override Page Builder
        }

        [data-content-type='image'] {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -#{$image-offset-top};
            left: 30px;
            height: $image-diameter;
            width: $image-diameter;
            border-radius: 50%;
            background: $ice;

            img {
                border-radius: 50% !important; // override Page Builder
                height: calc(100% - #{$image-border-width});
                width: calc(100% - #{$image-border-width});
            }
        }

        .username,
        h2 {
            margin-top: 0;
            padding-left: $image-diameter + 20px;
            font-size: 22px;
            color: $ice;
        }

        .review,
        h4 {
            margin: $indent__xs 0;
            line-height: 26px;
        }

        .source p,
        p {
            margin: $indent__s 0 0 !important; // override Page Builder
            font-weight: $font-weight__light;
            font-style: italic;
        }
    }
}
