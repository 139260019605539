@mixin hide-for-small() {
    @include max-screen($screen__s) {
        display: none;
    }
}

@mixin hide-for-desktop() {
    @include min-screen($screen__m) {
        display: none;
    }
}

// vertically aligns any element
@mixin vertically-center($position: relative) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
}

/**
 * Adds intrinsic ratio to a video container, allowing it
 * to be responsive on a ratio of either 16:9 (widescreen)
 * or 4:3 (standard)
 *
 * See: https://alistapart.com/article/creating-intrinsic-ratios-for-video
 */
@mixin responsive-video($is-ratio-standard: null) {
    $ratio-percentage: 56.25%; // 16:9

    @if ($is-ratio-standard == true) {
        $ratio-percentage: 75%; // 4:3
    }
    position: relative;
    padding-bottom: $ratio-percentage;
    height: 0;

    iframe,
    object {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@mixin custom-scrollbar(
    $direction: horizontal,
    $_track-color: $white,
    $_track-margin: 0,
    $_thumb-color: $midnight,
    $_thumb-size: 4px,
    $_thumb-radius: 0
) {
    scrollbar-color: $_thumb-color $_track-color; // for Firefox
    scrollbar-width: thin; // for Firefox

    @if ($direction == horizontal) {
        &::-webkit-scrollbar {
            height: $_thumb-size;
        }
    }

    @if ($direction == vertical) {
        &::-webkit-scrollbar {
            width: $_thumb-size;
        }
    }

    &::-webkit-scrollbar-track {
        margin: $_track-margin;
        background: $_track-color;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $_thumb-color;
        border-radius: $_thumb-radius;
    }
}
