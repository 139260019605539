//
//  Forms
//  _____________________________________________


@mixin lib-form-element-input($_type) {
	$_background                    : $form-element-input__background;
	$_border                        : $form-element-input__border;
	$_border-radius                 : $form-element-input__border-radius;
	$_height                        : $form-element-input__height;
	$_width                         : $form-element-input__width;
	$_margin                        : $form-element-input__margin;
	$_padding                       : $form-element-input__padding;
	$_vertical-align                : $form-element-input__vertical-align;
	$_background-clip               : $form-element-input__background-clip;

	$_font-size                     : $form-element-input__font-size;
	$_color                         : $form-element-input__color;
	$_font-family                   : $form-element-input__font-family;
	$_font-weight                   : $form-element-input__font-weight;
	$_line-height                   : $form-element-input__line-height;
	$_font-style                    : $form-element-input__font-style;

	$_placeholder-color             : $form-element-input-placeholder__color;
	$_placeholder-font-style        : $form-element-input-placeholder__font-style;

	$_disabled-background           : $form-element-input__disabled__background;
	$_disabled-border               : $form-element-input__disabled__border;
	$_disabled-opacity              : $form-element-input__disabled__opacity;
	$_disabled-color                : $form-element-input__disabled__color;
	$_disabled-font-style           : $form-element-input__disabled__font-style;

	$_focus-background              : $form-element-input__focus__background;
	$_focus-border                  : $form-element-input__focus__border;
	$_focus-color                   : $form-element-input__focus__color;
	$_focus-font-style              : $form-element-input__focus__font-style;

	@if $_type == "select" {
			$_background                : $select__background;
			$_border                    : $select__border;
			$_border-radius             : $select__border-radius;
			$_height                    : $select__height;
			$_width                     : $select__width;
			$_margin                    : $select__margin;
			$_padding                   : $select__padding;
			$_vertical-align            : $select__vertical-align;
			$_background-clip           : $select__background-clip;

			$_font-size                 : $select__font-size;
			$_color                     : $select__color;
			$_font-family               : $select__font-family;
			$_font-weight               : $select__font-weight;
			$_line-height               : $select__line-height;
			$_font-style                : $select__font-style;

			$_placeholder-color         : $select-placeholder__color;
			$_placeholder-font-style    : $select-placeholder__font-style;

			$_disabled-background       : $select__disabled__background;
			$_disabled-border           : $select__disabled__border;
			$_disabled-opacity          : $select__disabled__opacity;
			$_disabled-color            : $select__disabled__color;
			$_disabled-font-style       : $select__disabled__font-style;

			$_focus-background          : $select__focus__background;
			$_focus-border              : $select__focus__border;
			$_focus-color               : $select__focus__color;
			$_focus-font-style          : $select__focus__font-style;
	}
	@else if $_type == "textarea" {
			$_background                : $textarea__background;
			$_border                    : $textarea__border;
			$_border-radius             : $textarea__border-radius;
			$_height                    : $textarea__height;
			$_width                     : $textarea__width;
			$_margin                    : $textarea__margin;
			$_padding                   : $textarea__padding;
			$_vertical-align            : $textarea__vertical-align;
			$_background-clip           : $textarea__background-clip;

			$_font-size                 : $textarea__font-size;
			$_color                     : $textarea__color;
			$_font-family               : $textarea__font-family;
			$_font-weight               : $textarea__font-weight;
			$_line-height               : $textarea__line-height;
			$_font-style                : $textarea__font-style;

			$_placeholder-color         : $textarea-placeholder__color;
			$_placeholder-font-style    : $textarea-placeholder__font-style;

			$_disabled-background       : $textarea__disabled__background;
			$_disabled-border           : $textarea__disabled__border;
			$_disabled-opacity          : $textarea__disabled__opacity;
			$_disabled-color            : $textarea__disabled__color;
			$_disabled-font-style       : $textarea__disabled__font-style;

			$_focus-background          : $textarea__focus__background;
			$_focus-border              : $textarea__focus__border;
			$_focus-color               : $textarea__focus__color;
			$_focus-font-style          : $textarea__focus__font-style;
	}
	@else if $_type == "input-radio" or $_type == "input-checkbox" {
			$_margin                : $form-element-choice__margin;
			$_vertical-align        : $form-element-choice__vertical-align;
			$_disabled-opacity      : $form-element-choice__disabled__opacity;
	}

	background: $_background;
	background-clip: $_background-clip;
	border: $_border;
	border-radius: $_border-radius;
	box-sizing: border-box;
	color: $_color;
	font-family: $_font-family;
	font-size: $_font-size;
	font-style: $_font-style;
	font-weight: $_font-weight;
	height: $_height;
	line-height: $_line-height;
	margin: $_margin;
	padding: $_padding;
	vertical-align: $_vertical-align;
	width: $_width;

	@include _lib-form-element-focus(
			$_background,
			$_focus-background,
			$_border,
			$_focus-border,
			$_color,
			$_focus-color,
			$_font-style,
			$_focus-font-style
	);

	@include _lib-form-element-disabled(
			$_background,
			$_disabled-background,
			$_border,
			$_disabled-border,
			$_color,
			$_disabled-color,
			$_font-style,
			$_disabled-font-style,
			$_disabled-opacity
	);

	@include _lib-form-element-placeholder(
			$_type,
			$_placeholder-color,
			$_font-style,
			$_placeholder-font-style
	);
}

@mixin lib-form-element-choice($_type) {
	@if $_type == "radio" or $_type == "checkbox" {
			$_vertical-align  : auto;
			$_margin          : 2px $indent__xs 0 0;
			$_disabled-opacity: 0.5;

			margin: $_margin;
			vertical-align: $_vertical-align;

			&:disabled {
					opacity: $_disabled-opacity;
			}
	}
}

@mixin lib-form-element-number-reset() {
	appearance: textfield;

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
	}
}

@mixin lib-form-element-search-reset() {
	appearance: none;

	&::-webkit-search-cancel-button,
	&::-webkit-search-decoration,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
			appearance: none;
	}
}

@mixin lib-form-element-textarea-resize($_textarea-resize: vertical) {
	resize: $_textarea-resize;
}

@mixin lib-form-element-color(
	$_border-color,
	$_color,
	$_background           : $form-field__background,
	$_focus-background     : $form-field__background,
	$_focus-border-color   : inherit,
	$_focus-background     : $form-field__background,
	$_focus-color          : inherit,
	$_disabled-border-color: inherit,
	$_disabled-background  : inherit,
	$_disabled-color       : inherit,
	$_placeholder-color    : inherit
) {
	background: $_background;
	border-color: $_border-color;
	color: $_color;

	&:focus {
			@include _lib-form-element-state-add-border-color(
					$_focus-border-color,
					$_border-color
			);
			@include _lib-form-element-state-add-background(
					$_focus-background,
					$_background
			);
			@include _lib-form-element-state-add-font-color(
					$_focus-color,
					$_color
			);
	}

	&:disabled {
			@include _lib-form-element-state-add-border-color(
					$_disabled-border-color,
					$_border-color
			);
			@include _lib-form-element-state-add-background(
					$_disabled-background,
					$_background
			);
			@include _lib-form-element-state-add-font-color(
					$_disabled-color,
					$_color
			);
	}

	@include lib-input-placeholder() {
			color: $_placeholder-color;
	}
}

@mixin lib-form-validation(
	$_element-color-error       : inherit,
	$_element-color-valid       : inherit,
	$_element-border-color-error: $form-element-validation__border-error,
	$_element-border-color-valid: inherit,
	$_element-background-error  : $form-field__background,
	$_element-background-valid  : inherit
) {
	&.mage-error {
			@include lib-form-element-color(
					$_border-color: $_element-border-color-error,
					$_background  : $_element-background-error,
					$_color       : $_element-color-error
			);
	}

	&.valid {
			@include lib-form-element-color(
					$_border-color: $_element-border-color-valid,
					$_background  : $_element-background-valid,
					$_color       : $_element-color-valid
			);
	}
}

@mixin lib-form-validation-note(
	$_note-color                   : $form-validation-note__color-error,
	$_note-font-size               : $form-validation-note__font-size,
	$_note-font-family             : $form-validation-note__font-family,
	$_note-font-style              : $form-validation-note__font-style,
	$_note-font-weight             : $form-validation-note__font-weight,
	$_note-line-height             : $form-validation-note__line-height,
	$_note-margin                  : $form-validation-note__margin,
	$_note-padding                 : $form-validation-note__padding,
	$_note-icon-use                : $form-validation-note-icon__use,

	$_note-icon-font-content       : $form-validation-note-icon__font-content,
	$_note-icon-font               : $form-validation-note-icon__font,
	$_note-icon-font-size          : $form-validation-note-icon__font-size,
	$_note-icon-font-line-height   : $form-validation-note-icon__font-line-height,
	$_note-icon-font-color         : $form-validation-note-icon__font-color,
	$_note-icon-font-color-hover   : $form-validation-note-icon__font-color-hover,
	$_note-icon-font-color-active  : $form-validation-note-icon__font-color-active,
	$_note-icon-font-margin        : $form-validation-note-icon__font-margin,
	$_note-icon-font-vertical-align: $form-validation-note-icon__font-vertical-align,
	$_note-icon-font-position      : $form-validation-note-icon__font-position,
	$_note-icon-font-text-hide     : $form-validation-note-icon__font-text-hide
) {
	@include lib-typography(
			$_font-size  : $_note-font-size,
			$_color      : $_note-color,
			$_font-family: $_note-font-family,
			$_font-weight: $_note-font-weight,
			$_line-height: $_note-line-height,
			$_font-style : $_note-font-style
	);
	@include _lib-form-validation-icon(
			$_note-icon-use,
			$_note-icon-font-content,
			$_note-icon-font,
			$_note-icon-font-size,
			$_note-icon-font-line-height,
			$_note-icon-font-color,
			$_note-icon-font-color-hover,
			$_note-icon-font-color-active,
			$_note-icon-font-margin,
			$_note-icon-font-vertical-align,
			$_note-icon-font-position,
			$_note-icon-font-text-hide
	);
}

@mixin lib-form-validation-color(
	$_element-border-color-error,
	$_element-background-error,
	$_element-color-error,
	$_element-border-color-valid,
	$_element-background-valid,
	$_element-color-valid
) {
	&.mage-error {
			@include lib-form-element-color(
					$_border-color: $_element-border-color-error,
					$_background  : $_element-background-error,
					$_color       : $_element-color-error
			);
	}

	&.valid {
			@include lib-form-element-color(
					$_border-color: $_element-border-color-valid,
					$_background  : $_element-background-valid,
					$_color       : $_element-color-valid
			);
	}
}

@mixin lib-form-element-all() {
	input[type="text"],
	input[type="password"],
	input[type="url"],
	input[type="tel"],
	input[type="search"],
	input[type="number"],
	input[type="datetime"],
	input[type="email"] {
			@include lib-form-element-input($_type: input-text);
	}

	input[type="number"] {
			@include lib-form-element-number-reset();
	}

	input[type="search"] {
			@include lib-form-element-search-reset();
	}

	select {
			@include lib-form-element-input($_type: select);
	}

	select[multiple="multiple"] {
			height: auto;
			background-image: none;
	}

	textarea {
			@include lib-form-element-input($_type: textarea);
			@include lib-form-element-textarea-resize();
	}

	input[type="checkbox"] {
			@include lib-form-element-choice($_type: checkbox);
	}

	input[type="radio"] {
			@include lib-form-element-choice($_type: radio);
	}

	input,
	select,
	textarea {
			@include lib-form-validation();
	}

	div.mage-error[generated] {
			@include lib-form-validation-note($_note-icon-font-margin: 0);
	}

	input[type="button"],
	input[type="reset"],
	input[type="submit"] {
			cursor: pointer;
			appearance: button;
			overflow: visible;
	}

	input::-moz-focus-inner {
			border: 0;
			padding: 0;
	}
}

//
//  Internal use mixins
//  ---------------------------------------------

@mixin _lib-form-validation-icon(
	$_note-icon-use,
	$_note-icon-font-content,
	$_note-icon-font,
	$_note-icon-font-size,
	$_note-icon-font-line-height,
	$_note-icon-font-color,
	$_note-icon-font-color-hover,
	$_note-icon-font-color-active,
	$_note-icon-font-margin,
	$_note-icon-font-vertical-align,
	$_note-icon-font-position,
	$_note-icon-font-text-hide
) {
	@if $_note-icon-use {
			@include lib-icon-font(
					$_icon-font-content       : $_note-icon-font-content,
					$_icon-font               : $_note-icon-font,
					$_icon-font-size          : $_note-icon-font-size,
					$_icon-font-line-height   : $_note-icon-font-line-height,
					$_icon-font-color         : $_note-icon-font-color,
					$_icon-font-color-hover   : $_note-icon-font-color-hover,
					$_icon-font-color-active  : $_note-icon-font-color-active,
					$_icon-font-margin        : $_note-icon-font-margin,
					$_icon-font-vertical-align: $_note-icon-font-vertical-align,
					$_icon-font-position      : $_note-icon-font-position,
					$_icon-font-text-hide     : $_note-icon-font-text-hide
			);
	}
}

//  Only for states :disabled: :focus: placeholder
@mixin _lib-form-element-focus(
	$_background-default,
	$_background-state,
	$_border-default,
	$_border-state,
	$_color-default,
	$_color-state,
	$_font-style-default,
	$_font-style-state
) {
	&:focus {
			@include _lib-form-element-state-add-background(
					$_background-state,
					$_background-default
			);
			@include _lib-form-element-state-add-border(
					$_border-state,
					$_border-default
			);
			@include _lib-form-element-state-add-font-color(
					$_color-state,
					$_color-default
			);
			@include _lib-form-element-state-add-font-style(
					$_font-style-state,
					$_font-style-default
			);
	}
}

@mixin _lib-form-element-disabled(
	$_background-default,
	$_background-state,
	$_border-default,
	$_border-state,
	$_color-default,
	$_color-state,
	$_font-style-default,
	$_font-style-state,
	$_opacity
) {
	&:disabled {
			opacity: $_opacity;
			@include _lib-form-element-state-add-background(
					$_background-state,
					$_background-default
			);
			@include _lib-form-element-state-add-border(
					$_border-state,
					$_border-default
			);
			@include _lib-form-element-state-add-font-color(
					$_color-state,
					$_color-default
			);
			@include _lib-form-element-state-add-font-style(
					$_font-style-state,
					$_font-style-default
			);
	}
}

@mixin _lib-form-element-placeholder(
	$_type,
	$_color,
	$_font-style-default,
	$_font-style-state
) {
	@if $_type != "select" {
			&::-moz-placeholder {
					color: $_color;
					@include _lib-form-element-state-add-font-style(
							$_font-style-state,
							$_font-style-default
					);
			}

			&::-webkit-input-placeholder {
					color: $_color;
					@include _lib-form-element-state-add-font-style(
							$_font-style-state,
							$_font-style-default
					);
			}

			&:-ms-input-placeholder {
					color: $_color;
					@include _lib-form-element-state-add-font-style(
							$_font-style-state,
							$_font-style-default
					);
			}
	}
}

@mixin _lib-form-element-state-add-background(
	$_background-state,
	$_background-default
) {
	@if $_background-state != $_background-default {
			background: $_background-state;
	}
}

@mixin _lib-form-element-state-add-border(
	$_border-state,
	$_border-default
) {
	@if $_border-state != $_border-default {
			border: $_border-state;
	}
}

@mixin _lib-form-element-state-add-border-color(
	$_border-state,
	$_border-default
) {
	@if $_border-state != $_border-default {
			border-color: $_border-state;
	}
}

@mixin _lib-form-element-state-add-font-style(
	$_font-style-state,
	$_font-style-default
) {
	@if $_font-style-state != $_font-style-default {
			font-style: $_font-style-state;
	}
}

@mixin _lib-form-element-state-add-font-color(
	$_color-state,
	$_color-default
) {
	@if $_color-state != $_color-default {
			color: $_color-state;
	}
}

@mixin lib-form-fieldset(
	$_border            : $form-fieldset__border,
	$_margin            : $form-fieldset__margin,
	$_padding           : $form-fieldset__padding,
	$_legend-color      : $form-fieldset-legend__color,
	$_legend-font-size  : $form-fieldset-legend__font-size,
	$_legend-font-family: $form-fieldset-legend__font-family,
	$_legend-font-weight: $form-fieldset-legend__font-weight,
	$_legend-font-style : $form-fieldset-legend__font-style,
	$_legend-line-height: $form-fieldset-legend__line-height,
	$_legend-margin     : $form-fieldset-legend__margin,
	$_legend-padding    : $form-fieldset-legend__padding,
	$_legend-width      : $form-fieldset-legend__width
) {
	border: $_border;
	margin: $_margin;
	padding: $_padding;

	> .legend {
			margin: $_legend-margin;
			padding: $_legend-padding;
			box-sizing: border-box;
			float: left;

			@include lib-typography(
					$_font-size  : $_legend-font-size,
					$_color      : $_legend-color,
					$_font-family: $_legend-font-family,
					$_font-weight: $_legend-font-weight,
					$_line-height: $_legend-line-height,
					$_font-style : $_legend-font-style
			);

			& + br {
					clear: both;
					display: block;
					height: 0;
					overflow: hidden;
					visibility: hidden;
			}
	}
}

@mixin lib-form-field(
	$_type                         : $form-field-type,

	$_type-inline-margin           : $form-field-type-label-inline__margin,
	$_type-inline-label-margin     : $form-field-type-label-inline__margin,
	$_type-inline-label-padding    : $form-field-type-label-inline__padding,
	$_type-inline-label-align      : $form-field-type-label-inline__align,
	$_type-inline-label-width      : $form-field-type-label-inline__width,
	$_type-inline-control-width    : $form-field-type-control-inline__width,

	$_type-block-margin            : $form-field-type-label-block__margin,
	$_type-block-label-margin      : $form-field-type-label-block__margin,
	$_type-block-label-padding     : $form-field-type-label-block__padding,
	$_type-block-label-align       : $form-field-type-label-block__align,

	$_vertical-indent              : $form-field__vertical-indent,
	$_additional-vertical-indent   : $form-field__additional-vertical-indent,

	$_border                       : $form-field__border,
	$_column                       : $form-field-column,
	$_column-padding               : $form-field-column__padding,
	$_column-number                : $form-field-column__number,

	$_label-color                  : $form-field-label__color,
	$_label-font-size              : $form-field-label__font-size,
	$_label-font-family            : $form-field-label__font-family,
	$_label-font-weight            : $form-field-label__font-weight,
	$_label-font-style             : $form-field-label__font-style,
	$_label-line-height            : $form-field-label__line-height,

	$_label-asterisk-color         : $form-field-label-asterisk__color,
	$_label-asterisk-font-size     : $form-field-label-asterisk__font-size,
	$_label-asterisk-font-family   : $form-field-label-asterisk__font-family,
	$_label-asterisk-font-weight   : $form-field-label-asterisk__font-weight,
	$_label-asterisk-font-style    : $form-field-label-asterisk__font-style,
	$_label-asterisk-line-height   : $form-field-label-asterisk__line-height,
	$_label-asterisk-margin        : $form-field-label-asterisk__margin,

	$_note-color                   : $form-field-note__color,
	$_note-font-size               : $form-field-note__font-size,
	$_note-font-family             : $form-field-note__font-family,
	$_note-font-weight             : $form-field-note__font-weight,
	$_note-line-height             : $form-field-note__line-height,
	$_note-font-style              : $form-field-note__font-style,
	$_note-margin                  : $form-field-note__margin,
	$_note-padding                 : $form-field-note__padding,

	$_note-icon-font               : $form-field-note-icon-font,
	$_note-icon-font-content       : $form-field-note-icon-font__content,
	$_note-icon-font-size          : $form-field-note-icon-font__size,
	$_note-icon-font-line-height   : $form-field-note-icon-font__line-height,
	$_note-icon-font-color         : $form-field-note-icon-font__color,
	$_note-icon-font-color-hover   : $form-field-note-icon-font__color-hover,
	$_note-icon-font-color-active  : $form-field-note-icon-font__color-active,
	$_note-icon-font-margin        : $form-field-note-icon-font__margin,
	$_note-icon-font-vertical-align: $form-field-note-icon-font__vertical-align,
	$_note-icon-font-position      : $form-field-note-icon-font__position,
	$_note-icon-font-text-hide     : $form-field-note-icon-font__text-hide
) {
	border: $_border;

	@include lib-form-field-type(
			$_type,
			$_type-inline-margin,
			$_type-inline-label-margin,
			$_type-inline-label-padding,
			$_type-inline-label-align,
			$_type-inline-label-width,
			$_type-inline-control-width,
			$_vertical-indent,
			$_type-block-margin,
			$_type-block-label-margin,
			$_type-block-label-padding,
			$_type-block-label-align
	);

	&:last-child {
			margin-bottom: 0;
	}

	@include lib-form-field-column(
			$_column,
			$_column-padding,
			$_column-number
	);

	> .label {
			@include lib-typography(
					$_font-size  : $_label-font-size,
					$_color      : $_label-color,
					$_font-family: $_label-font-family,
					$_font-weight: $_label-font-weight,
					$_line-height: $_label-line-height,
					$_font-style : $_label-font-style
			);
			& + br {
					display: none;
			}
	}

	.choice {
			input {
					vertical-align: top;
			}
	}

	.fields.group {
			@include lib-clearfix();
			.field {
					box-sizing: border-box;
					float: left;
			}

			&.group-2 .field {
					width: 50% !important;
			}

			&.group-3 .field {
					width: 33.3% !important;
			}

			&.group-4 .field {
					width: 25% !important;
			}

			&.group-5 .field {
					width: 20% !important;
			}
	}

	.addon {
			display: inline-flex;
			flex-wrap: nowrap;
			padding: 0;
			width: 100%;

			textarea,
			select,
			input {
					order: 2;
					flex-basis: 100%;
					display: inline-block;
					margin: 0;
					width: auto;
			}

			.addbefore,
			.addafter {
					@include lib-form-element-input($_type: input-text);
					order: 3;
					display: inline-block;
					vertical-align: middle;
					white-space: nowrap;
					width: auto;
			}

			.addbefore {
					float: left;
					order: 1;
			}
	}

	.additional {
			margin-top: $_additional-vertical-indent;
	}

	@include _lib-form-field-required(
			$_label-asterisk-color,
			$_label-asterisk-font-size,
			$_label-asterisk-font-family,
			$_label-asterisk-font-weight,
			$_label-asterisk-font-style,
			$_label-asterisk-line-height,
			$_label-asterisk-margin
	);

	@include _lib-form-field-note(
			$_note-font-size,
			$_note-color,
			$_note-font-family,
			$_note-font-weight,
			$_note-line-height,
			$_note-font-style,
			$_note-margin,
			$_note-padding,

			$_note-icon-font-content,
			$_note-icon-font,
			$_note-icon-font-size,
			$_note-icon-font-line-height,
			$_note-icon-font-color,
			$_note-icon-font-color-hover,
			$_note-icon-font-color-active,
			$_note-icon-font-margin,
			$_note-icon-font-vertical-align,
			$_note-icon-font-position,
			$_note-icon-font-text-hide
	);
}

@mixin lib-form-field-type(
	$_type,

	$_type-inline-margin       : 0 0 $indent__base,
	$_type-inline-label-margin : false,
	$_type-inline-label-padding: 6px 15px 0 0,
	$_type-inline-label-align  : right,
	$_type-inline-label-width  : 25.8%,
	$_type-inline-control-width: 74.2%,
	$_vertical-indent          : $indent__base / 2,

	$_type-block-margin        : $indent__base,
	$_type-block-label-margin  : 0 0 $indent__xs,
	$_type-block-label-padding : inherit,
	$_type-block-label-align   : inherit


) {
	@if $_type == "inline" {
			@include _lib-form-field-type-inline(
					$_type-inline-margin,
					$_type-inline-label-margin,
					$_type-inline-label-padding,
					$_type-inline-label-width,
					$_type-inline-control-width,
					$_type-inline-label-align,
					$_vertical-indent
			);
	}
	@else if $_type == "block" {
			@include _lib-form-field-type-block(
					$_type-block-margin,
					$_type-block-label-margin,
					$_type-block-label-padding,
					$_type-block-label-align
			);
	}
}

@mixin _lib-form-field-type-inline(
	$_type-inline-margin,
	$_type-inline-label-margin,
	$_type-inline-label-padding,
	$_type-inline-label-width,
	$_type-inline-control-width,
	$_type-inline-label-align,
	$_vertical-indent
) {
	@include lib-clearfix();
	margin: $_type-inline-margin;
	box-sizing: border-box;

	&.choice:before,
	&.no-label:before {
			padding: $_type-inline-label-padding;
			width: $_type-inline-label-width;
			box-sizing: border-box;
			content: ' ';
			float: left;
			height: 1px;
	}

	.description {
			padding: $_type-inline-label-padding;
			text-align: $_type-inline-label-align;
			width: $_type-inline-label-width;
			box-sizing: border-box;
			float: left;
	}

	&:not(.choice) {
			> .label {
					@if $_type-inline-label-margin {
							margin: $_type-inline-label-margin;
					}
					padding: $_type-inline-label-padding;
					text-align: $_type-inline-label-align;
					width: $_type-inline-label-width;
					box-sizing: border-box;
					float: left;
			}

			> .control {
					width: $_type-inline-control-width;
					float: left;
			}
	}
}

@mixin _lib-form-field-type-block(
	$_type-block-margin,
	$_type-block-label-margin,
	$_type-block-label-padding,
	$_type-block-label-align
) {
	margin: $_type-block-margin;
	> .label {
			margin: $_type-block-label-margin;
			padding: $_type-block-label-padding;
			text-align: $_type-block-label-align;
			display: inline-block;
	}
}

@mixin _lib-form-field-required(
	$_color      : $color-red10,
	$_font-size  : $font-size__s,
	$_font-family: inherit,
	$_font-weight: inherit,
	$_font-style : inherit,
	$_line-height: inherit,
	$_margin     : 0 0 0 $indent__xs
) {
	&.required > .label,
	&._required > .label {
			&:after {
					content: '*';
					@include lib-typography(
							$_font-size  : $_font-size,
							$_color      : $_color,
							$_font-family: $_font-family,
							$_font-weight: $_font-weight,
							$_line-height: $_line-height,
							$_font-style : $_font-style
					);
					margin: $_margin;
			}
	}
}

@mixin _lib-form-field-note(
	$_note-font-size,
	$_note-color,
	$_note-font-family,
	$_note-font-weight,
	$_note-line-height,
	$_note-font-style,
	$_note-margin,
	$_note-padding,

	$_note-icon-font-content,
	$_note-icon-font,
	$_note-icon-font-size,
	$_note-icon-font-line-height,
	$_note-icon-font-color,
	$_note-icon-font-color-hover,
	$_note-icon-font-color-active,
	$_note-icon-font-margin,
	$_note-icon-font-vertical-align,
	$_note-icon-font-position,
	$_note-icon-font-text-hide
) {
	.note {
			@include lib-typography(
					$_font-size  : $_note-font-size,
					$_color      : $_note-color,
					$_font-family: $_note-font-family,
					$_font-weight: $_note-font-weight,
					$_line-height: $_note-line-height,
					$_font-style : $_note-font-style
			);

			margin: $_note-margin;
			padding: $_note-padding;

			@include lib-icon-font(
					$_icon-font-content       : $_note-icon-font-content,
					$_icon-font               : $_note-icon-font,
					$_icon-font-size          : $_note-icon-font-size,
					$_icon-font-line-height   : $_note-icon-font-line-height,
					$_icon-font-color         : $_note-icon-font-color,
					$_icon-font-color-hover   : $_note-icon-font-color-hover,
					$_icon-font-color-active  : $_note-icon-font-color-active,
					$_icon-font-margin        : $_note-icon-font-margin,
					$_icon-font-vertical-align: $_note-icon-font-vertical-align,
					$_icon-font-position      : $_note-icon-font-position,
					$_icon-font-text-hide     : $_note-icon-font-text-hide
			);
	}
}

@mixin lib-form-field-type-revert(
	$_type                     : inline,

	$_type-inline-margin       : 0 0 $indent__base,
	$_type-inline-label-width  : 25.8%,
	$_type-inline-label-margin : false,
	$_type-inline-label-padding: 6px 15px 0 0,
	$_type-inline-label-align  : right,
	$_type-inline-control-width: 74.2%,
	$_type-block-margin        : 0,
	$_type-block-label-margin  : 0 0 $indent__xs,
	$_type-block-label-padding : inherit,
	$_type-block-label-align   : inherit,

	$_vertical-indent          : $indent__base / 2
) {
	@if $_type == "block" {
			&:not(.choice) {
					> .label {
							box-sizing: content-box;
							float: none;
							width: auto;

							@include _lib-revert-type-block-label-align(
									$_type-block-label-align
							);

							@include _lib-revert-type-block-label-padding(
									$_type-block-label-padding
							);
					}

					> .control {
							float: none;
							width: auto;
					}
			}

			@include _lib-form-field-type-block(
					$_type-block-margin,
					$_type-block-label-margin,
					$_type-block-label-padding,
					$_type-block-label-align
			);

			&.choice,
			&.no-label {
					&:before {
							display: none;
					}
			}
	}
	@else if $_type == "inline" {
			> .label {
					@include _lib-revert-type-inline-label-margin(
							$_type-inline-label-margin
					);
			}
			@include _lib-form-field-type-inline(
					$_type-inline-margin,
					$_type-inline-label-margin,
					$_type-inline-label-padding,
					$_type-inline-label-width,
					$_type-inline-control-width,
					$_type-inline-label-align,
					$_vertical-indent
			);
	}
}

@mixin lib-form-field-column(
	$_column        : inherit,
	$_column-padding: 0 12px 0 0,
	$_column-number : 2
) {

	@if $_column != inherit {
			padding: $_column-padding;
			box-sizing: border-box;
			display: inline-block;

			@include lib-form-field-column-number($_column-number);
			vertical-align: top;

			& + .fieldset {
					clear: both;
			}
	}
}

@mixin lib-form-field-column-number(
	$_column-number: 2
) {
	width: 100% / $_column-number;
}

@mixin _lib-revert-type-block-label-padding($_type-block-label-padding) {
	@if $_type-block-label-padding == inherit {
			padding: 0;
	}
}

@mixin _lib-revert-type-block-label-align($_type-block-label-align) {
	@if $_type-block-label-align == inherit {
			text-align: left;
	}
}

@mixin _lib-revert-type-inline-label-margin($_type-inline-label-margin) {
	@if $_type-inline-label-margin == false {
			margin: 0;
	}
}

@mixin lib-form-hasrequired(
	$_position   : $form-hasrequired__position,
	$_color      : $form-hasrequired__color,
	$_font-size  : $form-hasrequired__font-size,
	$_font-family: $form-hasrequired__font-family,
	$_font-weight: $form-hasrequired__font-weight,
	$_font-style : $form-hasrequired__font-style,
	$_line-height: $form-hasrequired__line-height,
	$_border     : $form-hasrequired__border,
	$_margin     : $form-hasrequired__margin,
	$_padding    : $form-hasrequired__padding
) {
	@if $_position == "top" {
			&:before {
					@include _lib-form-hasrequired-position-any(
							$_border,
							$_margin,
							$_padding,
							$_font-size,
							$_color,
							$_font-family,
							$_font-weight,
							$_line-height,
							$_font-style
					);
			}
	}
	@else if $_position == "bottom" {
			&:after {
					@include _lib-form-hasrequired-position-any(
							$_border,
							$_margin,
							$_padding,
							$_font-size,
							$_color,
							$_font-family,
							$_font-weight,
							$_line-height,
							$_font-style
					);
			}
	}
}

@mixin _lib-form-hasrequired-position-any(
	$_border,
	$_margin,
	$_padding,
	$_font-size,
	$_color,
	$_font-family,
	$_font-weight,
	$_line-height,
	$_font-style
) {
	border: $_border;
	margin: $_margin;
	padding: $_padding;
	content: attr(data-hasrequired);
	display: block;
	letter-spacing: normal;
	word-spacing: normal;
	@include lib-typography(
			$_font-size  : $_font-size,
			$_color      : $_color,
			$_font-family: $_font-family,
			$_font-weight: $_font-weight,
			$_line-height: $_line-height,
			$_font-style : $_font-style
	);
}
