/*doc
---
title: Breadcrumbs
name: block_breadcrumbs
category: Blocks - Breadcrumbs
---


```html_example
<div class="breadcrumbs">
    <ul class="items">
        <li class="item home"><a href="#" title="Go to Home Page">Home</a></li>
        <li class="item product"><strong>Desiree Fitness Tee</strong></li>
    </ul>
</div>
```
*/
$breadcrumbs__font-size: 10px !default;
$breadcrumb_link_color: $azule;

.breadcrumbs {
    @include layout-container;
    @include lib-breadcrumbs(
        $_breadcrumbs-font-size: $breadcrumbs__font-size,
        $_breadcrumbs-current-font-weight: $font-weight__bold,
        $_breadcrumbs-container-margin: 0 auto,
        $_breadcrumbs-container-padding: 30px $indent__m,
        $_breadcrumbs-link-color: $text__color,
        $_breadcrumbs-link-color-hover: $breadcrumb_link_color,
        $_breadcrumbs-link-text-decoration-hover: none,
        $_breadcrumbs-link-text-decoration: none,
        $_breadcrumbs-current-color: $breadcrumb_link_color,
        $_breadcrumbs-link-color-visited: $text__color,
        $_breadcrumbs-separator-color: $text__color,
        $_icon-font-color: $text__color,
        $_icon-font-vertical-align: middle,
        $_icon-font-line-height: $breadcrumbs__font-size
    );

    @include max-screen($screen__m) {
        padding: $indent__m 15px;
    }

    .items {
        @include min-screen($screen__m) {
            font-size: $breadcrumbs__font-size;
        }

        font-family: $font-family__secondary;
        font-weight: $font-weight__bold;
        letter-spacing: $heading__letter-spacing__large;
        text-transform: uppercase;

        .item {
            margin: $indent__xs 0;
        }
    }
}
