//
//  Variables
//  _____________________________________________

$checkout-payment-option-title__border           : $checkout-payment-method-title__border !default;
$checkout-payment-option-title__color            : $link__color !default;
$checkout-payment-option-title__padding          : $checkout-payment-method-title__padding !default;
$checkout-payment-option-title-mobile__padding   : $checkout-payment-method-title-mobile__padding !default;

$checkout-payment-option-content__padding__xl    : $checkout-payment-method-content__padding__xl !default;

//
//  Common
//  _____________________________________________

.checkout-payment-method {
    .payment-option {
        &._collapsible {
            .payment-option-title {
                cursor: pointer;
            }

            .payment-option-content {
                display: none;
            }
        }
    }

    .payment-option-title {
        border-top: $checkout-payment-option-title__border;
        padding: $checkout-payment-option-title__padding 0;

        .action-toggle {
            @include icon-svg(
                $icon       : expand,
                $fill       : $toggling-icon-color,
                $width      : $toggling-icon-width,
                $height     : auto,
                $hideLabel  : false,
                $pseudo     : after
            );
            color: $checkout-payment-option-title__color;
            position: relative;

            &::after {
                @include vertically-center($position: absolute);
                left: -$indent__m;
            }
        }
    }

    .payment-option._active .payment-option-title .action-toggle {
        @include icon-svg(
            $icon       : collapse,
            $fill       : $toggling-icon-color,
            $width      : $toggling-icon-width,
            $height     : auto,
            $hideLabel  : false,
            $pseudo     : after
        );
    }

    .payment-option-content {
        padding: 0 0 $indent__base $checkout-payment-option-content__padding__xl;

        .actions-toolbar {
            .action {
                @include lib-button-primary();
            }
        }
    }

    .payment-option-inner {
        margin: 0 0 $indent__base;
    }

    .credit-card-types {
        padding: 0;

        .item {
            display: inline-block;
            list-style: none;
            margin: 0 $indent__xs 0 0;
            vertical-align: top;

            &._active {
                font-weight: $font-weight__bold;

                img {
                    filter: grayscale(0%);
                }
            }

            &._inactive {
                opacity: 0.4;
            }

            span {
                display: inline-block;
                padding-top: 6px;
                vertical-align: top;
            }
        }

        img {
            filter: grayscale(100%);
            transition: all 0.6s ease;

            @include max-screen($screen__s) {
                max-width: 40px;
            }
        }
    }

    .ccard {
        .legend {
            @include lib-visually-hidden();
        }

        .fields {
            & > .year {
                padding-left: $indent__xs;
            }

            .select {
                padding-left: $indent__xs;
                padding-right: $indent__xs;
            }
        }

        .month {
            .select {
                width: 140px;
            }
        }

        .year {
            .select {
                width: 80px;
            }
        }

        .number {
            .input-text {
                width: 225px;
            }
        }

        & > .field.cvv {
            & > .control {
                padding-right: $indent__base;
                width: auto;
            }
        }

        .cvv {
            .input-text {
                width: 55px;
            }
        }

        &.fieldset {
            & > .field {
                .fields.group.group-2 {
                    .field {
                        width: auto !important;
                    }
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .checkout-payment-method {
        .payment-option {
            margin: 0 (-$checkout-payment-option-title-mobile__padding);

            .payment-option-title {
                padding: $checkout-payment-option-title-mobile__padding;
                padding-left: 35px;
            }

            .payment-option-content {
                padding: 0 $checkout-payment-option-title-mobile__padding $indent__base;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-payment-method {
        .payment-option-title {
            padding-left: $checkout-payment-option-content__padding__xl;
        }
        .payment-option-content {
            .payment-option-inner {
                + .actions-toolbar {
                    margin-left: 0;
                }
            }
        }
    }
}
