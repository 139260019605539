@mixin price-style-1() {
    .price {
        &-tier_price .price-excluding-tax,
        &-tier_price .price-including-tax {
            display: inline;
        }
    }
}

@mixin price-style-2() {
    .price {
        &-including-tax,
        &-excluding-tax {
            display: inline;
        }

        &-including-tax:before {
            content: ' / ';
        }

        &-including-tax:after {
            content: '("attr(data-label)")';
        }
    }
}

@mixin price-style-3() {
    .price-including-tax,
    .price-excluding-tax {
        display: block;
        font-size: 18px;
        line-height: 1;

        .price {
            font-weight: $font-weight__bold;
        }

        .cart-tax-total {
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size       : 26px,
                $_icon-font-line-height: 10px,
                $_icon-font-margin     : 3px 0 0 0,
                $_icon-font-position   : after
            );
            cursor: pointer;
            padding-right: 12px;
            position: relative;

            &::after {
                position: absolute;
                right: -$indent__s;
                top: 3px;
            }

            &-expanded {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }
        }
    }

    .price-including-tax + .price-excluding-tax,
    .weee[data-label] {
        display: block;
        font-size: 18px;

        &:before {
            content: attr(data-label) ': ';
            font-size: 11px;
        }

        .price {
            font-size: 11px;
        }
    }
}
@include price-style-1();
@include price-style-3();
// TO DO - No idea what the hell happened here - looks like piece of shit
