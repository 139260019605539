
//  Paypal billing agreement
.form-new-agreement {
    .actions-toolbar {
        margin-left: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .form-new-agreement {
        .fieldset .legend,
        .actions-toolbar {
            @extend .abs-reset-left-margin-desktop;
        }
    }
}
