.modal-popup.size-chart-modal {
    .modal-inner-wrap {
        padding: $indent__m;

        .modal-content {
            padding: 0;
        }
    }

    [data-content-type="row"] {
        padding: 0 !important; // override Page Builder
        overflow-x: auto;
    }

    [data-content-type="column-group"] {
        flex-wrap: nowrap;

        &:first-child {
            [data-content-type="column"] {
                font-weight: bold;
                border-bottom: 0 !important; // override Page Builder
            }
        }

        [data-content-type="column"] {
            text-align: center;
            border: 1px solid $c-border !important; // override Page Builder
            flex-basis: 100%;
            min-width: 80px;
        }
    }

    .modal-inner-wrap .modal-footer button {
        margin-right: 0;
    }
}
