.sidebar {
    .block-addbysku {
        .fieldset {
            margin: 0;
            .fields {
                position: relative;
                .field {
                    display: inline-block;
                    margin-bottom: $indent__s;
                    vertical-align: top;
                    &.sku {
                        box-sizing: border-box;
                        margin-right: -85px;
                        padding-right: 90px;
                        width: 100%;
                    }
                    &.qty {
                        .qty {
                            @extend .abs-input-qty;
                            + .mage-error {
                                width: 80px;
                            }
                        }
                    }
                }
                .actions-toolbar {
                    position: absolute;
                    right: 0;
                    top: 6px;
                }
            }
        }
        .form-addbysku {
            .actions-toolbar {
                margin-bottom: $indent__base;
                .primary {
                    text-align: left;
                    .action {
                        width: auto;
                    }
                }
                .secondary {
                    float: none;
                    text-align: left;
                }
            }
        }
        .action {
            &.add {
                @include lib-button-icon(
                    $_icon-font-content  : $icon-expand,
                    $_icon-font-text-hide: true,
                    $_icon-font-size     : 31px
                );
                line-height: normal;
                padding: 2px 0;
                width: auto;
            }
            &.links {
                display: inline-block;
                margin: $indent__base 0 0;
            }
            &.reset {
                display: block;
                margin: $indent__s 0;
            }
            &.remove {
                @include lib-button-icon(
                    $_icon-font-content  : $icon-remove,
                    $_icon-font-text-hide: true,
                    $_icon-font-size     : 28px
                );
                @include lib-button-reset();
            }
        }
    }
}
