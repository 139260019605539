//
//  Variables
//  _____________________________________________

$checkout-sidebar__margin: $indent__base !default;
$checkout-sidebar__margin__xl: 46px !default;
$checkout-sidebar__width: 33.3333333% !default;

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-sidebar {
        margin: $checkout-sidebar__margin__xl 0 $checkout-sidebar__margin;
        @include lib-layout-column(2, 2, $checkout-sidebar__width);
    }
}
