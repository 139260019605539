$newsletter-form-input__height: 50px;

.block.newsletter {
    margin-bottom: $indent__xs;

    .form.subscribe {
        display: table;
        width: 100%;
        max-width: $form-field__width__desktop; // newsletter will be all on its lonesome for mobile-tablet resolutions, and needs a capped width as a result
    }

    .fieldset {
        display: table-cell;
        padding: 0;
        vertical-align: top;
    }

    .field {
        margin: 0;
    }

    .title h2 {
        letter-spacing: $heading__letter-spacing__large;
    }

    .content {
        margin: 40px 0;
        height: $newsletter-form-input__height;

        @include max-screen($screen__xs) {
            margin: 30px 0;
            height: auto;

            .newsletter,
            .actions {
                display: block;
                width: 100%;
            }

            .actions {
                margin-top: $indent__s;
            }
        }

        input[type="email"],
        .action.subscribe {
            height: $newsletter-form-input__height;
        }

        input[type="email"] {
            padding: 15px 20px;
            background: transparent;
            color: $white;
        }

        .action.subscribe {
            @include lib-button-primary-white();
        }
    }

    .label {
        display: table-row;
    }

    .actions {
        display: table-cell;
        vertical-align: top;
        width: 1%;
    }
}
