.four-column-featured-content {
    $image-width: 250px;
    $image-overlap: 50px;
    $content-offset: 25px;
    $content-padding: 20px;

    [data-content-type="column-group"] {
        justify-content: space-between;
    }

    [data-content-type="column"] {
        position: relative;
        margin: $image-width $content-offset 0 !important; // override Page Builder
        padding: $image-overlap $content-padding $content-padding !important; // override Page Builder
        max-width: 250px;
        background: $smoke-gradient;
        box-shadow: 0px 10px 20px rgba($midnight, .15);
        left: $content-offset;
        transition: left 150ms;

        @include min-screen($screen__m) {
            @include hover {

                &,
                [data-content-type="image"] {
                    left: 0;
                }

                [data-content-type="image"] img {
                    transform: scale(1.1);
                }
            }
        }
    }

    [data-content-type="image"] {
        position: absolute;
        bottom: calc(100% - #{$image-overlap});
        left: -#{$content-offset};
        width: 100%;
        overflow: hidden;
        transition: left 150ms;
        box-shadow: 0px 10px 20px rgba($midnight, .22);

        img {
            display: block;
            height: 100% !important; // override Page Builder
            width: 100%;
            object-fit: cover;
            transition: transform 150ms;
        }
    }

    [data-content-type="heading"] {
        word-break: break-word;
        hyphens: auto;
    }

    [data-content-type="text"]:last-child p {
        margin: 0 !important; // override Page Builder
    }

    @include max-screen($screen__m) {
        $content-padding: $indent__s;
        $mobile-margin: $indent__s;

        [data-content-type="column-group"] {
            justify-content: space-evenly;
        }

        [data-content-type="column"] {
            margin: $mobile-margin !important; // override Page Builder
            padding: 0 $content-padding $content-padding !important; // override Page Builder
            max-width: calc(50% - (#{$mobile-margin} * 2)) !important; // override Page Builder
            left: 0;

            [data-content-type="image"] {
                left: -#{$content-padding};
                top: 0;
                bottom: unset;
                width: calc(100% + (#{$content-padding} * 2));
            }
        }

        [data-content-type="image"] {
            position: relative;
        }
    }
}
