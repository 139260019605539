//
//  Variables
//  _____________________________________________

$checkout-shipping-address__max-width          : 600px !default;
$checkout-shipping-address__margin-top         : 28px !default;

$checkout-shipping-item__border                : 2px solid transparent !default;
$checkout-shipping-item__line-height           : 30px !default;
$checkout-shipping-item__margin                : 0 0 $indent__base !default;
$checkout-shipping-item__padding               : $indent__base ($indent__l + 5px) $indent__base $indent__base !default;
$checkout-shipping-item__transition            : 0.3s border-color !default;
$checkout-shipping-item__width                 : 33.3333% !default;
$checkout-shipping-item-tablet__width          : 50% !default;
$checkout-shipping-item-mobile__width          : 100% !default;
$checkout-shipping-item__active__border-color  : $primary__color !default;

$checkout-shipping-item-icon__background-color : $checkout-shipping-item__active__border-color !default;
$checkout-shipping-item-icon__color            : $color-white !default;
$checkout-shipping-item-icon__content          : $icon-checkmark !default;

$checkout-shipping-item-icon__selected__height : 27px !default;
$checkout-shipping-item-icon__selected__width  : 29px !default;

$checkout-shipping-item-mobile__padding        : 0 0 15px !default;
$checkout-shipping-item-mobile__margin         : $checkout-shipping-item-mobile__padding !default;
$checkout-shipping-item-mobile__active__padding: 15px ($indent__l + 5px) 15px 18px !default;

$checkout-shipping-item-before__border-color   : $color-gray80 !default;
$checkout-shipping-item-before__height         : calc(100% - 20px) !default;

$checkout-shipping-method__border              : $checkout-step-title__border !default;
$checkout-shipping-method__padding             : $indent__base !default;

//
//  Shipping Address
//  _____________________________________________

.opc-wrapper {
    .form-login,
    .form-shipping-address {
        margin-top: $checkout-shipping-address__margin-top;
        margin-bottom: $indent__base;

        .fieldset .note {
            font-size: $font-size__base;
            margin-top: $indent__s;
        }
    }

    .sign-in-options {
        display: flex;
        align-items: center;
        margin-bottom: $indent__s;

        .primary + span {
            margin: 0 15px 0 0;
            text-transform: uppercase;
        }

        @include max-screen($screen__xs) {
            flex-direction: column;

            .primary {
                width: 100%;

                + span {
                    margin: $indent__s 0;
                }
            }
        }
    }

    .shipping-address-items {
        font-size: 0;
    }

    .shipping-address-item {
        box-sizing: border-box;
        border: $checkout-shipping-item__border;
        line-height: $checkout-shipping-item__line-height;
        margin: $checkout-shipping-item__margin;
        padding: $checkout-shipping-item__padding;
        transition: $checkout-shipping-item__transition;
        width: $checkout-shipping-item-tablet__width;
        display: inline-block;
        font-size: $font-size__base;
        position: relative;
        vertical-align: top;
        word-wrap: break-word;

        &.selected-item {
            border-color: $checkout-shipping-item__active__border-color;

            &:after {
                background: $checkout-shipping-item-icon__background-color;
                color: $checkout-shipping-item-icon__color;
                content: $checkout-shipping-item-icon__content;
                font-family: $icons__font-name;
                height: $checkout-shipping-item-icon__selected__height;
                width: $checkout-shipping-item-icon__selected__width;
                font-size: 27px;
                line-height: 21px;
                padding-top: 2px;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
            }

            .action-select-shipping-item {
                @include hide-for-small();
                visibility: hidden;
            }
        }
    }

    .field.addresses {
        @include lib-clearfix();
    }

    .action-show-popup {
        margin: 0 0 $indent__base;
        @include lib-button-primary-white();

        > span::before {
            content: '+';
            padding-right: $indent__xs;
        }
    }

    .action-select-shipping-item {
        margin: $indent__base 0 0;
        @include lib-button-primary();
    }

    .edit-address-link {
        @include button--inline-link();
        display: block;
        float: right;
        margin: 13px $indent__xs;
    }
}

//
//  Shipping Methods
//  _____________________________________________

.checkout-shipping-method {
    .step-title {
        margin-bottom: 0;
    }

    .no-quotes-block {
        margin: $indent__base 0;
    }
}

.methods-shipping .actions-toolbar .action.primary {
    @include lib-button-l();
    margin: $indent__base 0 0;
}

.table-checkout-shipping-method {
    thead th {
        display: none;
    }

    tbody {
        td {
            border-top: $checkout-shipping-method__border;
            padding-bottom: $checkout-shipping-method__padding;
            padding-top: $checkout-shipping-method__padding;

            &:first-child {
                padding-left: 0;
                padding-right: 0;
                width: 35px;
            }
        }

        tr:first-child td {
            border-top: none;
        }

        .row-error td {
            border-top: none;
            padding-bottom: $indent__s;
            padding-top: 0;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .form-login {
            margin: $checkout-shipping-item-mobile__padding;
            padding: $checkout-shipping-item-mobile__padding;
        }

        .shipping-address-item {
            border-bottom: 1px solid $checkout-shipping-item-before__border-color;
            margin: $checkout-shipping-item-mobile__margin;
            padding: $checkout-shipping-item-mobile__padding;
            width: 100%;

            &.selected-item {
                padding: $checkout-shipping-item-mobile__active__padding;
                border-bottom-width: 2px;

                .edit-address-link {
                    right: $checkout-shipping-item-icon__selected__width + $indent__s;
                }
            }
        }

        .form-login,
        .form-shipping-address {
            margin-top: $checkout-shipping-address__margin-top;
        }

        .action-select-shipping-item {
            float: none;
            margin-top: $indent__s;
            width: 100%;
        }

        .action-show-popup {
            width: 100%;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-shipping-method .actions-toolbar {
        > .primary {
            float: right;
        }

        .action .primary {
            margin: 0;
        }
    }

    .opc-wrapper {
        .form-login,
        .form-shipping-address {
            max-width: $checkout-shipping-address__max-width;
        }

        .form-login {
            padding-bottom: 20px;
            border-bottom: 1px solid $checkout-shipping-item-before__border-color;
        }
    }
}

@include min-screen($screen__l) {
    .opc-wrapper .shipping-address-item {
        width: $checkout-shipping-item__width;

        &::before {
            background: $checkout-shipping-item-before__border-color;
            height: $checkout-shipping-item-before__height;
            content: '';
            left: 0;
            position: absolute;
            top: 0;
            width: 1px;
        }

        &:nth-child(3n + 1)::before,
        &.selected-item::before,
        &.selected-item + .shipping-address-item::before {
            display: none;
        }
    }

    .table-checkout-shipping-method {
        min-width: 500px;
    }
}
