$nav-section-title__height: 60px;

//
//  Common
//  _____________________________________________
.nav-sections {
    background: $navigation__background;

    @include max-screen($mobile-nav__breakpoint) {
        -webkit-overflow-scrolling: touch;
        background: $flyout-active-color;
        transition: transform $transition-speed $transition-func;
        height: 100%;
        z-index: $z-index-5;
        transform: translateX(-100%);
        overflow: auto;
        position: fixed;
        top: 0;
        width: calc(100% - #{$active-nav-indent});
        max-width: 500px;
    }

    .nav-open & {
        @include max-screen($mobile-nav__breakpoint) {
            transition: transform .4s $transition-func;
            box-shadow: $flyout-box-shadow;
        }
    }
}

.nav-sections-items {
    @include max-screen($mobile-nav__breakpoint) {
        background: $flyout-background-color;
    }
}

.navigation {
    @include layout-container;
}

.nav-sections-item-switch {
    text-decoration: none;
    font-size: $font-size__s;
    font-weight: $font-weight__bold;
    color: $text__color;
}

.nav-toggle {
    @include lib-icon-text-hide();
    @include icon-svg(
        $icon       : menu,
        $fill       : $white,
        $fill-hover : $header-icons-color-hover,
        $width      : 28px
    );
    display: flex;
    align-items: center;
    min-height: $header__icon-height;
    float: left;
    font-size: 0;
    cursor: pointer;
    z-index: 14;
}

.nav-sections-item-title[aria-controls="close.menu"] {
    text-align: right;
    position: relative;

    a {
        @include button--close(
            $fill-color: $azule,
            $fill-hover-color: $header-icons-color-hover
        );
        font-size: 0; // hiding actual text
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        &::after {
            right: $layout__width-xs-indent;
        }
    }
}


//
//  Mobile
//  _____________________________________________

@include max-screen($mobile-nav__breakpoint) {
    .navigation {
        padding: 0;

        .parent {
            .level-top {
                @include icon-svg(
                    $icon     : expand,
                    $fill     : $navigation-level0-item__color,
                    $width    : 15px,
                    $height   : auto,
                    $hideLabel: false,
                    $pseudo   : after
                );
                position: relative;

                &::after {
                    @include vertically-center($position: absolute);
                    right: 15px;
                }

                &.ui-state-active {
                    @include icon-svg(
                        $icon     : collapse,
                        $fill     : $navigation-level0-item__color,
                        $hideLabel: false,
                        $pseudo   : after
                    );
                }
            }
        }
    }

    .nav-sections {
        .switcher {
            border-top: 1px solid $color-gray82;
            font-size: 16px;
            font-weight: 700;
            margin: 0;
            padding: 0.8rem 3.5rem 0.8rem 2rem;

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray82;
            }
        }

        .switcher-trigger {
            strong {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content : $icon-down,
                    $_icon-font-size    : 42px,
                    $_icon-font-position: after,
                    $_icon-font-display : block
                );

                &:after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                }
            }

            &.active strong {
                @include lib-icon-font-symbol(
                    $_icon-font-content : $icon-up,
                    $_icon-font-position: after
                );
            }
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
            padding: $indent__s 0;
        }

        .header.links {
            @include lib-list-reset-styles();
            margin: 0 15px;

            li {
                font-size: $font-size__base;
                margin: 0;
                font-weight: 700;

                .counter {
                    &::before {
                        content: '(';
                    }

                    &::after {
                        content: ')';
                    }
                }
            }

            .oauth-login a.action {
                @include max-screen($screen__m) {
                    @include lib-button-primary();
                }

                width: 100%;
            }

            .greet.welcome,
            li > a {
                padding: 9px $indent__xl 9px 0;
                font-weight: $font-weight__bold;
                font-size: $font-size__s;
                font-family: $font-family__secondary;
                border-bottom: 1px solid $cloud;
                line-height: 2;
                text-transform: uppercase;
            }

            a {
                text-decoration: $navigation-level0-item__text-decoration;
                color: $text__color;
                display: block;

                @include hover {
                    text-decoration: $navigation-level0-item__text-decoration;
                    display: block;
                }
            }

            .customer-menu .links {
                margin: 0;
                border-bottom: none;
            }
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .nav-sections {
            transform: none;
            z-index: 99;
        }

        .nav-toggle {
            &:after {
                background: $modal-overlay__background-color;
                content: '' !important; // override SVG
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 6; // above logo, z of 5
            }
        }
    }

    .nav-sections-items {
        @include lib-clearfix();
        position: relative;
        z-index: 1;
    }

    .nav-sections-item-title {
        background: darken($navigation__background, 5%);
        border-width: 0 0 1px 1px;
        box-sizing: border-box;
        float: left;
        height: ($nav-section-title__height + 1px); // 1px = border
        font-family: $font-family__secondary;
        text-transform: uppercase;
        line-height: $nav-section-title__height;
        text-align: center;
        width: 33.33%;

        &.active {
            background: $flyout-active-color;
            box-shadow: inset 0 4px $primary__color;
        }

        &[aria-controls="store.links"].active ~ [aria-controls="close.menu"] {
            background-color: $flyout-active-color;
            border-left: 0;
            border-bottom: 0;

            a {
                @include button--close($fill-color: $azule);
            }
        }

        .nav-sections-item-switch {
            @include hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        @include lib-clearfix();
        box-sizing: border-box;
        float: right;
        margin-left: -100%;
        margin-top: ($nav-section-title__height + 1px);
        width: 100%;
        padding: $indent__s 0;
        background-color: $flyout-active-color;

        &.active {
            display: block;
        }
    }

    // TODO: Fix this if reordering is safe.
    // scss-lint:disable DeclarationOrder
    @include lib-main-navigation(
        $_nav-background-color: $flyout-active-color,
        $_nav__indent-side: 17px,

        $_nav-level0-text-transform: none,
        $_nav-level0-item-margin: 0 15px,
        $_nav-level0-item-padding: 9px $indent__xl 9px 0,
        $_nav-level0-item-border: 1px solid $cloud,
        $_nav-level0-font-size: 12px,
        $_nav-level0-font-weight: $font-weight__bold,
        $_nav-level0-item-line-height: 2,
        $_nav-level0-item__active__border-style: none,

        $_submenu-font-size: 12px,
        $_submenu-item__active__border-width: 0 0 0 2px,
        $_submenu-item__active__border: 2px,
        $_submenu-item__active__border-color: $primary__color
    );
    // scss-lint:enable DeclarationOrder
}

//
//  Desktop
//  _____________________________________________

@include min-screen($mobile-nav__breakpoint) {
    .nav-toggle {
        display: none;
    }

    .nav-sections {
        flex-shrink: 0;
        flex-basis: auto;

        .cms-index-index & {
            margin-bottom: 0;
        }
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;
    }

    .nav-sections-item-content > * {
        display: none;
    }

    .nav-sections-item-content {
        > .navigation {
            display: block;
        }
    }

    // TODO: Fix this if reordering is safe.
    // scss-lint:disable DeclarationOrder
    @include lib-main-navigation-desktop(
        $_nav-background-color: transparent,
        $_nav-font-size: 14px,
        $_nav-font-weight: $heading__font-weight__bold,

        $_nav-level0-item-margin: 0 40px 0 0,
        $_nav-level0-item-padding: 0,
        $_nav-level0-item-line-height: 50px,
        $_nav-level0-item-color: $white,
        $_nav-level0-item-color-hover: $ice,
        $_nav-level0-item-color-active: $white,
        $_nav-level0-item__active__border-color: $ice,
        $_nav-level0-item__active__border-width: 0 0 3px,

        $_submenu-background-color: $white,
        $_submenu-item__active__border-color: $ice,
        $_submenu-item-padding: 4px $indent__base,
        $_submenu-box-shadow: $midnight-shadow,
        $_submenu-border-style: none,
        $_submenu-item-color: $text__color,
        $_submenu-item-color-hover: $azule,
        $_submenu-item__hover__background-color: transparent,
        $_submenu-font-weight: $font-weight__regular,
        $_submenu-font-size: 12px
    );
    // scss-lint:enable DeclarationOrder

    .navigation ul {
        padding: 5px $layout__width-xs-indent 0;
    }
}
