$dark-border: 1px solid $dusk;
$border-radius: 7px;
$reward-icon-width__desktop: 75px;
$reward-icon-width__mobile: 50px;
$platform-selector__min-width: 250px;

//
//  Rewards landing page general styles
//  ---------------------------------------------
.rounded-border {
    border: $dark-border;
    border-radius: $border-radius;
}

.btn__white-background__hover {
    @include lib-button-primary(
        $_button-color-hover: $midnight,
        $_button-gradient-color-start-hover: $white,
        $_button-gradient-color-end-hover: $white
    );
}

.catalog-category-view.page-layout-rewards-landing-page {
    background-color: $rewards-page__background-color;

    .breadcrumbs {
        .items,
        a:hover {
            color: $ice;
        }
    }

    .breadcrumbs a,
    .breadcrumbs .item::after,
    .category-header,
    .sidebar-main .filter,
    .product-item-link,
    .sorter,
    .toolbar-amount,
    .limiter {
        color: $white;
    }

    .column.main {
        @include min-screen($screen__m) {
            width: 75%;
            max-width: calc(100% - #{$platform-selector__min-width});
        }
    }

    .sidebar-main {
        @include min-screen($screen__m) {
            width: 25%;
            min-width: $platform-selector__min-width;
        }

        .filter {
            &-current .action.remove::before {
                color: $ice;
            }

            .filter-value {
                color: inherit;
            }

            .block-title button {
                border-color: $dusk;

                @include icon-svg(
                    $icon       : expand,
                    $fill       : $ice,
                    $width      : $toggling-icon-width,
                    $height     : auto,
                    $pseudo     : after,
                    $hideLabel  : false
                );

                &[aria-expanded="true"] {
                    @include icon-svg(
                        $icon       : collapse,
                        $fill       : $ice,
                        $width      : $toggling-icon-width,
                        $height     : auto,
                        $pseudo     : after,
                        $hideLabel  : false
                    );
                }
            }

            .filter-options {
                &-item {
                    border-color: $dusk;
                }

                &-title {
                    @include icon-svg(
                        $icon       : expand,
                        $fill       : $ice,
                        $width      : $toggling-icon-width,
                        $height     : auto,
                        $pseudo     : before,
                        $hideLabel  : false
                    );

                    &[aria-expanded="true"] {
                        @include icon-svg(
                            $icon       : collapse,
                            $fill       : $ice,
                            $width      : $toggling-icon-width,
                            $height     : auto,
                            $pseudo     : before,
                            $hideLabel  : false
                        );
                    }
                }

                .item {
                    color: $white;

                    @include hover {
                        color: $ice;
                    }
                }
            }
        }
    }

    .platform-selector {
        @include platform-selector(
            $_background: $midnight,
            $_border: $dark-border,
            $_image-width: 70px,
            $_border-radius: $border-radius,
            $_platform-background: $dusk,
            $_platform-background__selected: $azule-gradient,
            $_heading-color: $white
        );

        margin-bottom: $indent__base;
        color: $white;

        .linked-account {
            .member-since {
                color: $light-gold;
            }
        }

        .platform-selector__edit-btn.secondary,
        .sso-login {
            @extend .btn__white-background__hover;
        }

        .platform-selector__edit-btn.secondary {
            display: flex;
            padding: 12px 8px;
        }

        @include screen($screen__m, 1300px) {
            .linked-account {
                flex-direction: column;
                align-items: flex-start;
            }

            .username {
                font-size: 14px;
            }
        }
    }

    .products {
        grid-template-columns: repeat(2, minmax(100px, 1fr));
        grid-gap: 45px;

        @include max-screen($screen__m) {
            grid-gap: $indent__base;
        }

        @include max-screen($screen__s) {
            grid-template-columns: minmax(100px, 1fr);
        }

        .product-item {
            @extend .rounded-border;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0;
            background: $midnight;
            color: $white;

            @include hover {
                .product-item-link {
                    color: $ice;
                }
            }

            .cataloglabel {
                left: 0;
                width: 100%;

                &.position-TL,
                &.position-TC,
                &.position-TR {
                    top: 0;
                    border-radius: $border-radius $border-radius 0 0;
                }
            }

            .product-item-details {
                padding: $product-item__padding;
            }

            .product-image {
                &-wrapper {
                    border-top-left-radius: $border-radius;
                    border-top-right-radius: $border-radius;
                }

                &-container {
                    width: 100% !important; // override inline width
                }

                &-photo {
                    width: 100%;
                }
            }

            .product.name a,
            .price {
                font-size: 20px;

                @include max-screen($screen__s) {
                    font-size: 15px;
                }
            }

            &-info {
                position: relative;
            }

            .reward-claimable,
            .reward-purchased {
                position: absolute;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                pointer-events: none;
                z-index: 2; // overlap Mirasvit product badges
            }

            .reward-claimable {
                left: 0;
                box-sizing: border-box;
                max-height: 30px;
                padding: $indent__xs;
                width: 100%;
                background: $light-gold url('../images/textured-gold.png') no-repeat;
                background-size: cover;
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
                text-align: center;
                text-transform: uppercase;
                text-shadow: 1px 1px $medium-gold;
                font-weight: $font-weight__bold;

                img {
                    position: relative;
                    top: 7px;
                    margin-left: -($reward-icon-width__desktop) / 2;
                    width: $reward-icon-width__desktop;

                    @include max-screen($screen__s) {
                        top: 0;
                        margin-left: -($reward-icon-width__mobile) / 2;
                        width: $reward-icon-width__mobile;
                    }
                }
            }

            .reward-purchased {
                $triangle-width: 70px;

                height: $triangle-width;
                width: $triangle-width;
                border-top-left-radius: $border-radius;
                overflow: hidden;

                @include icon-svg(
                    $icon       : checkmark,
                    $width      : 18px,
                    $fill       : $white,
                    $height     : auto,
                    $pseudo     : before,
                    $hideLabel  : false
                );

                &::before,
                &::after {
                    position: absolute;
                    z-index: 3; // overlap triangle
                }

                &::before {
                    top: 12px;
                    left: 12px;
                }

                &::after {
                    content: '';
                    display: block;
                    top: 9px;
                    left: 9px;
                    height: 20px;
                    width: 20px;
                    border: 2px solid $white;
                    opacity: .6;
                    transform: rotate(45deg);
                }

                .triangle {
                    width: 0;
                    height: 0;
                    border-top: $triangle-width solid $sky;
                    border-right: $triangle-width solid transparent;
                    z-index: 2; // display underneath pseudo elements
                }
            }
        }
    }

    .bungie-rewards {
        &__top {
            padding: 0 $product-item__padding;
        }

        .bungie-reward {
            margin: 0 0 $indent__base;

            &__container {
                @extend .rounded-border;
                align-items: center;
                padding: $indent__s;
                font-weight: $font-weight__regular;
                background: $charcoal;
                color: $white;

                img {
                    margin: 0 $indent__xs;
                    background: none;
                    border-radius: 0;
                }

                @include max-screen($screen__m) {
                    flex-direction: column;
                    align-items: flex-start;

                    img {
                        align-self: center;
                        margin: $indent__s 0 $indent__base;
                    }
                }
            }

            &__status {
                font-weight: $font-weight__semibold;
                letter-spacing: $letter-spacing__extra-large;
                color: $white;
            }

            &__title {
                font-size: 16px;
            }

            &.claimed,
            &.purchased {
                .bungie-reward__container {
                    border-color: $light-gold;
                    text-shadow: 2px 2px $medium-gold;
                }
            }

            &.claimed .bungie-reward__container {
                background: $gold-gradient;
            }

            &.purchased .bungie-reward__container {
                background: $medium-gold;
            }
        }

        &__purchase-status,
        .claimed .bungie-reward__status,
        .purchased .bungie-reward__status {
            @include icon-svg(
                $icon       : checkmark,
                $width      : 15px,
                $fill       : $charcoal,
                $height     : auto,
                $pseudo     : before,
                $hideLabel  : false
            );

            padding: 0;
            background: none;
            color: $light-gold;
            font-size: $font-size__s;
            font-weight: $font-weight__semibold;
            letter-spacing: $letter-spacing__extra-large;

            &::before {
                padding: 2px;
                margin-right: $indent__xs;
                background: $light-gold;
                border-radius: 100%;
            }
        }

        &__deadlines {
            display: flex;
            flex-direction: row;
            margin-top: $indent__base;
            padding: $product-item__padding;
            background: $charcoal;
            border-top: $dark-border;
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;

            @include max-screen($screen__m) {
                flex-direction: column;
            }
        }

        &__earn-by,
        &__purchase-by {
            margin: 0 $indent__s;

            &__title {
                margin: 0;
                padding: 0;
                background: none;
                color: $ice;
            }

            @include max-screen($screen__m) {
                margin: $indent__xs 0;
            }
        }

        .action {
            @extend .btn__white-background__hover;
            margin: $indent__base 0;
        }
    }

    .toolbar-wrapper {
        align-items: center;

        .pages {
            display: flex;
            align-items: center;
            margin-bottom: 0;

            .page {
                font-size: $font-size__base;
            }

            strong.page {
                color: $white;
            }

            a.page {
                color: $storm;
            }

            .pages-item-previous,
            .pages-item-next {
                padding: 0;
                height: 32px;
                width: 32px;
                background: $azule;
                border-radius: 50px;

                .action {
                    span {
                        display: none;
                    }

                    &:hover {
                        background: none;
                    }

                    &.previous,
                    &.next {
                        padding: 0;
                    }

                    &.previous::before {
                        content: $icon-arrow-left-thin;
                        color: $white;
                        font-size: $font-size__base;
                        line-height: 32px;
                    }

                    &.next::after {
                        content: $icon-arrow-right-thin;
                        color: $white;
                        font-size: $font-size__base;
                        line-height: 32px;
                    }
                }
            }

            .item.current {
                border: none;
            }
        }
    }
}

.verify-email-block {
    @extend .rounded-border;

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $indent__base;
    padding: $indent__xs;
    background: $midnight;
    color: $white;

    &__warning-icon {
        margin: 0 $indent__xs;

        @include icon-svg(
            $icon: warning,
            $width: 32px,
            $height: 32px,
            $fill: #fed44c,
            $pseudo: before
        );
    }

    &__button {
        @include button--tertiary(
            $button__color: $ice,
            $button__color__hover: $white
        );
        margin-left: auto; // float to the right
        padding-left: $indent__base;
        text-align: center;
    }
}

//
//  CMS blocks
//  ---------------------------------------------
.rewards-banner-top {
    color: $white;
    padding-left: $layout-indent__width !important; // override Page Builder
    padding-right: $layout-indent__width !important; // override Page Builder

    [data-content-type='text'] {
        margin: $indent__s auto !important; // override Page Builder
        max-width: 600px;
    }

    [data-content-type="button-item"] {
        margin: 0 $indent__s $indent__s;

        &:not(.pagebuilder-button-outlined) .pagebuilder-button-primary {
            @extend .btn__white-background__hover;
        }

        @include max-screen($screen__s) {
            margin: 0 0 $indent__s;
            width: 100%;
        }
    }
}

.play-earn-reward {
    color: $white;

    [data-content-type="column-group"] {
        justify-content: center;
    }

    [data-content-type="column"] {
        img {
            max-height: 120px;
        }

        [data-content-type="heading"] {
            margin-bottom: $indent__xs !important; // override Page Builder
            text-transform: uppercase;
        }

        [data-content-type="text"] {
            margin: 0 auto !important; // override Page Builder
            max-width: 250px;
            font-size: 16px;
        }

        @include max-screen($screen__m) {
            margin-bottom: $indent__m !important; // override Page Builder
            flex-basis: 50%;

            img {
                max-height: 100px;
            }
        }
    }

    [data-content-type="buttons"] .pagebuilder-button-link {
        font-size: 16px;
        color: $primary__color;

        @include hover {
            color: $white;
        }
    }
}

.seasonal-rewards {
    padding: $indent__xs;
    color: $white;
    border-radius: $border-radius !important; // override Page Builder
    border: $dark-border !important; // override Page Builder

    [data-content-type="column"] {
        align-self: center;
    }

    [data-content-type="heading"] {
        margin: $indent__s 0 !important; // override Page Builder

        @include icon-svg(
            $icon       : bungie,
            $fill       : $white,
            $width      : 32px,
            $height     : 32px,
            $pseudo     : before,
            $hideLabel  : false
        );

        &::before {
            margin-right: $indent__s;
            padding-right: $indent__s;
            border-right: 1px solid $dusk;
        }
    }

    [data-content-type="text"] {
        font-size: 16px;
    }

    [data-content-type="button-item"] {
        .pagebuilder-button-link {
            font-size: 16px;
            color: $primary__color;

            @include hover {
                color: $white;
            }
        }

        .pagebuilder-button-primary {
            @extend .btn__white-background__hover;
        }
    }
}
