//
//  Common
//  _____________________________________________
$layered-nav__toggle-content-title_color: $toggling-icon-color;

.items {
    @extend .abs-reset-list;
}

.filter {
    &.block {
        margin-bottom: 0;
    }

    &-title {
        strong {
            $_shadow: inset 0 1px 0 0 $color-white, inset 0 -1px 0 0 fade($border-color__base, 30);

            padding: 7px $indent__s;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
            background-color: $toolbar-element-background;
            border: 1px solid $border-color__base;
            border-radius: 3px;
            box-shadow: $_shadow;


            &[data-count]:after {
                color: $color-white;
                background: $color-orange-red1;
                border-radius: 2px;
                content: attr(data-count);
                display: inline-block;
                font-size: .8em;
                line-height: 1;
                margin: 0 $indent__xs;
                min-width: 1em;
                padding: 2px;
            }

            &.disabled {
                opacity: .5;
            }
        }
    }

    .block-subtitle {
        display: none;
        border-bottom: $border-width__base solid $border-color__base;
        font-size: $font-size__base;
        font-weight: $font-weight__semibold;
        line-height: 1em;
        padding: $indent__s $indent__s $indent__base 0;

        &.filter-current-subtitle {
            font-size: 18px;
            font-weight: 700;

            &::before {
                font-size: 16px;
                margin-right: 5px;
            }

        }
    }

    .block-title button,
    &-options-title {
        @include button--transparent;
        @include button--font-reset;
        position: relative;
        width: 100%;
        cursor: pointer;
        text-align: left;
        overflow: visible;

        &::before {
            @include vertically-center($position: absolute);
            left: 0;
        }
    }

    .block-title {
        button {
            padding: 0 40px 0 15px;
            border-top: $border-width__base solid $border-color__base;
            border-bottom: $border-width__base solid $border-color__base;

            @include icon-svg(
                $icon       : expand,
                $fill       : $layered-nav__toggle-content-title_color,
                $width      : $toggling-icon-width,
                $height     : auto,
                $pseudo     : after,
                $hideLabel  : false
            );

            @include hover {
                // reset from button--transparent
                border-top: $border-width__base solid $border-color__base;
                border-bottom: $border-width__base solid $border-color__base;
            }

            @include min-screen($screen__m) {
                border-top: 0;

                @include hover {
                    border-top: 0;
                }
            }

            &::after {
                @include vertically-center($position: absolute);
                right: 15px;
            }

            &[aria-expanded="true"] {
                border-bottom: 0;
                @include icon-svg(
                    $icon       : collapse,
                    $fill       : $layered-nav__toggle-content-title_color,
                    $width      : $toggling-icon-width,
                    $height     : auto,
                    $pseudo     : after,
                    $hideLabel  : false
                );
            }
        }

        h2 {
            font-weight: $heading__font-weight__medium;

            @include max-screen($screen__m) {
                font-size: $font-size__s;
                text-transform: uppercase;
            }
        }
    }

    &.active .block-title {
        @include max-screen($screen__m) {
            border-bottom: 0;
        }
    }

    &-subtitle {
        display: none;
    }

    &-content {
        .filter-current {
            margin: 0;

            .items {
                padding: 3px 0;
                margin-bottom: 0;
            }

            .item {
                position: relative;
                z-index: 1;
                padding-left: 27px;
                margin-bottom: 20px;
                list-style-type: none;
            }

            .filter &-subtitle {
                display: none;
                border: none;
                padding-bottom: $indent__s;
            }

            .action.remove {
                @extend .abs-remove-button-for-blocks;
                left: -7px;
                position: absolute;
                top: -1px;
                padding-left: 0;

                &:before {
                    color: $azule;
                }
            }
        }
    }

    &-actions {
        margin-bottom: $indent__m;
        padding: 0;

        .filter-clear {
            @include lib-button-primary();

            display: block;
            padding: 8px;
            text-align: center;
            background: $primary__color;
        }
    }

    &-label::after {
        content: ': ';
    }

    &-value {
        color: $color-gray52;
    }

    &-options {
        margin: 0;

        &-item {
            border-bottom: $border-width__base solid $cloud;
        }

        &-title {
            @include icon-svg(
                $icon       : expand,
                $fill       : $toggling-icon-color,
                $width      : $toggling-icon-width,
                $height     : auto,
                $pseudo     : before,
                $hideLabel  : false
            );

            $side-padding: $indent__s + 30px;

            position: relative;
            z-index: 1;
            padding: $indent__s $side-padding;
            margin: 0;
            font-family: $font-family__secondary;
            font-weight: $font-weight__bold;
            font-size: $font-size__s;
            text-transform: uppercase;
            letter-spacing: $heading__letter-spacing__large;
            line-height: 2;
            word-break: break-word;
            cursor: pointer;

            &::before {
                left: $side-padding / 2 - $toggling-icon-width / 2;
            }

            &[aria-expanded="true"] {
                @include icon-svg(
                    $icon       : collapse,
                    $fill       : $toggling-icon-color,
                    $width      : $toggling-icon-width,
                    $height     : auto,
                    $pseudo     : before,
                    $hideLabel  : false
                );
            }
        }

        &-content {
            margin: 0;
            padding-bottom: $indent__s;
            padding-left: $indent__l;

            .items {
                padding-left: 0;
                &:after {
                    position: absolute;
                    left: -5px;
                    top: 4px;
                }

                .item {
                    &:hover,
                    &:focus,
                    &:active {
                        text-decoration: none;
                        background: none;
                        border: none;
                        color: $secondary__color;
                    }
                }

                &[aria-expanded="true"] {
                    @include lib-icon-font-symbol(
                        $_icon-font-content: $_dropdown-toggle-active-icon-content,
                        $_icon-font-position: after
                    );
                }
            }

            .item {
                line-height: 1.5em;
                margin: 20px 0;
                list-style-type: none;

                @include max-screen($screen__m) {
                    &:first-child {
                        margin-top: 0;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            a {
                display: block;
                padding-left: 5px;
                padding-right: 7px;
                margin-left: -5px;
                margin-right: -5px;
                text-decoration: none;

                @include hover {
                    text-decoration: none;
                }
            }

            .count {
                &:before {
                    content: '(';
                }

                &:after {
                    content: ')';
                }
            }

            .filter-count-label {
                @include lib-visually-hidden();
            }
        }
    }

    .filter-title button {
        @include min-screen($screen__m) {
            padding-left: 0;
            pointer-events: none;

            &::before,
            &::after {
                display: none !important; // overwrite [aria-expanded] check
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .filter {
        & &-subtitle {
            font-size: 20px;
            font-weight: $font-weight__regular;
        }

        &-options-content {
            padding: $indent__xs $indent__s $indent__s $indent__xl;

            .items {
                margin-bottom: 3px;
            }
        }
    }

    .filter .filter-current {
        .items {
            padding-left: 20px;
        }

        &-subtitle {
            position: relative;
            padding-left: 7px;
            z-index: 1;

            @include lib-icon-font(
                $_icon-font-content: $icon-collapse,
                $_icon-font-size: 13px,
                $_icon-font-position: before,
                $_icon-font-display: block
            );
        }

        .item {
            padding-left: 32px;
        }

        .action.remove {
            left: -2px;
        }

        &.active {
            .items {
                display: block;
            }

            & + .block-actions {
                display: block;
            }
        }
    }

    .filter-no-options {
        .filter-title {
            &:before {
                background: rgba(255,255,255,.5);
                content: '';
                display: block;
                height: 40px;
                left: -15px;
                margin-top: -60px;
                position: relative;
                width: 100px;
                z-index: 99;
            }
        }

        .filter-content {
            margin-bottom: $indent__base;
        }
    }

    .page-with-filter {
        .columns {
            .sidebar-main {
                order: 0;
            }
        }
    }
}

@include min-screen($screen__m) {
    .filter {
        &.block {
            margin-bottom: $indent__xl;
        }

        &-content {
            .item {
                margin: 10px 0 15px 0;

                &:last-child {
                    margin-bottom: 14px;
                }

                a {
                    padding-left: 16px;
                }
            }
        }

        &-options-content {
            padding-top: $indent__xs;

            .swatch-attribute {
                padding-left: 10px;

                button {
                    background: none;
                }
            }
        }

        &-actions {
            margin-bottom: 13px;
            padding-left: 0;
            padding-top: 5px;
        }

        &.active &-options,
        &-options {
            background: transparent;
            clear: both;
            display: block;
            overflow: initial;
            position: static;
        }

        &-subtitle {
            display: block;
            position: static;
        }
    }

    .page-layout-1column {
        .toolbar-products {
            position: absolute;
            top: 0;
            width: 100%;
        }

        .products ~ .toolbar-products {
            position: static;
        }

        &.page-with-filter .column.main {
            padding-top: 45px;
            position: relative;
            z-index: 1;
        }

        .filter {
            &.block {
                border-top: 1px solid $border-color__base;
            }

            &-content {
                margin-top: $indent__s;
            }

            &-subtitle {
                display: none;
            }

            &-options {
                &-item {
                    border: 0;
                    display: inline-block;
                    margin-right: $indent__m;
                    position: relative;

                    &.active {
                        z-index: 2;

                        @include hover {
                            z-index: 3;
                        }

                        .filter-options-content {
                            visibility: visible;
                        }

                        &:after,
                        &:before {
                            @include lib-arrow(up, 8px, $color-black);
                            bottom: -1px;
                            content: '';
                            display: block;
                            left: $indent__xs;
                            position: absolute;
                            z-index: 3;
                        }

                        &:after {
                            border-bottom-color: $color-white;
                            margin-top: 2px;
                            z-index: 4;
                        }
                    }
                }

                &-title {
                    padding: 0 $indent__base 0 0;

                    &:after {
                        right: 2px;
                        top: 3px;
                        z-index: 3;
                    }
                }

                &-content {
                    $_shadow: 0 3px 5px 0 rgba(50, 50, 50, .75);

                    background-color: $color-white;
                    box-shadow: $_shadow;
                    border: 1px solid $border-color__base;
                    padding: $indent__xs 0;
                    position: absolute;
                    top: 100%;
                    visibility: hidden;
                    width: 180px;
                    z-index: 2;

                    .item {
                        margin: 0;
                        padding: $indent__xs;

                        a {
                            margin-left: 0;
                        }

                        @include hover {
                            background-color: $color-gray91;
                        }
                    }
                }
            }

            &-current {
                display: inline;
                line-height: 35px;

                &-subtitle {
                    color: $text__color__muted;
                    display: inline;
                    font-size: $font-size__base;
                    font-weight: normal;
                    padding: 0;

                    &:after {
                        content: ':';
                    }
                }

                .item,
                .items {
                    display: inline;
                }

                .item {
                    margin-right: $indent__m;
                    white-space: nowrap;
                }

                .action.remove {
                    line-height: normal;
                }
            }

            &-actions {
                display: inline;
                white-space: nowrap;

                & ~ .filter-options {
                    margin-top: $indent__m;
                }
            }
        }
    }
}

@media (max-width: 880px) {
    .navigation {
        width: -webkit-fill-available;
        position: relative;
    }
}
