@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@mixin platform-selector(
    $_background                    : none,
    $_image-width                   : 80px,
    $_border                        : 1px solid $dusk,
    $_border-radius                 : 0,
    $_platform-background           : $storm,
    $_platform-background__selected : $midnight-gradient,
    $_heading-color                 : $text__color
) {
    display: inline-block;
    padding: $indent__base;
    box-sizing: border-box;
    background: $_background;
    border: $_border;
    border-radius: $_border-radius;
    width: 450px;
    max-width: 100%;

    @include max-screen($screen__m) {
        width: 100%;
    }

    .linked-account {
        display: flex;
        align-items: center;
        margin-bottom: $indent__base;

        &__user-info {
            font-size: 16px;
        }

        .username {
            color: $_heading-color;
        }

        &__avatar {
            margin-right: $indent__base;
            border-radius: 50px;
            width: $_image-width;
            height: $_image-width;
            object-fit: contain;
            background: $cloud;
        }

        &__error {
            padding: $indent__s;
            background: $error__background-color;
            color: $error__color;
        }
    }

    .member-since {
        @include lib-heading(h3);
        margin: $indent__s 0;
        background: none;
        color: $text__color;
        letter-spacing: $letter-spacing__extra-large;
        font-size: $font-size__s;
    }

    .platform-list {
        position: relative;

        .select-platform {
            @include lib-heading(h3);
            margin: 0 0 6px;
            background: none;
            color: $_heading-color;
            font-size: $font-size__s;
        }

        .loading-mask {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;

            .loader > img {
                position: static;
            }
        }

        &__platform,
        .cross-save__notification {
            @include lib-heading(h3);
            display: flex;
            align-items: center;
            box-sizing: border-box;
            margin: 0 0 6px;
            padding: 14px 8px;
            width: 100%;
            background: $_platform-background;
            color: $white;
            font-size: $font-size__s;

            &.selected-platform {
                background: $_platform-background__selected;
            }

            label {
                display: flex !important; // override custom-input
                align-items: center;
                margin: 0;
                font-weight: $font-weight__bold;
                min-height: unset;

                &::before,
                &::after {
                    @include vertically-center(absolute);
                }
            }

            input[type="radio"] + label::after {
                top: 50%;
            }

            &[disabled] {
                opacity: 1;
                color: $storm;
                background: $cloud;

                label {
                    @include icon-svg(
                        $icon       : block,
                        $fill       : $storm,
                        $width      : 24px,
                        $height     : auto,
                        $pseudo     : before,
                        $hideLabel  : false
                    );

                    &::before {
                        background: none;
                        border: 0;
                    }
                }
            }
        }

        .cross-save__notification {
            justify-content: center;
            background: $midnight-gradient;
            color: $button__color;
        }
    }

    .no-destiny-account {
        margin-bottom: $indent__base;
        line-height: $line-height__base;

        h3 {
            margin: $indent__s 0;
            padding: 0;
            color: inherit;
            background: none;

            @include max-screen($screen__m) {
                font-size: $font-size__s;
            }
        }
    }

    .platform-selector {
        &__edit-btn {
            @include lib-button-outlined(
                $_button-color: $midnight,
                $_button-color-hover: $white,
                $_button-gradient-color-start-hover: $button__gradient-color-start,
                $_button-gradient-color-end-hover: $button__gradient-color-end,
                $_button-padding: 12px 8px
            );

            display: flex;
            justify-content: center;
            align-items: center;
            width: 100% !important;
            margin-bottom: $indent__s;
            line-height: 1;
        }

        &__sync-accounts {
            @include icon-svg(
                $icon       : sync,
                $width      : 17px,
                $height     : auto,
                $pseudo     : before,
                $hideLabel  : false
            );

            margin: 0;
            padding: 0;
            width: 100%;
            text-align: center;
            color: $text__color;
            background: none;

            @media (prefers-reduced-motion: no-preference) {
                &.syncing::before {
                    animation: spin 800ms infinite;
                }
            }

            &.success {
                @include icon-svg(
                    $icon       : checkmark,
                    $width      : 17px,
                    $height     : auto,
                    $pseudo     : before,
                    $hideLabel  : false
                );
            }

            &::before {
                margin-right: $indent__xs;
            }
        }

        &__sync-accounts-message {
            display: block;
            margin-top: $indent__s;
            color: $error__color;
        }

        &__sign-in {
            display: block;
            margin: 0 0 $indent__s;
            padding: 0;
            text-align: center;
            background: none;
            color: inherit;
            font-size: $font-size__s;
        }
    }

    .platform-list__platform,
    .platform-selector__edit-btn {
        min-height: 44px;
    }

    .sso-login {
        @include animated-arrows();
        width: 100%;
    }
}
