$upsell-products__img-width: 150px !default;
$upsell-products__img-width-mobile: 140px !default;

.block.upsell {
    .block-title {
        > * {
            font-family: $font-family__base;
            font-weight: $font-weight__light;

            @include max-screen($screen__m) {
                margin-bottom: $indent__s;
            }

            @include min-screen($screen__l) {
                margin-top: 0;
                padding-top: 0;
            }
        }
    }
}

.products-upsell {
    margin-top: 0;

    .products {
        &.product-items {
            width: 100%;

            @include min-screen($screen__m) {
                margin-left: 0;
            }
        }

        .product-item {
            width: 50%;

            @include min-screen($screen__m) {
                width: 25%;
                margin-bottom: 0;
                padding-top: $indent__s;
                padding-left: 0;
                padding-bottom: $indent__s;
            }

            .product-image-container {
                max-width: $upsell-products__img-width-mobile;

                @include min-screen($screen__m) {
                    max-width: $upsell-products__img-width;
                }
            }
        }
    }
}
