.contact.form {
    margin-top: $indent__m;
    max-width: $form-field__width__desktop;
}

.contact-index-index {
    .contact-description {
        max-width: $form-field__width__desktop;
    }
}
