// reset button styles
@mixin button--transparent {
    padding: 0;
    text-align: inherit;
    color: inherit;
    background: transparent;
    border: 0;

    @include hover {
        color: inherit;
        background: transparent;
        border: 0;
    }
}

@mixin button--font-reset {
    letter-spacing: normal;
    text-transform: none;
    -webkit-font-smoothing: auto; // reset from button styling
}

@mixin button--inline-link {
    @include button--transparent;
    @include button--font-reset;
    @include lib-link();
    display: inline;
    margin: 0;
    width: auto;
    font-family: inherit;
    font-size: inherit;
    vertical-align: inherit;
}

@mixin button--split {
    .action.split {
        padding: 7px 12px;
        text-decoration: none;
        border: $border-width__base solid $c-secondary__color;
        border-right: 0;

        @include hover {
            border: $border-width__base solid $primary__color;
            border-right: 0;

            ~ .action.toggle {
                border-color: $primary__color;
            }
        }
    }

    .action.toggle {
        padding: $indent__xs 2px;
        border: $border-width__base solid $c-secondary__color;

        @include hover {
            border: $border-width__base solid $primary__color;
        }

        &:after {
            font-size: 29px;
            color: inherit;
        }
    }

    .items {
        margin-top: 35px;
        min-width: 160px;
        border: $border-width__base solid $c-secondary__color;

        &::before,
        &::after {
            display: none; // remove dropdown arrows
        }
    }

    @include hover {
        button.action.split {
            border-color: $primary__color;
        }
    }
}

/*doc
---
title: Tertiary Button Link
name: links_tertiary
category: Blocks - Links
---

```html_example
<a href="#" class="abs-action-link-button">View and Edit Cart</a>

<a href="#" class="action">View and Edit Cart</a>
```
*/
@mixin button--tertiary(
    $button__color: $button-tertiary__color,
    $button__color__hover: $button-tertiary__hover__color
) {
    padding: $button-tertiary__padding;
    background: none;
    font-family: $button-tertiary__font-family;
    font-weight: $font-weight__bold;
    font-size: $font-size__s;
    letter-spacing: $button-tertiary__letter-spacing;
    text-transform: $button-tertiary__text-transform;
    text-decoration: $button-tertiary__text-decoration;
    color: $button__color;
    transition: box-shadow 150ms;

    &:visited {
        color: $button__color;
    }

    &:focus {
        box-shadow: $focus__box-shadow;
    }

    @include hover {
        text-decoration: none;
        color: $button__color__hover;
    }
}

@mixin button--close($fill-color: $link__color, $fill-hover-color: $link__hover__color) {
    @include icon-svg(
        $icon       : remove,
        $fill       : $fill-color,
        $fill-hover : $fill-hover-color,
        $width      : $toggling-icon-width,
        $pseudo     : after
    );

    &::after {
        @include vertically-center($position: absolute);
    }

    &:focus {
        box-shadow: $focus__box-shadow;
    }
}

@mixin sliding-gradient(
    $_button-gradient-color-start,
    $_button-gradient-color-end,
    $_button-gradient-color-start-hover,
    $_button-gradient-color-end-hover,
    $_button-gradient-direction: 45deg,
) {
    $gradient-from: linear-gradient($_button-gradient-direction, $_button-gradient-color-start, $_button-gradient-color-end) no-repeat border-box;
    $gradient-to: linear-gradient($_button-gradient-direction, $_button-gradient-color-start-hover, $_button-gradient-color-end-hover) no-repeat border-box;

    background: $gradient-from, $gradient-to;
    background-size: 100%, 100%;
    background-position: 0 0, 0 100%;
    background-origin: padding-box, padding-box;
    transition: background-position 300ms, color 300ms;

    &:hover,
    &:focus {
        background-position: 0 -80px, 0 -100%;
    }
}

@mixin animated-arrows(
    $_padding       : $indent__s,
    $_arrow-size    : 20px
) {
    @include icon-svg(
        $icon       : 'bungie-arrows',
        $width      : $_arrow-size,
        $height     : auto,
        $pseudo     : before,
        $hideLabel  : false
    );

    @include icon-svg(
        $icon       : 'bungie-arrows',
        $width      : $_arrow-size,
        $height     : auto,
        $pseudo     : after,
        $hideLabel  : false
    );

    @media (prefers-reduced-motion: no-preference) {
        @include icon-svg(
            $icon       : 'bungie-arrows-animated',
            $width      : $_arrow-size,
            $height     : auto,
            $pseudo     : before,
            $hideLabel  : false
        );

        @include icon-svg(
            $icon       : 'bungie-arrows-animated',
            $width      : $_arrow-size,
            $height     : auto,
            $pseudo     : after,
            $hideLabel  : false
        );
    }

    position: relative;
    padding-left: calc(#{$_arrow-size} + #{$_padding} * 2);
    padding-right: calc(#{$_arrow-size} + #{$_padding} * 2);

    &::before,
    &::after {
        @include vertically-center($position: absolute);
    }

    &::before {
        left: $_padding;
    }

    &::after {
        right: $_padding;
        transform: translateY(-50%) rotate(180deg);
    }
}
