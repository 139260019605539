//
//  Bryant Park settings
//  ---------------------------------------------
//
//  Table of Contents:
//
//      Colors
//      Layout grid
//      Typography
//      Buttons
//      Navigation
//      Icons
//      Messages
//      Loaders
//      Popups
//      Variables
//      Forms
//      Accordion and Tabs
//      Pager
//      Modals
//      Popups
//      Progress Bar
//      Pages
//      Sidebar
//      Cart
//      Variables missing in extend.scss
//      Magento CMS Widgets
//      Account pages

//
// Colors
// ---------------------------------------------

// Bryant Park theme-specific colors
$c-scorpion: #424242;
$c-dusty-grey: #999;
$c-silver: #ccc;
$c-christine: #F06212;
$c-green: #006400;
$c-alizarian: #e02b27;
$c-yellow: #fdf0d5;
$c-brown: #6f4400;

// Bungie theme-specific colors
$midnight: #12171d;
$charcoal: #1c2025;
$deep-sea: #142941;
$dusk: #3b4550;
$sky: #007ABD;
$azule: #005989;
$ice: #009ade;
$storm: #7c8e95;
$cloud: #d6dbdd;
$smoke: #f5f5f5;
$white: #ffffff;
$crimson: #d62547;
$light-gold: #d4a32d;
$medium-gold: #a1750b;

// Gradients and shadows
$midnight-gradient: linear-gradient(45deg, $midnight 0%, $deep-sea 100%);
$azule-gradient: linear-gradient(45deg, $azule 0%, $sky 100%);
$ice-gradient: linear-gradient(45deg, $ice 0%, #6DD2FD 100%);
$smoke-gradient: linear-gradient(45deg, $cloud 0%, #eee 100%);
$gold-gradient: linear-gradient(135deg, $medium-gold, $light-gold, $medium-gold, $light-gold, $medium-gold);
$midnight-fade: linear-gradient($midnight 0%, $midnight 100%);
$midnight-shadow: 0px 10px 20px rgba(18, 23, 29, .15);

// Standard colors
$c-pink: #fae5e5;
$c-light-green: #e5efe5;
$c-red: $c-alizarian;
$c-orange: $c-christine;
$c-blue: #1979c3;
$c-light-grey: $c-silver;
$c-medium-grey: $c-dusty-grey;
$c-dark-grey: $c-scorpion;

// Main site colors
$primary__color: $ice;
$secondary__color: $c-scorpion;

$active__color: $primary__color; // standard color for designating an element as being "active"

$c-border: $c-silver; // most borders used throughout site

$page__background-color: $smoke;
$rewards-page__background-color: #090b0c;

//
//  Layout grid
//  ---------------------------------------------

$total-columns: 12;
$grid-width: ($column-width * $total-columns) + ($gutter-width * ($total-columns - 1));

// Be sure to update js/utils/breakpoints.js as well
$screen__xxs: 320px;
$screen__xs__between: 375px;
$screen__xs: 480px;
$screen__s: 640px;
$screen__m: 768px;
$screen__ml: 950px;
$screen__l: 1024px;
$screen__xl: 1440px;
$mobile-nav__breakpoint: 880px; // Update mediaCheck variable in js/menu.js too

// Variables for layout columns
$layout-column__sidebar-width: 5;

// Checkout columns width
$layout-column-checkout__width-left: 3;

//
//  Typography
//  ---------------------------------------------

// Font families
$font-family__sans-serif: Arial, Verdana, sans-serif;
$font-family__base: 'Open Sans', $font-family__sans-serif;
$font-family__secondary: 'neue-haas-grotesk-display', $font-family__base;

$font-weight__light: 300;
$font-weight__regular: 400;
$font-weight__semibold: 600;
$font-weight__bold: 700;

$font-size__s: 12px;
$font-size__base: 14px;
$font-size__base--desktop: $font-size__base;

$letter-spacing__base: .6px;
$letter-spacing__base--desktop: .65px;
$letter-spacing__large: 1.5px;
$letter-spacing__extra-large: 2px;

$line-height__base: 1.43;

$text__color: $midnight;
$text__color-alt: $c-dusty-grey;

$focus__box-shadow: none;

// Link styles
$link__color: $azule;
$link__hover__color: $text__color;
$link__hover__text-decoration: none;
$link__visited__color: $azule;
$link__active__color: $link__hover__color;
$link__font__weight: $font-weight__bold;
$link__text-decoration: none;
$link__visited__text-decoration: $link__text-decoration;
$link__active__text-decoration: $link__visited__text-decoration;
$paragraph-link__text-decoration: $link__text-decoration;

$product-name-link__color: $midnight;
$product-name-link__color__active: $azule;
$product-name-link__color__hover: $azule;
$product-name-link__color__visited: $midnight;
$product-name-link__text-decoration: none;
$product-name-link__text-decoration__hover: none;
$product-name-link__text-decoration__active: none;
$product-name-link__text-decoration__visited: none;
$product-name-link__font-size: 15px;
$product-name-link__font-size-mobile: 10px;

// Headings
$heading__font-family__base: $font-family__secondary;
$heading__font-family__secondary: $font-family__base, $font-family__sans-serif;
$heading__font-weight__base: 300;
$heading__font-weight__medium: 600;
$heading__font-weight__bold: 700;
$heading__letter-spacing__base: .72px;
$heading__letter-spacing__medium: .96px;
$heading__letter-spacing__large: $letter-spacing__large;

$heading-h1: (
  font-family: $heading__font-family__base,
  font-size: 22px,
  font-size-desktop: 28px,
  font-weight: $heading__font-weight__bold,
  letter-spacing: $heading__letter-spacing__base,
  line-height: 1.15,
  line-height-desktop: 1.1
);

$heading-h2: (
  font-family: $heading__font-family__base,
  font-size: 18px,
  font-size-desktop: 24px,
  font-weight: $heading__font-weight__medium,
  letter-spacing: $heading__letter-spacing__base,
  line-height: 1
);

$heading-h3: (
  font-family: $heading__font-family__base,
  font-size: 10px,
  font-size-desktop: $font-size__s,
  font-weight: $heading__font-weight__bold,
  text-transform: uppercase,
  line-height: 1.2,
  letter-spacing: $heading__letter-spacing__large,
  letter-spacing-desktop: $heading__letter-spacing__large,
  line-height-desktop: 1.18,
  color: $white,
);

$heading-h4: (
  font-family: $heading__font-family__secondary,
  font-size: 14px,
  font-size-desktop: 18px,
  font-weight: $font-weight__regular,
  letter-spacing: $heading__letter-spacing__base,
  line-height: 1.22,
  line-height-desktop: 1.22
);

$heading-h5: (
  font-family: $heading__font-family__secondary,
  font-size: 14px,
  font-size-desktop: 14px,
  font-weight: $font-weight__regular,
  letter-spacing: $heading__letter-spacing__base,
  line-height: 1.25,
  line-height-desktop: 1.25
);

$heading-h6: (
  font-family: $heading__font-family__secondary,
  font-size: $font-size__s,
  font-size-desktop: $font-size__s,
  font-weight: $font-weight__regular,
  letter-spacing: $heading__letter-spacing__base,
  line-height: 1.25,
  line-height-desktop: 1.25
);


//
//  Buttons
//  ---------------------------------------------

// Default (Secondary Button)
$button__font-family: $font-family__secondary;
$button__font-size: $font-size__s;
$button__padding: 13px 30px;
$button__color: $white;
$button__background: $color-white;
$button__hover__background: $secondary__color;
$button__hover__color: $color-white;
$button__active__color: $secondary__color;
$button__border: none;
$button__hover__border: $button__border;
$button__active__color: inherit;
$button__active__border: $button__border;
$button__border-radius: 0;
$button__line-height: $button__font-size + 2;
$button__text-transform: uppercase;
$button__letter-spacing: 1.5px;

// Default secondary button gradient colors
$button__gradient-color-start: $midnight;
$button__gradient-color-end: $deep-sea;
$button__gradient-color-start-hover: $sky;
$button__gradient-color-end-hover: $azule;

// Primary Button
$button-primary__padding: $button__padding;
$button-primary__border: 0;
$button-primary__hover__border: 0;
$button-primary__active__border: 0;
$button-primary__color: $color-white;
$button-primary__background: $primary__color;
$button-primary__hover__color: $color-white;
$button-primary__hover__background: $secondary__color;
$button-primary__active__background: $secondary__color;

// Small button
$button__font-size__s: $button__font-size; // Small Buttons (PDP Add to Wishlist / PDP Compare)
$button__padding__s: $button__padding; // use same as normal button

// Large button
$button__font-size__l: $button__font-size__s;
$button__padding__l: $button-primary__padding;
$button__line-height__l: $button__font-size__l + 2;

// Tertiary Button
$button-tertiary__padding: 10px;
$button-tertiary__border: 0;
$button-tertiary__hover__border: 0;
$button-tertiary__active__border: 0;
$button-tertiary__color: $midnight;
$button-tertiary__hover__color: #146896;
$button-tertiary__background: transparent;
$button-tertiary__hover__background: transparent;
$button-tertiary__active__background: transparent;
$button-tertiary__font-family: $button__font-family;
$button-tertiary__letter-spacing: 1.44px;
$button-tertiary__text-transform: uppercase;
$button-tertiary__text-decoration: none;

//
//  Navigation
//  ---------------------------------------------

$navigation__background: transparent;

$navigation-level0-item__color: $azule;
$navigation-level0-item__text-decoration: none;

//  Desktop navigation
$navigation-desktop-level0-item__line-height: 47px;

$submenu-desktop__font-weight: $font-weight__regular;
$submenu-desktop-item__active__color: $primary__color;

$header__icon-height: 30px;
$header-icons-color: $ice;
$header-icons-color-hover: $primary__color;

$active-nav-indent: 54px;

// Mobile navigation flyout
$flyout-active-color: $color-white;
$flyout-background-color: $smoke;
$flyout-box-shadow: 0 0 12px 2px rgba(#000, 0.35);

//
//  Icons
//  ---------------------------------------------

// Fonts
$icons__font-path: '../fonts/Blank-Theme-Icons/Blank-Theme-Icons';
$icons__font-name: 'icons-blank-theme';

$icon-success: '\e60e';
$icon-error: '\e61f';
$icon-edit: '\e606';
$icon-print: '\e624';
$icon-star-empty: '\e625';
$icon-download: '\e626';
$icon-private: '\e629';
$icon-present: '\e62a';
$icon-gift-registry: '\e62b';

$icon-calendar__font-size: 45px;
$icon-wishlist-empty: inherit;
$icon-comment-reflected: inherit;
$icon-compare-empty: '\e61f';
$icon-arrow-up-thin: inherit;
$icon-arrow-right-thin: '\e624';
$icon-arrow-left-thin: '\e625';
$icon-arrow-down-thin: inherit;
$icon-arrow-right-thick: '\e619';
$icon-arrow-left-thick: '\e61a';
$icon-wishlist-empty: '\e601';

$icon-facebook: '\e903';
$icon-googleplus: '\e900';
$icon-instagram: '\e904';
$icon-pinterest: '\e902';
$icon-twitter: '\e901';
$icon-collapse: '\e60f';
$icon-arrow-down: '\e614';
$icon-expand: '\e60f';
$icon-collapse: '\e61c';

$toggling-icon-width: 14px;
$toggling-icon-color: $azule;
//
//  Messages
//  ---------------------------------------------
$success__color: $c-green;
$success__background-color: $c-light-green;
$warning__color: $c-brown;
$warning__background-color: $c-yellow;
$error__color: $crimson;
$error__background-color: #f8e5e9;

$message__padding: 12px $indent__base 12px $indent__m;
$message__line-height: inherit;
$message-icon__font-size: 24px;
$message-icon__inner-padding-left: 45px;
$message-icon__top: 22px;
$message-error-icon: $icon-error;
$message-success-icon: $icon-success;
$message-link__color: inherit;
$message-link__color-hover: inherit;
$message-link__color-active: inherit;

//
//  Loaders
//  ---------------------------------------------

$loader-overlay__background-color: rgba(#fff, 0.7);
$loader-overlay__z-index: 9999;

//
//  Popups
//  _____________________________________________

$popup-icon-font__size: 30px;
$popup-icon-font__line-height: 1;
$popup-icon-font__color: $link__color;
$popup-icon-font__color-hover: $link__hover__color;
$popup-icon-font__color-active: $link__active__color;

//
//  Forms
//  ---------------------------------------------

// Input text must be (at least) 16px to avoid the dreaded iOS zoom bug/feature
$form-element-input__font-size--mobile: 16px;
$form-element-input__border-color: $storm;
$form-element-input__border: 1px solid $form-element-input__border-color;
$form-element-input__height: 38px; // usually equivalent to button height
$form-element-input-placeholder__color: $c-silver;
$form-element-input__font-family: $font-family__base;
$form-element-input__color: $text__color;
$form-element-input__font-weight: $font-weight__regular;
$select__height: $form-element-input__height;

// Form fieldset
$form-fieldset__margin: 0;
$form-fieldset-legend__font-size: 18px;
$form-fieldset-legend__font-weight: $font-weight__light;
$form-fieldset-legend__margin: 0 0 $indent__base;
$form-fieldset-legend__padding: 0 0 $indent__s;
$form-fieldset-legend__width: 100%;

// Form field
$form-field-type-revert: inherit;
$form-field__additional-vertical-indent: $indent__s;
$form-field__width__desktop: 460px;
$form-field--vertical-indent: $indent__base;
$form-field__vertical-indent__desktop: $form-field--vertical-indent;
$form-field__background: $white;

// Form field label
$form-field-label__font-size: $font-size__s;
$form-field-label__font-weight: $font-weight__regular;
$form-field-label-asterisk__color: inherit;
$form-hasrequired__color: $form-field-label-asterisk__color;
$form-field-type-label-block__margin: 0 0 5px;
$form-field-type-label-inline__margin: $form-field-type-label-block__margin;
$form-field-type-label-inline__width: 1%;

// Select
$select__background: $form-field__background;
$select__border: $form-element-input__border;
$select__border-radius: $form-element-input__border-radius;
$select__height: $form-element-input__height;
$select__width: $form-element-input__width;
$select__margin: $form-element-input__margin;
$select__padding: $indent__xs $indent__s 4px;
$select__font-size: $form-element-input__font-size;
$select__color: $form-element-input__color;
$select__font-family: $form-element-input__font-family;
$select__font-weight: $form-element-input__font-weight;
$select__font-style: $form-element-input__font-style;
$select__line-height: $form-element-input__line-height;

// Form field note icon
$form-field-note-icon-font__content: inherit;
$form-field-note__color: inherit;
$form-calendar-icon__color: $primary__color;

// Form validation
$form-validation-note__color-error: $crimson;
$form-element-validation__border-error: #c5394b;

//
// Accordion and Tabs
// ------------------------------

$tab-content__padding-top: 0;
$tab-content__padding-right: 10px;
$tab-content__padding-bottom: 10px;
$tab-content__padding-left: 10px;

$tab-control__padding-top: 0;
$tab-control__padding-left: 10px;
$tab-control__padding-bottom: 0;

$tab-control__color: $text__color;
$tab-control__height: 40px;
$tab-control__font-size: 16px;

//
//  Tables
//  ---------------------------------------------

$panel__background-color: $c-light-grey;

//
//  Pager
//  ---------------------------------------------

$pager-reset-spaces: true;  // Reset spaces between inline-block elements
$pager-item__display: inline-block;

//  Rating
//  ---------------------------------------------

$rating-icon__font-size: 28px;
$rating-icon__letter-spacing: -9px;
$rating-icon__active__color: $active__color;

// Split dropdown
$dropdown-list__z-index: 100;

$_dropdown-toggle-icon-content: $icon-collapse;
$_dropdown-toggle-active-icon-content: $icon-expand;
$_dropdown-split-toggle-icon-content: $icon-collapse;
$_dropdown-split-toggle-active-icon-content: $icon-expand;

$_dropdown-split-list-item-hover-background: transparent;

// Actions-toolbar
$actions-toolbar__margin: 0;
$actions-toolbar__padding: 0;
$actions-toolbar-actions__position: left;
$actions-toolbar-actions__reverse: false;
$actions-toolbar-actions-primary__margin: 0 15px 0 0;

//
//  Modals
//  ---------------------------------------------

$modal-slide__first__indent-left: 44px;
$modal-slide-mobile__background-color: $flyout-active-color; // mobile nav flyout and modal are similar enough to warrant the same background
$modal-overlay__background-color: rgba($color-black, .7);
$modal-title__border: $c-border;

$modal__box-shadow: $flyout-box-shadow;

//
//  Popups
//  ---------------------------------------------
$modal-popup__padding: 0;

// Catalog
$tocart-min-width: 200px;

$width__product-related: 200px;
$width__product-info: 375px;
$product-item__padding: 15px;

$checkout-summary-items__max-height: 100%;

// Checkout tooltip
$checkout-tooltip-icon-arrow__font-size: 10px;
$checkout-tooltip-icon__font-size: 21px;

$checkout-tooltip-content-mobile__right: -($indent__s);
$checkout-tooltip-content-mobile__top: 30px + $checkout-tooltip-icon-arrow__font-size;
$checkout-tooltip-icon__color: $c-light-grey;
$checkout-tooltip-content__background-color: $c-light-grey;
$checkout-tooltip-content__padding: 12px;
$checkout-tooltip-content__border-width: 1px;

$checkout-tooltip-content__border-color: $c-medium-grey;
$checkout-tooltip-content__font-size: $font-size__base;
$checkout-tooltip-content__width: 270px;
$checkout-tooltip-content__active__border-color: $c-dark-grey;
$checkout-tooltip-icon-arrow__left: -( $checkout-tooltip-content__padding + $checkout-tooltip-icon-arrow__font-size - $checkout-tooltip-content__border-width);
$checkout-billing-address-details__line-height: 27px;

// Checkout Sidebar Shipping Information
$checkout-sidebar-shipping-information-edit-icon__content: $icon-edit;
$checkout-sidebar-shipping-information-edit-icon__font-size: 18px;
$checkout-sidebar-shipping-information-edit-icon__line-height: 20px;
$checkout-sidebar-shipping-information-edit-icon__top: $indent__s;

// Checkout
$checkout-step-title__padding: $indent__s;
$checkout-step-title__border: $border-width__base solid $c-border;
$checkout-step-title__font-size: 26px;
$checkout-step-title__font-weight: $font-weight__light;
$checkout-sidebar__columns: 8;
$checkout-shipping-address__max-width: 500px;

// Swatch
$swatch__border-color: #bdbdbd;
$swatch__selected-border-color: $midnight;
$swatch-color: $text__color;

//
//  Progress Bar
//  _____________________________________________

$checkout-progress-bar__font-size: $font-size__base;
$checkout-progress-bar__font-weight: $font-weight__regular;
$checkout-progress-bar__margin: $indent__base;

$checkout-progress-bar-item__background-color: $midnight;
$checkout-progress-bar-item__border-radius: 6px;
$checkout-progress-bar-item__color: $checkout-progress-bar-item__background-color;
$checkout-progress-bar-item__margin: $indent__s;
$checkout-progress-bar-item__width: 185px;
$checkout-progress-bar-item__active__background-color: $primary__color;
$checkout-progress-bar-item__complete__color: $checkout-progress-bar-item__background-color;

$checkout-progress-bar-item-element__width: 38px;
$checkout-progress-bar-item-element__height: $checkout-progress-bar-item-element__width;

$checkout-progress-bar-item-element-outer-radius__width: 6px;
$checkout-progress-bar-item-element-inner__background-color: $page__background-color;
$checkout-progress-bar-item-element-inner__color: $checkout-progress-bar-item__color;
$checkout-progress-bar-item-element-inner__width: $checkout-progress-bar-item-element__width - ($checkout-progress-bar-item-element-outer-radius__width * 2);
$checkout-progress-bar-item-element-inner__height: $checkout-progress-bar-item-element-inner__width;
$checkout-progress-bar-item-element-inner__active__content: $icon-checkmark;
$checkout-progress-bar-item-element-inner__active__font-size: 28px;
$checkout-progress-bar-item-element-inner__active__line-height: 1;

//
// Pages
//  ---------------------------------------------

$pages__color: $c-scorpion;
$pages__arrow__color: $midnight;
$pages__active__color: $ice;
$pages__pagination__fontsize: $font-size__s;

//
//  Sidebar
//  ---------------------------------------------

// Used in cart sidebar, Checkout sidebar, Tier Prices, My account navigation, Rating block background
$sidebar__background-color: $color-white;

//
//  Cart
//  --------------------------------------------

$mobile-cart-padding: 15px;
$cart-item-cell-padding-top: 20px;

$gift-wrapping__border-color: $c-border;
$gift-item-block__border-color: $c-border;

//
//  Variables missing in extend.scss
//  ---------------------------------------------

$addto-color: $primary__color;
$addto-hover-color: $link__hover__color;

//
// Magento CMS Widgets
// ---------------------------------------------

$widgets-indent__bottom: 0; // overwrite !default in Magento_Cms/styles/_widgets.scss

//
//  Account pages
//  _____________________________________________

$account-nav-background: $sidebar__background-color;
$account-nav-color: false;

$account-nav-current-border: 3px solid transparent;
$account-nav-current-border-color: $primary__color;
$account-nav-current-color: false;
$account-nav-current-font-weight: $font-weight__semibold;

$account-nav-item-hover: $c-light-grey;

$account-title-border-color: $c-border;
$account-table-border-bottom-color: $c-border;

//
// Company Pages
// ______________________________________________

$company-button__color: $primary__color;
$company-button__color: $primary__color;
$company-button__hover__color: $primary__color__dark;
$tree-leaf-clicked__background-color: $primary__color;

$quote-button__color: $primary__color;
$quote-button__active__color: $link__hover__color;
$quote-title__border-color: $c-medium-grey;


//
// Homepage Overflowing Banner
// ______________________________________________
$overflowing-banner-block-mobile__height: var(--text-block-height, 447px);
$overflowing-banner-block-mobile__margin-bottom: calc(#{$overflowing-banner-block-mobile__height} + 6px);

// Mega Menu
// ______________________________________________

// General
$default-border: 1px solid $color-black;
$lighter-border: 1px solid $c-silver;
$transparent-border: 1px solid transparent;
$mega__bg-color: $color-white;
$mega__text__font-weight: $font-weight__bold;

// Desktop
$mega__dropdown__shadow: rgba(100, 100, 111, .2) 0 15px 29px 0;
$mega__dropdown__bg-color: $color-white;
$mega__menu-item__text-color: inherit;
$mega__menu-item__icon: down;
$mega__menu-item__icon__color: $color-black;
$mega__menu-item__icon__width: $indent__l;
$mega__menu-item__icon__height: $mega__menu-item__icon__width;

// Desktop - First level of menu items
$mega__menu-item__first__bottom-border: $transparent-border;
$mega__menu-item__first__bottom-border__hover: $default-border;
$mega__menu-item__first__padding: $indent__s 0;
$mega__menu-item__first__margin: 0 $indent__l 0 0;
$mega__menu-item__first__has-dropdown__padding: $indent__s $indent__base $indent__s 0;
$mega__dropdown__first__top-border: $lighter-border;
$mega__dropdown__first__bottom-border: $mega__dropdown__first__top-border;
$mega__simple-dropdown__width: 200px;

// Desktop - Second level of menu items (if dropdowns exist within the first dropdown)
$mega__menu-item__second__padding: $indent__s;
$mega__menu-item__second__margin: 0;
$mega__dropdown__second__bg-color__hover: $c-silver;
$mega__menu-item__second__bottom-border: none;
$mega__menu-item__second__bottom-border__hover: none;

// Mobile - General
$mega__mobile-nav-bar__bg-color: transparent;
$mega__menu-item__text-color: inherit;
$mega__close-btn__width: 45px;
$mega__close-btn__color: inherit;
$mega__close-btn__padding: 13px 15px 15px;
$mega__close-btn__size: $indent__xl;
$mega__mobile__width: 340px;
$mega__small-mobile__overlay-gap: $indent__l;
$mega__small-mobile__width: calc(100% - #{$mega__small-mobile__overlay-gap});

// Mobile - Accordion
$mega__accordion__close-btn__display: none; // hide or show close button
$mega--accordion--first--menu-item--height: 20px;
$mega__tab__text-color: $mega__close-btn__color;
$mega__tab__bg-color: $c-silver;
$mega__tab__border-bottom: none;
$mega__tab__active__text-color: $mega__tab__text-color;
$mega__tab__active__bg-color: $color-white;
$mega__tab__active__border-bottom: $mega__tab__border-bottom;
$mega__acc-tab__links__bg-color: transparent;
$mega__accordion__padding: 0;
$mega__accordion__margin: $indent__l 0 0 0;
$mega__accordion__menu-item__bottom-border: $lighter-border;
$mega__accordion__menu-item__text-color: $mega__menu-item__text-color;
$mega__accordion__dropdown__padding: 0;
$mega__accordion__dropdown__margin: $indent__s 0;
$mega__accordion__dropdown__bg-color: $mega__bg-color;
$mega__accordion__icon__expand: expand;
$mega__accordion__icon__collapse: collapse;
$mega__accordion__icon__color: $mega__accordion__menu-item__text-color;
$mega__accordion__icon__width: 16px;
$mega__accordion__icon__height: $mega__accordion__icon__width;
$mega__accordion__acc-link-item__padding: 15px $indent__s;
$mega__accordion__acc-link-item__margin: 0 15px;
$mega__accordion__acc-link__padding: 0;
$mega__accordion__acc-link__margin: 0;

// Mobile - Accordion - First level of menu items
$mega__accordion__first__menu-item__padding: $mega__accordion__acc-link-item__padding;
$mega__accordion__first__menu-item__margin: $mega__accordion__acc-link-item__margin;
$mega__accordion__first__menu-item-link__padding: $mega__accordion__acc-link__padding;
$mega__accordion__first__menu-item-link__margin: $mega__accordion__acc-link__margin;

// Mobile - Accordion - Second level of menu items
$mega__accordion__second__menu-item__padding: 15px 0;
$mega__accordion__second__menu-item__margin: 0 0 0 15px;

// Mobile - Accordion - Third level of menu items
$mega__accordion__thrid__menu-item__padding: 0;
$mega__accordion__thrid__menu-item__margin: 0 0 0 15px;
$mega__accordion__third__menu-item-link__padding: $indent__s;
$mega__accordion__third__menu-item-link__margin: 0;

// Mobile - Drilldown
$mega__drilldown__mobile-nav-bar__padding: 0;
$mega__drilldown__mobile-nav-bar__margin: $indent__xs 0 0;
$mega__drilldown__padding: 0;
$mega__drilldown__margin: $indent__s 0 0 0;
$mega__drilldown__icon__expand: down;
$mega__drilldown__back-btn__icon: down;
$mega__drilldown__back-btn__bg-color: transparent;
$mega__drilldown__back-btn__width: $mega__close-btn__width;
$mega__drilldown__icon__width: $mega__close-btn__size;
$mega__drilldown__icon__height: $mega__drilldown__icon__width;
$mega__drilldown__icon__color: $mega__menu-item__text-color;
$mega__drilldown__close-btn__display: block; // hide or show close button
$mega__drilldown__acc-link-item__padding: $mega__accordion__acc-link-item__padding;
$mega__drilldown__acc-link-item__margin: $mega__accordion__acc-link-item__margin;
$mega__drilldown__acc-link__padding: $mega__accordion__acc-link__padding;
$mega__drilldown__acc-link__margin: $mega__accordion__acc-link__margin;
$mega__drilldown__current-category-label__padding: $indent__xs 0 0;
$mega__drilldown__current-category-label__margin: auto 0;
$mega__drilldown__menu-item__bottom-border: $mega__accordion__menu-item__bottom-border;
$mega__drilldown__menu-item__text-color: $mega__accordion__menu-item__text-color;
$mega__drilldown__dropdown__padding: $mega__accordion__dropdown__padding;
$mega__drilldown__dropdown__margin: $mega__accordion__dropdown__margin;
$mega__drilldown__dropdown__bg-color: $mega__accordion__dropdown__bg-color;

// Mobile - Drilldown - First level of menu items
$mega__drilldown__first__menu-item__padding: $mega__accordion__first__menu-item__padding;
$mega__drilldown__first__menu-item__margin: $mega__accordion__first__menu-item__margin;
$mega__drilldown__first__menu-item-link__padding: $mega__accordion__first__menu-item-link__padding;
$mega__drilldown__first__menu-item-link__margin: $mega__accordion__first__menu-item-link__margin;

// Mobile - Drilldown - Third level of menu items
$mega__drilldown__third__menu-item-link__padding: $mega__accordion__third__menu-item-link__padding;
$mega__drilldown__third__menu-item-link__margin: $mega__accordion__third__menu-item-link__margin;
