.social-links {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    margin-bottom: $indent__base;
    padding-left: 0;

    li {
        list-style: none;
        margin-top: 0;

        &:not(:last-child) {
            margin-right: 15px;
        }
    }

    svg {
        display: block;
        width: 30px;
        height: 30px;

        * {
            fill: currentColor;
        }
    }

    a {
        display: block;
        color: $primary__color;

        @include hover {
            color: $white;
        }
    }
}
