//
//  Category view
//  ---------------------------------------------

.old-price,
.old.price {
    text-decoration: line-through;
}

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: "(" attr(data-label) ": ";
                }

                &:last-child:after {
                    content: ")";
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                font-size: 11px;
            }

            &:before {
                content: " +" attr(data-label) ": ";
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.product.name a {
    @extend .abs-product-link;
}

.category {
    &-image {
        position: relative;
        height: inherit;

        .image {
            display: block;
            height: auto;
            max-width: 100%;
        }
    }

    &-image,
    &-description {
        margin-bottom: $indent__base;
    }
}

.catalog-category-view {
    .sidebar-main {
        order: 0;
    }

    .block-title {
        &.filter-title {
            margin-bottom: 0;
        }
    }

    .subcategories {
        @include custom-scrollbar($_track-margin: 0 15px);
        display: flex;
        margin: 50px 0 $indent__base;
        overflow-x: auto;

        .subcategory {
            margin: 0 $indent__m;
            width: 100px;
            text-align: center;
            flex-shrink: 0;

            &:first-of-type {
                margin-left: 0;
            }

            img {
                display: block;
                width: 100px;
                height: 100px;
                object-fit: cover;
                border-radius: 50px;
                background: $white;
            }

            .product-image {
                @include hover {
                    + .product-item-name a {
                        color: $product-name-link__color__hover;
                    }
                }
            }
        }

        .product-item-name {
            margin: 20px 0;
            font-size: $font-size__s;
            text-transform: uppercase;
        }
    }

    &.page-layout-category-full-width {
        #maincontent {
            &,
            .page-main {
                padding: 0;
                max-width: none;
            }
        }

        .column.main {
            padding: 0;
            width: 100%;
        }
    }

    .category-header {
        position: relative;
        width: 100%;

        .page-title-wrapper,
        .subcategories,
        .category-description {
            @include layout-container();
        }

        .category-description {
            &,
            [data-content-type="row"] * {
                margin-bottom: 0 !important; // override Page Builder
            }
        }

        &-background-container {
            position: absolute;
            top: 0;
            z-index: -1; // position behind category header content

            &,
            [data-content-type="row"] {
                height: 100%;
                width: 100%;
            }
        }
    }
}

.cataloglabel {
    @include lib-heading(h3);

    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: $indent__xs 0;
    width: 100%;
    background: $ice;
    color: $white;
    text-align: center;
    z-index: 1;

    @include min-screen($screen__m) {
        font-size: 10px;
    }

    .product-item & {
        top: -#{$product-item__padding};
        left: -#{$product-item__padding};
        width: calc(100% + #{$product-item__padding} * 2);
    }

    &.position-BL,
    &.position-BC,
    &.position-BR {
        top: unset;
        bottom: 0;
    }
}

//
//  Product images general container
//  ---------------------------------------------

.product-image {
    &-container {
        display: inline-block;
        width: 100%;
        max-width: 100%;
    }

    &-wrapper {
        display: block;
        padding-bottom: 100%;
        height: 0;
        overflow: hidden;
        position: relative;
        z-index: 1;
    }

    &-photo {
        bottom: 0;
        display: block;
        height: auto;
        left: 0;
        margin: auto;
        max-width: 100%;
        object-fit: contain;
        position: absolute;
        right: 0;
        top: 0;
    }
}

//
//  Product view
//  ---------------------------------------------

.catalog-product-view .verify-email-block {
    border: none;
}

.product.media {
    position: relative;

    .cataloglabel {
        max-width: 100%;
    }

    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        color: $text__color__muted;
        font-size: $font-size__s;
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.product.info.detailed {
    clear: both;
    margin-bottom: 30px;

    .additional-attributes {
        width: auto;
        @include lib-table-resize(
            $_th-padding-left: 0,
            $_th-padding-right: $indent__l,
            $_th-padding-bottom: $indent__s,
            $_td-padding-bottom: $indent__s
        );
    }
}

.product-info-main {
    .page-title-wrapper {
        .page-title {
            margin-bottom: $indent__s;
        }
    }

    .stock {
        margin-top: 0;

        &.available,
        &.unavailable {
            display: inline-block;
            font-weight: $font-weight__bold;
            margin-right: $indent__base;
            text-transform: uppercase;
            vertical-align: top;
        }
    }

    .product {
        &.attribute {
            &.sku {
                display: inline-block;
                vertical-align: top;
                color: $midnight;

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.overview {
                margin: $indent__base 0;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .price-box {
        margin-top: $indent__s;
    }

    .swatch-attribute {
        margin-bottom: $indent__base;
    }

    .product-reviews-summary .reviews-actions {
        font-size: $font-size__base;
    }

    .bss_productstockaler {
        display: flex;
        flex-direction: row;
        align-items: top;

        .control,
        .stockalert_email {
            max-width: none;

            @include min-screen($screen__xs) {
                height: 100%;
            }
        }

        .field {
            flex: 1;
        }

        label {
            position: absolute;
        }

        @include max-screen($screen__xs) {
            flex-direction: column;
            align-items: stretch;
        }
    }

    .platform-selector {
        @include platform-selector(
            $_background: $white,
            $_border: none,
        );

        margin-bottom: $indent__base;
    }
}

.bungie-rewards {
    .bungie-reward {
        margin: $indent__base 0;

        &__status {
            display: flex;
            align-items: center;
            margin: 0 0 $indent__s;
            padding: 0;
            background: none;
            color: $midnight;

            @include max-screen($screen__m) {
                font-size: $font-size__s;
            }

            &.complete {
                @include icon-svg(
                    $icon       : checkmark,
                    $width      : 17px,
                    $fill       : $azule,
                    $height     : auto,
                    $pseudo     : before,
                    $hideLabel  : false
                );

                color: $azule;

                &::before {
                    padding: 2px;
                    border: 2px solid $azule;
                    border-radius: 100%;
                    margin-right: $indent__xs;
                }
            }
        }

        &__container {
            display: flex;
            flex-direction: row;
            align-items: top;

            img {
                height: 55px;
                width: 55px;
                background: $azule-gradient;
                border-radius: 100%;
                object-fit: cover;

                @include max-screen($screen__xs) {
                    height: 50px;
                    width: 50px;
                }
            }

            > div {
                margin-left: $indent__s;
            }
        }

        &__title {
            margin-top: 0;
        }
    }

    &__earn-by,
    &__purchase-by {
        margin: $indent__base 0;

        p {
            margin: $indent__xs 0;
        }

        &__title {
            margin: 0;
            padding: 0;
            background: none;
            color: $midnight;

            @include max-screen($screen__m) {
                font-size: $font-size__s;
            }
        }
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            font-weight: $font-weight__bold;
            margin: 0 0 $indent__xs;
            font-size: $font-size__base;
            border: none;
            display: inline-block;
            padding: 0;
        }

        &.required,
        &._required {
            .legend {
                &:after {
                    content: "*";
                    @include lib-typography(
                        $_font-size: $form-field-label-asterisk__font-size,
                        $_color: $form-field-label-asterisk__color,
                        $_font-family: $form-field-label-asterisk__font-family,
                        $_font-weight: $form-field-label-asterisk__font-weight,
                        $_line-height: $form-field-label-asterisk__line-height,
                        $_font-style: $form-field-label-asterisk__font-style
                    );
                    margin: $form-field-label-asterisk__margin;
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-info-main,
.product-options-bottom {
    .price-box {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        .price-including-tax + .price-excluding-tax,
        .weee + .price-excluding-tax,
        .weee {
            font-size: $font-size__s;
            line-height: 14px;
            margin-bottom: $indent__xs;

            .price {
                font-size: $font-size__s;
                font-weight: $font-weight__bold;
            }
        }

        .price-wrapper .price {
            font-size: 18px;
            font-weight: $font-weight__bold;
        }

        .price {
            white-space: nowrap;
        }

        .price-to {
            margin-left: $indent__s;

            &::before {
                content: " - ";
            }
        }

        .price-from {
            .old-price {
                float: left;
                margin-right: $indent__s;
            }
        }
    }

    .special-price {
        display: block;
        order: 2;
        color: $c-red;

        .price-container {
            font-size: $font-size__base;
        }

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .old-price {
        margin-right: $indent__s;
    }

    .old-price,
    .special-price {
        .price-label {
            &:after {
                content: ": ";
            }
        }
    }

    .box-tocart .action.tocart,
    .box-tocart .oauth-login .action,
    .box-tocart .action.how-to-earn,
    .bundle-actions .action.customize {
        @include lib-button-l();
        min-width: $tocart-min-width;
    }

    .box-tocart {
        margin: $indent__base 0;

        .field.qty {
            margin-bottom: $indent__base;
            padding-right: 0.75 * $indent__base;
        }

        .input-text.qty {
            $tocart-input-size: $form-element-input__height;
            height: $tocart-input-size + 2px;
            text-align: center;
            width: $tocart-input-size + 2px;
        }

        .preorder-backorder {
            margin-top: $indent__base;
        }
    }

    .product-addto-links {
        margin: $indent__base 0;
    }

    .action.tocompare {
        display: inline-block;
        margin-left: ($indent__base * 0.75);
        padding: 5px 12px;
        vertical-align: middle;
        text-decoration: none;
        border: $border-width__base solid $c-secondary__color;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    background: $sidebar__background-color;
    margin: $indent__s 0;
    padding: $indent__s (0.75 * $indent__base);
    padding-left: 0; // left align with column data

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;

        .price {
            font-size: $font-size__base;
            font-weight: $font-weight__bold;
        }
    }
}

.ui-dialog-titlebar-close {
    @include button--inline-link();
}

.block.related {
    .action.select {
        margin: 0 $indent__xs;
    }
}

.popup-modal-options {
    .disclaimer {
        margin: $indent__base 0;
    }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            margin-bottom: $indent__base;
            position: relative;

            &-info {
                position: relative;
                width: auto;

                .product-item-photo {
                    left: 0;
                    position: absolute;
                    top: 0;
                }
            }

            &-name {
                margin-top: 0;
            }

            &-details {
                margin: 0 0 0 85px;
            }

            &-actions {
                display: block;
                margin-top: $indent__s;
            }
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            color: $primary__color__lighter;
            font-size: $font-size__s;
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        display: none;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @include lib-clearfix();
        margin-left: -$indent__xs;

        .product-item {
            box-sizing: border-box;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .catalog-product-view {
        .column.main {
            display: flex;
            flex-direction: column;
        }

        .product.media {
            order: -1;
        }

        .product-hero .product-info-main {
            width: 100%;
        }
    }

    .block.related {
        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    [class*="block-compare"] {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-info-main,
    .product-options-bottom {
        .box-tocart {
            display: table;
        }
    }

    .sidebar {
        .product-items {
            .product-item {
                &-info {
                    .product-item-photo {
                        float: left;
                        left: auto;
                        margin: 0 $indent__s $indent__s 0;
                        position: relative;
                        top: auto;
                    }
                }

                &-details {
                    margin: 0;
                }

                &-actions {
                    clear: left;
                }
            }
        }
    }
}

.product__zoom-button {
    position: relative;
    margin-bottom: 5px;
    padding-left: 25px;
    color: $c-dark-grey;
    width: 100%; // force flexed elements to wrap
    box-sizing: border-box;

    svg {
        @include vertically-center($position: absolute);
        left: 0;
        width: 15px;
        height: 15px;

        * {
            fill: currentColor;
        }
    }

    span {
        opacity: 0;
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item {
                &-info {
                    .product-item-photo {
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin: 0;
                    }
                }

                &-details {
                    margin-left: 85px;
                }
            }
        }
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
    .product-info-main {
        float: right;
    }

    .product.media {
        float: left;
        margin-bottom: $indent__m;
    }

    .page-layout-1column {
        .product-info-main {
            width: 40%;
        }
        .product.media {
            width: 57%;
        }
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .product-info-main {
            width: 48%;
        }

        .product.media {
            width: 50%;
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @include lib-visually-hidden();
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style
            $table__border-color;
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style
                $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            font-size: 13px;
            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    background: $page__background-color;
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }

        &.compare {
            @include lib-button-revert-secondary-color();
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}

.aw-afptc__promo-products {
    .product-list {
        padding: $indent__base;
        list-style: none;
    }

    .action {
        margin: $indent__base 0;
    }
}
