//
//  Variables
//  ---------------------------------------------

$product-name-link__color                   : $text__color !default;
$product-name-link__color__active           : $text__color !default;
$product-name-link__color__hover            : $text__color !default;
$product-name-link__color__visited          : $text__color !default;

$product-name-link__text-decoration         : none !default;
$product-name-link__text-decoration__active : $link__hover__text-decoration !default;
$product-name-link__text-decoration__hover  : $link__hover__text-decoration !default;
$product-name-link__text-decoration__visited: $link__hover__text-decoration !default;

//
//  Product Lists
//  _____________________________________________
.products {
    margin: $indent__l 0;
}

.product {
    &-items {
        @extend .abs-reset-list;
        display: block;

        .products-grid &:not(.slick-slider) {
            display: grid;
            grid-gap: $indent__m;
            grid-template-columns: repeat(4, minmax(100px, 1fr));
            margin: 0 $indent__s;

            @include max-screen($screen__l) {
                grid-template-columns: repeat(3, minmax(100px, 1fr));
            }

            @include max-screen($screen__m) {
                grid-gap: $indent__s;
                grid-template-columns: repeat(2, minmax(100px, 1fr));
            }
        }
    }

    &-item {
        box-sizing: border-box;
        vertical-align: top;
        padding: $product-item__padding;
        background: $white;
        transition: box-shadow 100ms ease-in-out;

        .products-grid & {
            display: inline-block;
            width: 100%;
        }

        @include hover {
            box-shadow: $midnight-shadow;

            .product-item-name a {
                color: $product-name-link__color__hover;
            }
        }

        &-name {
            @extend .abs-product-link;
            letter-spacing: .5px;
            line-height: 1.25;
            display: block;
            margin: $indent__xs 0;
        }

        &-info {
            display: flex;
            flex-direction: column;
            max-width: 100%;
            height: 100%;
        }

        &-actions {
            .actions-secondary {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                & > .action {
                    @extend .abs-actions-addto-gridlist;
                    &:before {
                        margin: 0;
                    }

                    span {
                        @include lib-visually-hidden();
                    }
                }
            }
        }

        &-description {
            margin: $indent__m 0;

            .more {
                display: none;
            }
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0;

                @include min-screen($screen__m) {
                    margin-right: 4px;
                }
            }

            .reviews-actions {
                margin-top: 0;
                line-height: $line-height__base;
                text-transform: lowercase;
                font-size: $font-size__base;
            }
        }

        .price-box {
            margin: 0;

            .price {
                font-size: 15px;
                font-weight: $font-weight__regular;
                white-space: nowrap;
            }

            .price-label {
                font-size: $font-size__s;

                &:after {
                    content: ':';
                }
            }
        }

        .special-price,
        .minimal-price {
            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            margin-left: 5px;
            color: $crimson;
        }

        .old-price {
            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: $font-size__base;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            display: inline-block;
            margin: 0;
        }

        .price-from {
            .old-price {
                float: left;
                margin-right: $indent__xs;
            }
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
        }
    }
}

.price-container {
    .price {
        font-size: $font-size__base;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            width: 100%;

            &-info {
                display: flex;
            }

            &-photo {
                display: block;
                padding: 0 $indent__l $indent__l 0;
                vertical-align: top;
                width: 240px;
            }

            &-details {
                display: block;
                vertical-align: top;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

.product-item .product-item-details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: left;

    .notify_me_bss {
        margin-top: auto;
    }
}

// When product image is hovered, pretend like the product title is hovered
.product-item-photo:hover + div .product-item-link {
    text-decoration: $product-name-link__text-decoration__hover;
    color: $product-name-link__color__hover;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }

    .products-crosssell .tocart {
        margin-bottom: 15px;
    }
}

@include min-screen($screen__s) {
    .product {
        &-item {
            &-actions {
                display: block;

                .products-grid &,
                .actions-primary + .actions-secondary {
                    margin: $indent__s 0;
                }

                .actions-primary {
                    display: table-cell;

                    + .actions-secondary {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}
