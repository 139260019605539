.opc-wrapper {
    .form-discount {
        max-width: 500px;
        .field {
            .label {
                @include lib-visually-hidden();
            }
        }
    }
}
