// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________

.pagebuilder-column {
    .widget-product-grid {
        .product-item {
            max-width: 100%;
            min-width: 50px;
            .price-box {
                word-wrap: break-word;
                .price {
                    white-space: normal;
                }
            }
            .product-item-actions {
                .actions-primary {
                    display: inline-block;
                    max-width: 100%;
                }
                .actions-secondary {
                    display: inline-block;
                    width: auto;
                }
                .tocart {
                    max-width: 100%;
                    white-space: normal;
                }
            }
        }
    }
    [data-content-type='products'] {
        .block.widget {
            .products-grid {
                .widget-product-grid.product-items {
                    .product-item {
                        width: 200px;
                    }
                }
            }
        }

        &[data-is-slider="true"] .widget-product-grid {
            &:not(.slick-initialized) {
                display: none;
            }
        }
    }
}

[data-content-type='products'] {
    &[data-appearance="carousel"] .product-items {
        &:not(.slick-initialized) {
            :root & {
                display: none;
            }
        }

        :root & {
            display: block;
        }
    }

    &[data-images-off-page="true"] {
        overflow: hidden;
        width: 100vw;
        position: relative;
        left: 49%;
        right: 49%;
        margin-left: -50vw !important;  //override pagebuilder
        margin-right: -50vw !important;  //override pagebuilder

        &[data-appearance="carousel"] .product-items {
            max-width: $layout__max-width;
            margin: 0 auto;

            .slick-list {
                overflow: visible;
            }
        }
    }
}
