.product.data.items {
    @include bryant-park-lib-data-accordion();

    > .item.title > .switch > span {
        @include lib-visually-hidden-reset();
    }

    .data.item {
        display: block;
        position: relative;

        .counter {
            &::before {
                content: '(';
            }

            &::after {
                content: ')';
            }
        }
    }
}
