.four-column-category-content {
    [data-content-type="column-group"] {
        @include max-screen($screen__l) {
            flex-wrap: wrap;

            [data-content-type="column"] {
                flex-basis: 50%;
                width: 50%;

                @include max-screen($screen__s) {
                    height: 250px;
                    min-height: unset !important; // override Page Builder
                }
            }
        }
    }

    [data-content-type="column"] {
        position: relative;
        justify-content: flex-end !important; // override Page Builder
        align-items: flex-start;
        padding: 20px !important; // override Page Builder
        width: 100%;
        height: 400px;
        color: $white;

        [data-content-type="image"] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
                height: 100% !important; // override Page Builder
                width: 100%;
                object-fit: cover;
            }
        }

        &::before {
            position: absolute;
            content: "";
            background: linear-gradient(to top, #13171c, transparent);
            background-position: 0 150px;
            background-repeat: no-repeat;
            transition: background-position 100ms;
            height: 100%;
            width: 100%;
            left: 0;
            bottom: 0;
            pointer-events: none;
            z-index: 1; // display behind text

            @include max-screen($screen__s) {
                background-position: 0 50px;
            }
        }

        [data-content-type="heading"],
        [data-content-type="buttons"] {
            z-index: 2; // display above background gradient
        }

        [data-content-type="heading"] {
            @include gradient-heading();
            margin: 20px 0;
        }

        .pagebuilder-button-link {
            margin: 0;
            padding: 0;
            color: $white;
        }

        @include hover {
            &::before {
                background-position: 0 50px;

                @include max-screen($screen__s) {
                    background-position: 0;
                }
            }

            [data-content-type="heading"] {
                @include gradient-heading($active: true);
            }

            .pagebuilder-button-link {
                color: $ice;
            }
        }
    }
}
