//
//  Common
//  _____________________________________________

.gift-message {
    .field {
        .label {
            color: $gift-message-field-label__color;
            font-weight: $font-weight__regular;
        }
    }
}

.gift-options {
    @include lib-clearfix();
    .actions-toolbar {
        .action-cancel {
            @include button--inline-link();
            display: none;
        }
    }
}

.gift-options-title {
    margin: 0 0 $indent__base;
}

.gift-options-content {
    .fieldset {
        margin: 0 0 $indent__base;
    }
}
.gift-summary {
    .actions-toolbar {
        & > .secondary {
            float: none;

            .action {
                margin: $indent__s $indent__base 0 0;
            }
        }
    }
}

//
//  In-table block
//  ---------------------------------------------

.cart.table-wrapper {
    .gift-content {
        clear: left;
        display: none;
        float: left;
        margin: $indent__base 0;
        text-align: left;
        width: 100%;

        &._active {
            display: table;
            table-layout: fixed;
        }
    }

    .action-gift {
        @include icon-svg(
            $icon       : expand,
            $fill       : $secondary__color,
            $width      : 14px,
            $height     : auto,
            $pseudo     : after,
            $hideLabel  : false
        );
        margin-right: $indent__xs;


        &:last-child {
            margin-right: 0;
        }

        &._active {
            @include icon-svg(
                $icon       : collapse,
                $fill       : $secondary__color,
                $width      : 14px,
                $height     : auto,
                $pseudo     : after,
                $hideLabel  : false
            );
        }
    }
}

//
//  Collapsible block
//  ---------------------------------------------

.gift-item-block {
    margin: 0;
    padding: $indent__base $layout__width-xs-indent;

    .title {
        @include icon-svg(
            $icon       : expand,
            $fill       : $secondary__color,
            $width      : 14px,
            $height     : auto,
            $pseudo     : after,
            $hideLabel  : false
        );

        span {
            @include lib-link();
        }
    }

    &._active {
        .title {
            @include icon-svg(
                $icon       : collapse,
                $fill       : $secondary__color,
                $width      : 14px,
                $height     : auto,
                $pseudo     : after,
                $hideLabel  : false
            );
        }
    }

    .content {
        @include lib-clearfix();
        border-bottom: $gift-item-block__border-width solid $gift-item-block__border-color;
        padding: $indent__s $indent__s $indent__base;
        position: relative;
    }
}

//
//  Account order gift message
//  ---------------------------------------------

.order-details-items {
    .order-gift-message,
    .block-order-details-gift-message {
        .item-options {
            dt {
                font-weight: $font-weight__regular;
                margin: $indent__s 0;
            }
        }

        @include lib-clearfix();

        dt {
            &:after {
                content: '';
            }
        }

        .label {
            @extend .abs-colon;
        }

        .item-message {
            clear: left;
        }
    }
}

.order-details-items .order-items {
    .order-gift-message {
        &:not(.expanded-content) {
            @include lib-visually-hidden();
        }

        .action.close {
            display: none;
        }
    }

    .action.show {
        @include lib-icon-font(
            $_icon-font-content  : $icon-down,
            $_icon-font-size     : 22px,
            $_icon-font-text-hide: false,
            $_icon-font-position : after,
            $_icon-font-display  : inline-block
        );
        padding-right: $indent__base;
        position: relative;

        &:after {
            position: absolute;
            right: 0;
            top: -4px;
        }

        &.expanded {
            @include lib-icon-font-symbol(
                $_icon-font-content : $icon-up,
                $_icon-font-position: after
            );
        }
    }
}

.block-order-details-gift-message {
    border-top: $border-width__base solid $border-color__base;
    padding-top: $indent__s;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .cart.table-wrapper {
        .gift-content {
            margin-right: -$indent__s;
        }
    }
}

@include max-screen($screen__s) {
    .cart.table-wrapper {
        .gift-content {
            border-bottom: $border-width__base solid $border-color__base;
            margin-bottom: $indent__base;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .gift-message {
        .field {
            @include lib-clearfix();
            @include lib-form-field-type-revert(
                $_type                   : inline,
                $_type-inline-label-align: left
            );
        }
    }

    .gift-options {
        position: relative;
        z-index: 1;

        .actions-toolbar {
            clear: both;
            float: right;
            position: static;

            .secondary {
                float: right;

                .action {
                    float: right;
                    margin-left: $indent__base;
                }

                .action-cancel {
                    display: block;
                    float: left;
                    margin-top: 6px;
                }
            }

            &:nth-child(3) {
                &:before {
                    border-left: 1px solid $gift-item-block__border-color;
                    bottom: 5rem;
                    content: '';
                    display: block;
                    left: 50%;
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    width: 0;
                }
            }
        }
    }

    .gift-options-title {
        font-weight: $font-weight__light;
        font-size: 18px;
    }

    .gift-summary,
    .cart.table-wrapper .gift-summary {
        .actions-toolbar {
            @extend .abs-reset-left-margin-desktop;
        }
    }

    .cart-container {
        .cart-gift-item {
            @extend .abs-shopping-cart-items-desktop;
        }
    }

    //
    //  In-table block
    //  ---------------------------------------------

    .cart.table-wrapper {
        .action-gift {
            float: left;
        }
    }
}
