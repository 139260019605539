//
//  Variables
//  _____________________________________________

$account-nav-background: $sidebar__background-color;
$account-nav-color: false;

$account-nav-current-border: 3px solid transparent;
$account-nav-current-border-color: $color-orange-red1;
$account-nav-current-color: false;
$account-nav-current-font-weight: $font-weight__semibold;

$account-nav-item-hover: $color-gray91;

$_password-default: $color-gray-light01 !default;
$_password-weak: #ffafae !default;
$_password-medium: #ffd6b3 !default;
$_password-strong: #c5eeac !default;
$_password-very-strong: #81b562 !default;

//login page specific variables
$redesign_input-border-color: #B4B4B4;
$redesign_input-error-color: #FB1432;

//login page specific mixins
@mixin redesign-button_primary {
    font-family: $font-family__base;
    border-radius: 32px;
    background: $ice;
    color: $midnight;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .5px;
    transition: background .3s, color .3s;
    line-height: 20px;
    width: auto;
    min-width: 150px;

    &:hover {
        background: $smoke;
    }
}

@mixin redesign-input {
    background: none;
    height: 40px;
    border: 1px solid $redesign_input-border-color;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 8px;
    color: $cloud;
    font-family: $font-family__base;
}

@mixin bordered-box {
    border-radius: 8px;
    border: 1px solid $swatch__border-color;
    background: $charcoal;
    color: $white;
}

@mixin redesign-button_link {
    color: $white;
    font-family: $font-family__base;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    padding: 0;
    text-decoration: none;
    border-bottom: 1px solid $white;
}

@mixin redesign-error-msg {
    border-radius: 8px;
    background: rgba(251, 20, 50, 0.10);
}

@mixin redesign-error-success {
    border-radius: 8px;
    background: rgba($c-green, 0.10);
}

@mixin redesign-error-warning {
    border-radius: 8px;
    background: rgba($c-brown, 0.10);
}

//
//  Common
//  _____________________________________________

.login-block {
    max-width: 614px;
    padding: 24px;
    width: 100%;
    margin: 32px auto;
    box-sizing: border-box;
    text-align: center;

    @include min-screen($screen__m) {
        padding: 40px;
        margin: 20px auto;
    }
}

.customer-account-login,
.customer-account-create,
.customer-account-forgotpassword,
.customer-account-createpassword {
    .column-wrapper {
        flex-basis: 100%;
        flex-grow: 1;
    }

    .message.error {
        @include redesign-error-msg;
    }

    .page.messages .message{
        margin: 40px;
    }

    .message.success {
        @include redesign-error-success;
    }

    .message.warning {
        @include redesign-error-warning;
    }

    .page-main .action.primary,
    .page-main a.action.primary,
    .action.reload.captcha-reload {
        @include redesign-button_primary;
    }

    .actions-toolbar {
        @include min-screen($screen__m) {
            text-align: center;
        }
    }

    .actions-toolbar > .primary,
    .actions-toolbar > .secondary {
        margin-bottom: 0;
        float: none;

        button {
            margin-bottom: 10px;
        }
    }

    .fieldset .field.newsletter {
        @include min-screen($screen__m) {
            padding: 0 24px;
        }
    }

    .fieldset > .field {
        text-align: left;

        @include min-screen($screen__m) {
            padding: 0 23px;
            margin-bottom: 22px;

            &:last-child {
                margin-bottom: 22px;
            }

            .control {
                max-width: none;
            }

            input {
                width: 100%;
                max-width: none;
            }
        }

        @include min-screen($screen__s) {
            .control {
                &:last-child {
                    max-width: none;
                }
            }
        }
    }

    .page-main a:not(.primary),
    .page-main a.link {
        @include redesign-button_link;
    }

    .page-main [data-content-type='buttons'] {
        display: flex;
        gap: 16px;
        margin-top: 12px;
        justify-content: center;

        .button {
            font-size: 12px;
        }

        @include max-screen($screen__m) {
            flex-direction: column;
            gap: 12px;
        }
    }

    .page-main .pagebuilder-button-link {
        padding: 0;
        font-size: 12px;
        letter-spacing: 0;
        display: inline-block;

        &:visited {
            color: $white;
        }

        &:hover {
            color: $white;
            text-decoration: none;
            border-bottom: 1px solid $white;
        }
    }

    .form input[type="datetime"],
    .form input[type="email"],
    .form input[type="number"],
    .form input[type="password"],
    .form input[type="search"],
    .form input[type="tel"],
    .form input[type="text"],
    .form input[type="url"],
    .form select, .form textarea {
        @include redesign-input;
    }

    [type="checkbox"]:not(.custom-input--disabled) + label::before,
    [type="radio"]:not(.custom-input--disabled) + label::before {
        border-radius: 8px;
        background: transparent;
        border-color: #B4B4B4;
        width: 24px;
        height: 24px;
    }

    [type="checkbox"]:not(.custom-input--disabled) + label:hover::after,
    [type="checkbox"]:not(.custom-input--disabled) + label:focus::after,
    [type="radio"]:not(.custom-input--disabled) + label:hover::after,
    [type="radio"]:not(.custom-input--disabled) + label:focus::after {
        border: none;
    }

    [type="checkbox"]:not(.custom-input--disabled):checked + label::after,
    [type="radio"]:not(.custom-input--disabled):checked + label::after {
        width: 24px;
        height: 24px;
    }

    [type="checkbox"]:not(.custom-input--disabled):checked + label::before,
    [type="radio"]:not(.custom-input--disabled):checked + label::before {
        background-color: #009ade;
        border-color: #009ade;
    }

    .field.choice {
        display: flex;

        .label {
            margin: 0 !important; //to rewrite general important styles
            padding-left: 37px !important; //to rewrite general important styles
            min-height: 26px;
        }

        .field-tooltip {
            top: -1px;
        }
    }

    .field-tooltip .field-tooltip-content {
        border: 1px solid $swatch__border-color;
        background: $charcoal;

        &::after {
            border-bottom-color: $charcoal;
        }

        &::before {
            border-bottom-color: $swatch__border-color;
        }
    }

    .password-weak .password-strength-meter:before,
    .password-medium .password-strength-meter:before,
    .password-strong .password-strength-meter:before,
    .password-very-strong .password-strength-meter:before,
    .password-none .password-strength-meter:before {
        content: none;
    }

    .password-strength-meter {
        background-color: transparent;
        padding: 0;
        margin: 7px 0;
        color: $redesign_input-error-color;
    }

    .password-weak .password-strength-meter,
    .password-medium .password-strength-meter,
    .password-none .password-strength-meter {
        background-color: transparent;
        color: $redesign_input-error-color;
    }

    .password-strong .password-strength-meter,
    .password-very-strong .password-strength-meter {
        background-color: transparent;
        color: #81b562;
    }

    .actions-toolbar .primary .action {
        margin-left: 15px;
        margin-right: 15px;
    }

    .password-toggle {
        top: 12px;

        &__text {
            color: $ice;
            font-family: $font-family__base;
            font-size: 14px;
            font-weight: 700;
            line-height: 14px;
            text-transform: uppercase;

            &:hover,
            &:focus {
                color: $ice;
            }
        }
    }

    .create.account {
        .password-input {
            margin-bottom: 0;
        }

        .confirmation label {
            margin-top: 20px;
        }
    }

    .mage-error {
        color: $redesign_input-error-color;
        font-family: $font-family__base;
        font-size: 14px;
        font-weight: 400;
        margin: 12px 0;
        letter-spacing: 0.6px;

        @include min-screen($screen__m) {
            margin: 6px 0;
        }
    }

    form label,
    form .label,
    .fieldset > .field > .label,
    .fieldset > .fields > .field > .label {
        margin: 0 0 10px;
        font-family: $font-family__base;
        font-size: 16px;
    }

    .page-wrapper {
        background-color: $midnight;
        color: $white;
        overflow: hidden;
    }

    .login-container {
        @extend .login-block;
        @include bordered-box;
    }

    .page-title-wrapper {
        margin-bottom: 24px;
    }

    .page-title {
        font-family: $font-family__base;
        text-align: center;
        font-size: 22px;
        font-weight: 400;
        margin: 0;
    }

    .login-container {
        margin-top: 40px;

        @include min-screen($screen__m) {
            margin-top: 80px;
        }

        .block-logo {
            text-align: center;
            margin: 24px 0;
            display: flex;
            gap: 16px;
            justify-content: center;
            align-items: center;

            .icon-playstation {
                @include icon-svg(
                    $icon: playstation,
                    $width: 40px,
                    $height: 30px,
                    $fill: $swatch__border-color,
                    $pseudo: before
                );
            }
            .icon-xbox {
                @include icon-svg(
                    $icon: xbox,
                    $width: 31px,
                    $height: 26px,
                    $fill: $swatch__border-color,
                    $pseudo: before
                );
            }
            .icon-twitch {
                @include icon-svg(
                    $icon: twitch_redesign,
                    $width: 46px,
                    $height: 41px,
                    $fill: $swatch__border-color,
                    $pseudo: before
                );
            }
            .icon-epicgames {
                @include icon-svg(
                    $icon: epicgames,
                    $width: 27px,
                    $height: 26px,
                    $fill: $swatch__border-color,
                    $pseudo: before
                );
            }
        }

        .login-separator {
            margin: 21px 0;

            @include min-screen($screen__m) {
                margin: 24px 0;
            }
        }

        #forgot-password-link {
            margin-top: 8px;

            a {
                color: $white;
                font-family: $font-family__base;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.5px;
                border-bottom: 1px solid $white;
                text-transform: none;
            }
        }
    }

    fieldset::before {
        content: none;
    }

    .actions-toolbar {
        text-align: center;
    }

    .block-create-account,
    .login-support,
    .block-new-customer .oauth-login {
        @extend .login-block;
    }

    .block-create-account {
        padding: 0;
    }

    .login-support {
        padding: 0;
        margin-top: 80px;
    }

    .block-new-customer {
        .oauth-login {
            @include max-screen($screen__m) {
                @include bordered-box;
            }

            .action.primary {
                background-color: $white;

                &:hover {
                    background-color: $ice;
                }
            }
        }

        .login-separator {
            @include min-screen($screen__m) {
                display: none;
            }
        }
    }

    .oauth-login {
        h4 {
            color: $white;
            text-align: center;
            font-family: $font-family__base;
            font-size: 22px;
            font-weight: 400;
            line-height: 1.5;
            margin: 0;
        }

        p {
            color: $white;
            text-align: center;
            font-family: $font-family__base;
            font-size: 16px;
            font-weight: 400;
            margin: 17px 0 25px;
        }
    }

    .form-create-account {
        @extend .login-block;

        p {
            margin: 17px 0 10px;
        }

        .oauth-login {
            margin-top: 56px;

            @include min-screen($screen__m) {
                margin-top: 64px;
            }
        }

        .page-title {
            line-height: 1.4;
        }
    }
}

.customer-account-forgotpassword,
.customer-account-createpassword {
    form.password.forget,
    .form.password.reset{
        @extend .login-block;
    }

    .page-title-wrapper {
        text-align: center;
        margin-bottom: 0;
    }
}

.customer-account-create {
    .login-support {
        margin-top: 50px;

        @include min-screen($screen__m) {
            margin-top: 28px;
        }
    }
}

.login-separator {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::before,
    &::after {
        content: '';
        display: block;
        height: 1px;
        background: $swatch__border-color;
        margin: 16px 0;
        flex-basis: calc(50% - 16px);
    }
}

.warning-text {
    font-weight: $font-weight__bold;
    color: $error__color;
}

.legend span {
    @extend .h3;
    margin: 0;
}

.forget {
    .fieldset:before {
        margin-bottom: 10px;

        @include min-screen($screen__s) {
            max-width: $form-field__width__desktop;
        }
    }
}

.block-addresses-default,
.block-addresses-list {
    .block-title strong {
        @extend .h3;
        margin: 0;
    }
}

.block-addresses-list {
    .items.addresses {
        & > .item {
            margin-bottom: $indent__base;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .item.actions {
            margin-top: $indent__xs;

            .action {
                @extend .abs-account-actions;
            }
        }
    }
}

.form-address-edit {
    .region select {
        display: none;
    }

    .actions-toolbar .action.primary {
        @include lib-button-l();
    }
}

.form-edit-account {
    .fieldset.password {
        display: none;
        clear: both;
        float: none;

        @include min-screen($screen__l) {
            clear: none;
            width: 47%;
        }
    }
}

.box-billing-address,
.box-shipping-address,
.box-information,
.box-newsletter {
    .box-content {
        line-height: 26px;
    }
}

.box-address-shipping {
    @include max-screen($screen__m) {
        margin-top: 20px;
    }
}

//
//  My account
//  ---------------------------------------------

.account {
    .column.main {
        @include min-screen($screen__m) {
            width: 66.66666667%;
        }

        @include min-screen($screen__l) {
            width: 76.66666667%;
        }

        h2 {
            margin-top: 0;
        }

        .toolbar {
            text-align: center;
            .limiter-options {
                width: auto;
            }
        }

        .order-title {
            display: flex;
            flex-wrap: wrap;

            h3 {
                @include min-screen($screen__l) {
                    margin-right: $indent__s;
                }
            }

            a {
                margin-right: $indent__s;

                @include min-screen($screen__l) {
                    margin-top: $indent__base;
                }
            }
        }

        .order-options-links {
            margin-bottom: $indent__m;

            a {
                display: block;
                margin-bottom: $indent__xs;

                @include min-screen($screen__xs) {
                    display: inline-block;
                    margin-right: $indent__l;
                    margin-bottom: 0;
                }
            }
        }

        .limiter {
            > .label {
                @include lib-visually-hidden();
            }
        }

        .block:not(.widget) {
            @extend %abs-account-blocks;
        }
    }

    .sidebar {
        @include min-screen($screen__m) {
            width: 33.3333333%;
        }

        @include min-screen($screen__l) {
            width: 23.3333333%;
        }

        &-additional {
            margin-top: 40px;
        }
    }

    .block-title + .block-content > .table-wrapper {
        margin-top: -25px;
    }

    .table-wrapper {
        margin-bottom: $indent__base;

        &:last-child {
            margin-bottom: 0;
        }

        .action:last-child {
            margin-right: 0;
        }
    }

    .table-return-items {
        .qty {
            .input-text {
                @extend .abs-input-qty;
            }
        }
    }

    .legend {
        @extend %abs-account-title;
        width: 100%;
    }

    .actions-toolbar {
        clear: left;
    }
}

.link-account {
    @include platform-selector(
        $_background: $white,
        $_border: none,
    );
}

//  Checkout address (create shipping address)
.field.street {
    .field._required {
        .label {
            @include lib-visually-hidden();
        }
    }
}

/**
 * We're hiding the password confirmation field
 * from registration, and using JS to automatically
 * fill it with what's entered in the primary password
 * field
 */
.create.account .confirmation {
    display: none;
}

//
//  Account navigation
//  ---------------------------------------------

.account-nav {
    .title {
        @include lib-visually-hidden();
    }

    .content {
        background: $account-nav-background;
        padding: 15px 0;
    }

    .item {
        margin: 3px 0 0;

        &:first-child {
            margin-top: 0;
        }

        a,
        & > strong {
            color: $account-nav-color;
            border-left: 3px solid transparent;
            display: block;
            padding: $indent__xs 18px $indent__xs 15px;
        }

        a {
            text-decoration: none;

            @include hover {
                background: $account-nav-item-hover;
            }
        }

        &.current {
            a,
            strong {
                border-color: $account-nav-current-border-color;
                color: $account-nav-current-color;
                font-weight: $account-nav-current-font-weight;
            }

            a {
                border-color: $account-nav-current-border-color;
            }
        }
    }
}


//
//  Table Actions
//  ---------------------------------------------

.account,
[class^='sales-guest-'],
.sales-guest-view {
    .data.table {
        .col.actions {
            .action {
                @extend .abs-account-actions;
            }
        }
    }
}

//
//  Blocks & Widgets
//  ---------------------------------------------

.page-main .block {
    @extend .abs-margin-for-blocks-and-widgets;

    .column.main & {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .title {
        margin-bottom: $indent__s;
    }

    p:last-child {
        margin: 0;
    }

    .box-actions {
        margin-top: $indent__xs;

        .action {
            @extend .abs-account-actions;
        }
    }

    .box {
        &-newsletter,
        &-shipping-address {
            @include max-screen($screen__m) {
                margin-top: 20px;
            }
        }
    }
}

//
//  Password Strength Meter
//  ---------------------------------------------

.field.password {
    .control {
        display: flex;
        flex-direction: column;

        .mage-error {
            order: 2;
        }

        .input-text {
            order: 0;
        }

        &:last-child {
            @include min-screen($screen__s) {
                max-width: $form-field__width__desktop;
            }
        }
    }
}

.password-strength-meter {
    position: relative;
    padding: $form-element-input__padding;
    min-height: $form-element-input__height;
    line-height: $form-element-input__height;
    z-index: 1;
    background-color: $_password-default;
    font-size: $font-size__base;

    &:before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    span {
        display: inline-block;
    }

    .password-none & {
        &:before {
            background-color: $_password-default;
            width: 100%;
        }
    }

    .password-weak & {
        &:before {
            background-color: $_password-weak;
            width: 25%;
        }
    }

    .password-medium & {
        &:before {
            background-color: $_password-medium;
            width: 50%;
        }
    }

    .password-strong & {
        &:before {
            background-color: $_password-strong;
            width: 75%;
        }
    }

    .password-very-strong & {
        &:before {
            background-color: $_password-very-strong;
            width: 100%;
        }
    }
}

.control.captcha-image {
    margin-top: $indent__s;

    .captcha-img {
        vertical-align: middle;
    }
}

.block-reviews-dashboard .item .rating-summary {
    margin-bottom: $indent__s;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .account {
        .column.main,
        .sidebar-additional {
            margin: 0;
        }
    }
}

@include max-screen($screen__m) {
    .login-container {
        .fieldset {
            &:after {
                text-align: center;
            }
        }
    }

    .account {
        .page.messages .message {
            margin-bottom: $indent__s; // same amount as .block .title, which resides above the messages display
        }

        .toolbar {
            @extend .abs-pager-toolbar-mobile;
        }
    }

    .control.captcha-image {
        .captcha-img {
            margin-bottom: $indent__s;
            display: block;
        }
    }

    .customer-account-index {
        .page-title-wrapper {
            position: relative;
        }
    }
}

@include max-screen($screen__l) {
    .form-edit-account {
        .info,
        .password {
            width: 100%;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    //.login-container {
    //    @include lib-clearfix();
    //
    //    .block {
    //        @include abs-blocks-2columns();
    //        &.login {
    //            .actions-toolbar {
    //                & > .primary {
    //                    margin-bottom: 0;
    //                    margin-right: $indent__l;
    //                }
    //
    //                & > .secondary {
    //                    float: left;
    //                }
    //            }
    //        }
    //    }
    //
    //    .fieldset {
    //        &:after {
    //            @extend .abs-margin-for-forms-desktop;
    //        }
    //
    //        & > .field {
    //            & > .control {
    //                width: 55%;
    //            }
    //        }
    //    }
    //}

    .form-create-account {
        .fieldset-fullname {
            .fields {
                .field {
                    float: left;
                    margin: 0 $indent__s $indent__s 0;
                }
            }

            .field-name-prefix,
            .field-name-suffix {
                width: 50px;
            }
        }
    }

    .form.password.reset,
    .form.send.confirmation,
    .form.password.forget,
    .form.create.account {
        min-width: 600px;
        width: 50%;
    }

    //
    //  My account
    //  ---------------------------------------------

    .account {
        .column.main {
            .block:not(.widget) {
                .block-content {
                    @include lib-clearfix();

                    .box {
                        @include abs-blocks-2columns();
                    }
                }
            }
        }

        .data.table {
            margin-bottom: 0;

            .col {
                &.actions {
                    white-space: nowrap;
                }
            }
        }

        .toolbar {
            @extend .abs-pager-toolbar;
        }
    }

    .form-edit-account {
        .fieldset {
            @include abs-blocks-2columns();

            &:nth-child(even) {
                float: none;
            }
        }
    }

    .block-addresses-list {
        .items.addresses {
            @include lib-clearfix();

            & > .item {
                @include abs-blocks-2columns();
                margin-bottom: $indent__base;

                &:nth-last-child(1),
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }
            }
        }
    }

    //
    //  Welcome block
    //  ---------------------------------------------

    .dashboard-welcome-toggler {
        @extend .abs-visually-hidden-desktop;
    }

    .control.captcha-image {
        .captcha-img {
            margin: 0 $indent__s $indent__s 0;
        }
    }
}

.link-player-account {
    color: inherit;
    text-decoration: underline;
    font-weight: normal;
    cursor: pointer;
    display: inline-block; /* Ensures underline only extends to text */
    white-space: nowrap;

    &:hover {
        text-decoration: none;
    }
}

.password-toggle__text {
    margin-left: 70px;
}

.review-form .action.submit.primary {
    background: linear-gradient(45deg, #007abd, #005989) no-repeat border-box,
    linear-gradient(45deg, #12171d, #142941) no-repeat border-box !important;
    color: #fff !important;
}
