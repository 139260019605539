$cart-image-mobile-width: 60px !default;
$cart-summary-width-m: 40% !default;
$cart-summary-width-l: 28% !default;

//
//  Shopping cart
//  ---------------------------------------------

/**
 * We're stripping out the wrapper's padding at mobile so
 * that certain elements can expand full-bleed
 */
.checkout-cart-index .page-main {
    @include layout-gutters(
        $s-gutters: 0,
        $m-gutters: 0
    );
}

.checkout-cart-index .page-title-wrapper,
.cart-empty {
    @include layout-gutters($l-gutters: 0);
}

// Cart empty page
.cart-empty a {
    @include lib-button-primary();
}

//
//  Sidebar
//  ---------------------------------------------

.cart-summary {
    @extend .abs-adjustment-incl-excl-tax;
    box-sizing: border-box;
    background: $sidebar__background-color;
    margin-bottom: $indent__m;
    padding: 1px 0 $indent__m;

    @include max-screen($screen__m) {
        // override stickybits from theme.js
        position: relative !important;
        top: 0 !important;
    }

    @include min-screen($screen__m) {
        float: right;
        position: relative;
        right: 0;
        padding: 1px 15px $indent__m;
        width: $cart-summary-width-m;
    }

    @include min-screen($screen__l) {
        width: $cart-summary-width-l;
    }
}


.cart.actions {
    @include min-screen($screen__xs) {
        display: flex;
        justify-content: space-between;
    }

    .action.update {
        @include lib-button-primary-white();
    }
}

.summary.title {
    @include lib-heading(h2);
    display: none;

    @include min-screen($screen__m) {
        display: block;
        font-size: 16px;
    }
}

.cart-summary .block {
    margin-bottom: 0;
    margin: 0;

    > .title {
        @extend %abs-toggling-title;

        @include max-screen($screen__m) {
            padding-left: ($mobile-cart-padding + $indent__l);
            padding-right: $mobile-cart-padding;
        }

        &::after {
            left: 15px;

            @include min-screen($screen__m) {
                left: auto;
                right: $indent__s;
            }
        }
    }

    &:last-of-type > .title {
        border-bottom: $border-width__base solid $border-color__base;
    }

    .content {
        @include layout-gutters(
            $s-gutters: $mobile-cart-padding,
            $m-gutters: $mobile-cart-padding,
            $l-gutters: 0
        );
        display: none;
        padding-top: $indent__xs;
        padding-bottom: 25px;
        position: relative;
    }

    &.active {
        > .title {
            @include icon-svg(
            $icon       : collapse,
            $fill       : $azule,
            $width      : $toggling-icon-width,
            $height     : auto,
            $pseudo     : after
            );
        }

        .content {
            display: block;
        }
    }

    .item-options {
        margin-left: 0;
    }

    .fieldset {
        .field {
            margin-bottom: $indent__s;

            @include min-screen($screen__m) {
                @include lib-form-field-type-revert($_type: block);
                margin: 0 0 $indent__m;
            }
        }

        .item-options .field {
            margin-bottom: 0;
        }

        .methods .field > .label {
            display: inline;
        }
    }

    .fieldset.estimate {
        & > .legend,
        & > .legend + br {
            display: none;
        }
    }
}

// Used for "Apply Discount" and "Add Gift Card"
.cart-summary .action.add,
.cart-summary .action.apply {
    @include lib-button();
}

// Totals block
.cart-totals {
    @extend .abs-sidebar-totals;
    @include layout-gutters(
        $s-gutters: $mobile-cart-padding,
        $m-gutters: $mobile-cart-padding,
        $l-gutters: 0
    );
    padding-top: $indent__s;
    padding-bottom: $indent__s;

    @include min-screen($screen__m) {
        border-top: 1px solid $border-color__base;
    }

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;
    }

    tbody > tr > th,
    tbody > tr > td,
    .mark {
        padding-top: $indent__xs;
        padding-bottom: $indent__xs;
        text-align: left;
    }
}

.grand.totals {
    @include lib-heading(h4);
}

//
//  Cart products grid
//  ---------------------------------------------

.cart.table-wrapper {
    $_cart-product-image-margin: $cart-image-mobile-width + 15px;

    @include max-screen($screen__m) {
        overflow: inherit;
    }

    .items {
        thead + .item {
            border-top: $border-width__base solid $border-color__base;
        }

        > .item {
            border-bottom: $border-width__base solid $border-color__base;
            position: relative;
        }
    }

    .col {
        padding: $indent__m $indent__s $indent__s;
        vertical-align: top;

        &.item {
            @include max-screen($screen__m) {
                display: block;
                min-height: $_cart-product-image-margin;
                padding: $indent__m 0 $indent__s $_cart-product-image-margin;
                position: relative;
            }
        }

        &.qty {
            .input-text {
                @extend .abs-input-qty;
                margin-top: -5px;
            }

            .label {
                @include lib-visually-hidden();
            }
        }
    }

    .product-image-container {
        @include min-screen($screen__m) {
            max-width: $_cart-product-image-margin;
        }

        @include min-screen($screen__l) {
            max-width: 100%;
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .product-item-photo {
        display: block;
        left: 0;
        max-width: $cart-image-mobile-width;
        padding: 0;
        position: absolute;
        top: 15px;
        width: 100%;
    }

    .product-item-details {
        white-space: normal;
    }

    .product-item-name {
        margin-top: 0;
        line-height: 1;
    }

    .item-actions td {
        padding-bottom: $indent__s;
        text-align: center;
        white-space: normal;
    }

    .actions-toolbar {
        @include lib-clearfix();

        > .action {
            @include button--inline-link();
            margin-bottom: $indent__s;
            margin-right: $indent__s;

            &:last-child {
                margin-right: 0;
            }
        }

        .action-gift {
            @include button--inline-link();
            line-height: inherit;
            margin-right: $indent__s;
        }
    }

    .action.help.map {
        @include button--inline-link();
        font-weight: $font-weight__regular;
    }

    .price-including-tax,
    .price-excluding-tax {
        @include max-screen($screen__l) {
            font-size: $font-size__base + 2;
        }
    }

    .gift-registry-name-label::after {
        content: ':';
    }

    //  Product options
    .item-options {
        @extend .abs-product-options-list;
        @include lib-clearfix();
        font-size: $font-size__s;
        margin-bottom: $indent__s;
    }

    .product-item-name + .item-options {
        margin-top: $indent__s;
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent__base;
    }
}

.cart-container {
    @include min-screen($screen__m) {
        @include lib-clearfix();
    }

    .form-cart {
        @extend .abs-shopping-cart-items;
        @include layout-gutters(
            $s-gutters: $mobile-cart-padding,
            $m-gutters: $mobile-cart-padding,
            $l-gutters: 0
        );

        @include min-screen($screen__m) {
            @extend .abs-shopping-cart-items-desktop;
        }
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        margin-top: $indent__base;
        padding: 0 $mobile-cart-padding;
        text-align: center;

        @include min-screen($screen__m) {
            padding: 0;
        }

        .action.primary {
            @include lib-button-l();
            width: 100%;
        }

        .item + .item {
            margin-top: $indent__base;
        }
    }

    .widget {
        @include min-screen($screen__m) {
            float: left;
        }
    }

    .gift-message .gift-options-content .field {
        label {
            float: none;
        }

        .control {
            width: 100%;
        }
    }
}

.cart-tax-info,
.cart .cart-tax-info {
    & + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.order-items.table-wrapper {
    .col.price,
    .col.qty,
    .col.subtotal,
    .col.msrp {
        text-align: right;
    }
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
    @include layout-gutters(
        $s-gutters: $mobile-cart-padding,
        $m-gutters: $mobile-cart-padding,
        $l-gutters: 0
    );
    margin-top: 70px;

    .slick-slide {
        margin: 0 $indent__xs;
    }

    .slick-prev,
    .slick-next {
        $circle-diameter: 35px;

        height: $circle-diameter;
        width: $circle-diameter;

        &::before,
        &::after {
            width: $circle-diameter;
            font-size: 16px;
        }
    }

    .slick-prev {
        left: 0;
    }

    .slick-list {
        margin: 0 50px;
        text-align: center;

        .product-item {
            margin: 0 $indent__s;
            width: 90% !important; // override slick slider
        }

        .product-item-actions {
            margin: 10px 0 0;

            .actions-primary {
                display: block;

                .action {
                    margin-bottom: 0;
                    width: 100%;
                    padding-left: $indent__xs;
                    padding-right: $indent__xs;
                }
            }
        }
    }

    .slick-next {
        right: 0;
    }

    .product-image-photo {
        width: 100%;
    }

    .actions-secondary {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .cart.table-wrapper {
        thead .col {
            &.item,
            &.qty,
            &.price,
            &.subtotal,
            &.msrp {
                display: none;
            }
        }

        .col {
            &.qty,
            &.price,
            &.subtotal,
            &.msrp {
                box-sizing: border-box;
                display: block;
                float: left;
                text-align: center;
                white-space: nowrap;
                width: 33%;

                &::before {
                    content: attr(data-th);
                    display: block;
                    font-weight: $font-weight__regular;
                    padding-bottom: $indent__s;
                }
            }

            &.msrp {
                white-space: normal;
            }
        }
    }

    .cart-totals {
        .table-wrapper {
            border-top: 0;
        }

        .totals tbody > tr:not(:last-child) > td:last-child {
            border: 0;
        }
    }

    .cart-container .form-cart {
        .actions {
            @include max-screen($screen__xs) {
                text-align: center;
            }
        }

        .action.update,
        .action.continue,
        .action.clear {
            @include max-screen($screen__xs) {
                display: block !important;
                margin-bottom: 10px !important;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .gift-options-cart,
    .cart-discount,
    .crosssell {
        @extend .abs-shopping-cart-items-desktop;
    }

    .actions-toolbar .column.main .cart-summary {
        @include min-screen($screen__m) {
            margin-left: 0;
        }

        > .secondary {
            float: none;
        }
    }

    .cart.table-wrapper {
        .items { // Google Chrome version 44.0.2403.107 m fix
            min-width: 100%;
            width: auto;
        }

        .item-actions td {
            text-align: right;
        }

        .product-item-photo {
            display: table-cell;
            max-width: 100%;
            padding-right: $indent__base;
            position: static;
            vertical-align: top;
            width: 1%;
        }

        .product-item-details {
            display: table-cell;
            vertical-align: top;
            white-space: normal;
            width: 99%;
        }

        .item-actions .actions-toolbar {
            text-align: left;
            margin-left: 0;
        }
    }

    //  Cross sell
    .block.crosssell {
        @include lib-layout-column(2, 1, 100% - $cart-summary-width-m);
        padding: 0 4% 0 0;
        box-sizing: border-box;

        .products-grid .product-item {
            width: percentage(1/3);
            box-sizing: border-box;
        }

        .actions-secondary {
            text-align: left;
        }
    }

    // Cart mobile
    .checkout-cart-mobile {
        display: none;
    }
}


@include min-screen($screen__l) {
    .cart-summary .block.discount,
    .cart-summary .block.giftcard {
        .fieldset {
            @include inlinefieldset();
        }
    }
}

// Sale Price
.price--sale > .price {
    text-decoration: line-through;
}

.block.crosssell {
    @include min-screen($screen__l) {
        @include lib-layout-column(2, 1, 100% - $cart-summary-width-l);
    }
}
