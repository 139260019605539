.block-category-link,
.block-product-link {
    &.widget {
        display: block;
        margin-bottom: $indent__base;
    }
}
.block-product-link-inline {
    &.widget {
        margin: 0;
    }
}
.block.widget {
    .product-item-info {
        position: relative;
        width: auto;
    }

    .product-item-inner {
        display: none;
    }

    .pager {
        padding: 0;
        .toolbar-amount {
            float: none;
            font-size: $font-size__s;
        }
        .pages-item-previous {
            padding-left: 0;
        }
        .pages-item-next {
            position: relative;
        }
        .items {
            white-space: nowrap;
        }
    }
}

//
//    Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .sidebar .block.widget .pager {
        .item:not(.pages-item-next):not(.pages-item-previous) {
            display: none;
        }

        .pages-item-next {
            padding: 0;

            .action {
                margin: 0;
            }
        }
    }
}
