@media screen and (max-width: $screen__xs__between) {
    .mega-menu.drilldown .menu-item > .menu-level {
        width: $mega__small-mobile__width;
    }
}

@media (min-width: $screen__xs__between) and (max-width: $mobile-nav__breakpoint) {
    .mega-menu.drilldown .menu-item > .menu-level {
        width: $mega__mobile__width;
    }
}

@include max-screen($mobile-nav__breakpoint) {
    .mega-menu.drilldown {
        padding: $mega__drilldown__padding;
        margin: $mega__drilldown__margin;

        .menu-level {
            .menu-item {
                position: relative;
                padding: $mega__drilldown__first__menu-item__padding;
                margin: $mega__drilldown__first__menu-item__margin;
                border-bottom: $mega__drilldown__menu-item__bottom-border;
            }
        }

        .menu-item {
            .menu-item__link {
                position: unset;
                display: inline-block;
                color: $mega__drilldown__menu-item__text-color;
                padding: $mega__drilldown__first__menu-item-link__padding;
                margin: $mega__drilldown__first__menu-item-link__margin;

                &::before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    position: absolute;
                    z-index: 1;
                }
            }

            // first level if have dropdown, add icon + general styles
            &.has-dropdown > .menu-item__link {
                @include icon-svg(
                    $icon       : $mega__drilldown__icon__expand,
                    $fill       : $mega__drilldown__icon__color,
                    $hideLabel  : true,
                    $pseudo     : after,
                    $width      : $mega__drilldown__icon__width,
                    $height     : $mega__drilldown__icon__height
                );
                
                &::after {
                    transform: rotate(-90deg);
                    position: absolute;
                    top: 7px;
                    right: 0;
                }
            }

            &.active {
                > .menu-level {
                    display: block;
                    opacity: 1;
                    visibility: visible;
                    box-shadow: none;
                    padding: $mega__drilldown__dropdown__padding;
                    margin: $mega__drilldown__dropdown__margin;
                    height: 100vh;
                    z-index: $z-index-1;
                }

                &.has-dropdown > .menu-item__link::after {
                    display: none;
                }

                .menu-item .menu-level.active-3 {
                    display: block;
                    opacity: 1;
                    visibility: visible;
                    box-shadow: none;
                    padding: $mega__drilldown__dropdown__padding;
                    margin: $mega__drilldown__dropdown__margin;
                    height: 100vh;
                    z-index: 1;

                    > [data-content-type="row"] {
                        padding: $indent__base;
                    }
                }
            }

            .menu-item .menu-item__link {
                max-width: none;
            }

            > .menu-level {
                background: $mega__drilldown__dropdown__bg-color;
                opacity: 0;
                visibility: hidden;
                position: fixed;
                top: 51px;
                left: 0;
                overflow: scroll;
            }
        }

        .menu-item__link {
            cursor: pointer;
        }

        .pagebuilder-column [data-content-type="text"] {
            justify-content: flex-start;
            flex-direction: column;
        }

        .header.links {
            padding: 0;

            li {
                padding: $mega__drilldown__acc-link-item__padding;
                margin: $mega__drilldown__acc-link-item__margin;

                a {
                    padding: $mega__drilldown__acc-link__padding;
                    margin: $mega__drilldown__acc-link__margin;
                }
            }
        }
    }

    .mobile-nav-bar:not(.two-tab) {
        padding: $mega__drilldown__mobile-nav-bar__padding;
        margin: $mega__drilldown__mobile-nav-bar__margin;
        justify-content: space-between;

        .back-btn {
            width: $mega__drilldown__back-btn__width;
            background-color: $mega__drilldown__back-btn__bg-color;
            position: relative;

            @include icon-svg(
                $icon       : $mega__drilldown__back-btn__icon,
                $fill       : $mega__drilldown__icon__color,
                $hideLabel  : true,
                $pseudo     : after,
                $width      : $mega__drilldown__icon__width,
                $height     : $mega__drilldown__icon__height
            );
            
            &::after {
                transform: rotate(90deg);
                position: absolute;
                top: 4px;
                left: 0;
            }

            &.hide {
                display: none;
            }
        }

        .current-category-label {
            padding: $mega__drilldown__current-category-label__padding;
            margin: $mega__drilldown__current-category-label__margin;
        }

        .close-btn {
            display: $mega__drilldown__close-btn__display;
            margin-left: unset;
        }
    }

    .pagebuilder-column-line {
        flex-wrap: wrap;
    }
}
