@include min-screen($mobile-nav__breakpoint) {
    header.page-header {
        height: 100%;
        padding-bottom: 0;
    }

    .header.content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .logo {
            order: 1;
        }

        .block-search {
            order: 2;
        }

        .minicart-wrapper {
            order: 3;
        }

        nav {
            flex-basis: 100%;
            order: 4;
            margin-top: $indent__s;
        }
    }

    .breadcrumbs {
        margin-top: $indent__base;
    }

    // This section makes the megamenu dropdowns (with block content inside) on desktop full-width
    // Sets the absolute megamenu dropdowns with full-width blocks to only be relative to .page-header element (which takes up full-width of screen)
    .page-header {
        position: relative;

        .header.content {
            position: unset;
        }
    }

    nav {
        position: unset;

        [data-content-type="row"],
        .row-full-width-inner,
        .mega-menu,
        .mega-menu > .menu-level,
        .mega-menu > .menu-level > .menu-item {
            position: unset;
        }
    }

    // if simple dropdowns are present in the megamenu
    nav .mega-menu > .menu-level > .menu-item.simple-dropdown {
        position: relative;
    }
}
