/*doc
---
title: Buttons
name: Buttons
category: Style-Guide - Buttons-Links
---

```html_example
  <button type="submit">Buy Me</button>
  <p> AKA secondary button </p>
```

```html_example
  <button class="action primary" type="submit">Buy Me</button>
```

```html_example
  <a  href="#"">Buy Me</a>
```

```html_example
  <a class="abs-action-link-button" href="#">Buy Me</a>
```
*/

 // Secondary Button (main button styling)
 .file-upload {
     @include lib-button();
     @include headingFont;
     appearance: none;
     letter-spacing: 0.96px;
 }

 // Primary Button (mostly CTA)
 .action.primary {
     @include lib-button-primary();
     letter-spacing: 0.96px;
 }

 // Treat these anchor links as buttons
 a.action.primary {
     @include lib-link-as-button();
     @include headingFont;
 }

 .action.primary,
 a.action.primary {
     width: 100%;

     @include min-screen($screen__xs) {
         width: auto;
     }
 }

 .pagebuilder-wrapper .pagebuilder-collage-content,
 .pagebuilder-button-secondary {
     button.pagebuilder-banner-button,
     button.pagebuilder-button-secondary,
     [data-element="link_text"] {
         font-size: $button__font-size !important;
         text-transform: $button__text-transform !important;
     }
 }

 .button--transparent {
     @include button--transparent;
 }

 .button--inline-link {
     @include button--inline-link;
 }
