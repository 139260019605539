$back-to-top__z-index: 2;

.back-to-top:not([data-block-name="back-to-top"]) {
    position: fixed;
    left: 0;
    bottom: 0;
    display: block;
    margin-bottom: calc(env(safe-area-inset-bottom) + 60px);
    padding: 10px;
    background: linear-gradient(45deg, #4399D8 0%, #458BBE 100%);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: $back-to-top__z-index;

    @include hover {
        svg {
            fill: $white;
        }
    }

    svg {
        display: block;
        width: 35px;
        height: 35px;
        fill: $midnight;
        transition: fill 200ms;
    }
}

@media print {
    .back-to-top {
        display: none !important;
    }
}

.back-to-top-element {
    opacity: 1 !important;
}

.fadeIn {
    opacity: 0 !important;
}
