.block-reward-info {
    .reward-rates,
    .reward-limit,
    .reward-expiring {
        &:not(:last-child) {
            margin-bottom: $indent__m;
        }

        .title {
            display: inline-block;
            margin-bottom: $indent__s;
        }
    }
}
