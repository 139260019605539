$related-products__item__margin-bottom: $indent__base !default;
$related-products__price-box__margin-bottom: $indent__s !default;
$related-products__img-width: 150px !default;
$related-products__img-width-mobile: 140px !default;

.block.related {
    @include min-screen($screen__l) {
        margin-left: $indent__base;
    }

    .action.select {
        @include button--inline-link();
    }

    .block-title {
        > * {
            @include max-screen($screen__m) {
                margin-bottom: $indent__s;
            }

            @include min-screen($screen__l) {
                margin-top: 0;
            }
        }
    }

    .block-actions {
        padding-bottom: 15px;

        @include min-screen($screen__l) {
            border-bottom: $border-width__base solid $c-border;
        }
    }
}

:root .products-related > .products {
    margin-left: 0;
}

.products-related {
    margin-top: 0;

    .products {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;
        flex-direction: row;

        .product-item {
            margin-bottom: $related-products__item__margin-bottom;

            @include min-screen($screen__m) {
                margin-bottom: 0;
            }

            .product-image-photo {
                width: 100%;
            }

            .product-item-info {
                width: 100%;
            }

            .product-item-actions,
            .product_addtocart_form {
                display: none;
            }

            .product-item-actions {
                margin: 0;
                margin-top: $indent__xs;
                margin-left: -7px; // remove left whitespace for wishlist

                .secondary-addto-links {
                    margin-top: $indent__base;
                }
            }

            .price-box {
                margin: 0;
            }
        }
    }
}
