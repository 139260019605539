.pages {
    margin-bottom: $indent__m;

    @include lib-pager(
        $_pager-icon-font-margin: 0,
        $_pager-font-size: $pages__pagination__fontsize,
        $_pager-font-weight: $font-weight__regular,
        $_pager-line-height: 1.5,
        $_pager-color-hover: $pages__active__color,

        $_pager-action-background: none,
        $_pager-action-border: none,
        $_pager-action-border-hover: none,
        $_pager-action-color: $midnight,
        $_pager-action-color-visited: $midnight,

        $_pager-icon-previous-content: $icon-arrow-left-thick,
        $_pager-icon-next-content: $icon-arrow-right-thick,
        $_pager-icon-font-size: 30px,
        $_pager-icon-font-vertical-align: middle,

        $_pager-current-color: $midnight,
        $_pager-current-font-weight: $font-weight__regular,
    );

    .item {
        padding: 0;
        margin: 0 $indent__xs;

        &.pages-item-previous {
            padding-right: $indent__s;
        }

        &.pages-item-next {
            padding-left: $indent__s;
        }

        &.current {
            border-bottom: 2px solid $pages__active__color;
        }
    }
}


.pages .action {
    &.previous,
    &.next {
        font-family: $font-family__secondary;
        font-weight: $font-weight__bold;
        letter-spacing: $letter-spacing__large;
        text-transform: uppercase;

        &:hover,
        &:focus {
            color: $azule;

            &::before,
            &::after {
                // Arrow icon
                color: $ice;
            }
        }

        @include max-screen($screen__m) {
            padding: $indent__s $indent__xs;
        }

        @include min-screen($screen__m) {
            > span {
                // these nested <span>s are automatically excluded as screen-reader content
                // by theme-blank-sass, but it's where "Next"/"Previous" reside
                @include lib-visually-hidden-reset();
            }

            .label {
                display: none;
            }
        }
    }
}
