@media screen and (max-width: $screen__xs__between) {
  nav {
    width: $mega__small-mobile__width;
  }
}

@media (min-width: $screen__xs__between) and (max-width: $mobile-nav__breakpoint) {
  nav {
    width: $mega__mobile__width;
  }
}

@include max-screen($mobile-nav__breakpoint) {
  nav {
    all: revert;

    .nav-open & {
      transform: none;
      overflow: scroll;
    }
  }

  .mobile-nav-bar {
    background-color: $mega__mobile-nav-bar__bg-color;

    .nav-open & {
      display: flex;
    }

    &.two-tab {
      justify-content: flex-start;
      height: 60px;

      &.account + .mega-menu {
        .header.links {
          width: 100%;
          padding: 0;
          margin: 0;

          .greet.welcome {
            display: none;
          }

          li {
            padding: $mega__accordion__first__menu-item__padding;
            margin: $mega__accordion__first__menu-item__margin;
            border-bottom: $mega__accordion__menu-item__bottom-border;

            a {
              color: $mega__accordion__menu-item__text-color;
              display: block;
              padding: $mega__accordion__first__menu-item-link__padding;
              margin: $mega__accordion__first__menu-item-link__margin;
              border-bottom: 0;
              font-weight: $font-weight__bold;
            }
          }
        }

        .header.links,
        .header.links .authorization-link {
          display: block;
        }

        .menu-level {
          display: none;
        }
      }

      + .mega-menu > .header.links {
        display: none;
        height: 100%;
        background-color: $mega__acc-tab__links__bg-color;
      }
    }

    .mobile-menu-btn,
    .mobile-account-btn {
      width: 100%;
    }

    .close-btn {
      color: $mega__close-btn__color;
      font-size: $mega__close-btn__size;
      width: $mega__close-btn__width;
      top: 0;
      margin-left: auto;
      background-color: transparent;
      border-bottom: 0;
      padding: $mega__close-btn__padding;
    }

    button {
      border-radius: 0;
      background-color: $mega__tab__bg-color;
      border: 0;
      color: $mega__tab__text-color;
      border-bottom: $mega__tab__border-bottom;

      &.active, &:hover, &:focus {
        background-color: $mega__tab__active__bg-color;
        color: $mega__tab__active__text-color;
        border-bottom: $mega__tab__active__border-bottom;
      }
    }
  }

  .mega-menu {
    .nav-open & {
      left: 0;
    }

    &__top {
      padding: $indent__s;

      &::after {
        display: block;
        content: "";
        clear: both;
      }
    }

    .menu-level {
      padding: 0;
      margin: 0;
    }
  }
}
