.jarallax {
    position: relative;
    z-index: 0;
}

.jarallax > .jarallax-img {
    // Support for plugin https://github.com/bfred-it/object-fit-images
    font-family: 'object-fit: cover;', sans-serif;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
