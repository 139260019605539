// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________

[data-content-type='button-item'] {
	max-width: 100%;
	[data-element='link'],
	[data-element='empty_link'] {
			max-width: 100%;
			word-wrap: break-word;
	}
	[data-element='empty_link'] {
			cursor: default;
	}
}

a,
button,
div {
	&.pagebuilder-button-link {
			@include lib-button-as-link(
					$_padding: $button__padding__l
			);
			margin: 0;
			font-family: $button__font-family;
			font-size: $font-size__s;
			font-weight: $font-weight__bold;
			text-transform: $button-tertiary__text-transform;
			letter-spacing: $button-tertiary__letter-spacing;
			box-shadow: none;
			box-sizing: border-box;
			display: inline-block;
	}

	&.pagebuilder-button-primary {
			@include lib-link-as-button();
			@include lib-button-primary($_button-margin: 0);
			border-radius: $button__border-radius;
			box-shadow: none;
	}

	&.pagebuilder-button-secondary {
			@include lib-link-as-button();
			@include lib-button($_button-margin: 0);
			border-radius: $button__border-radius;
			box-shadow: none;
	}

	&.pagebuilder-button-outlined {
		&:not([data-content-type="button-item"]),
		&[data-content-type="button-item"] a {
			@include lib-button-outlined();
		}
}

	&.pagebuilder-button-white {
		&:not([data-content-type="button-item"]),
		&[data-content-type="button-item"] a {
			@include lib-button-primary-white();
		}
	}
}
