/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

//
//  Common
//  _____________________________________________

.payment-method {
    &._active {
        .available-credit {
            display: block;
        }
    }
}

.form-purchase-order {
    margin-bottom: $indent__s;
}

.credit-balance-list {
    list-style: none;
    padding-left: 0;
    display: flex;

    .credit-balance-item {
        margin-right: 10rem;

        &:last-child {
            margin-right: 0;
        }
    }

    .credit-balance-label {
        font-size: 1.6rem;
        margin-bottom: 0;
    }

    .credit-balance-price {
        font-size: 2.4rem;
        font-weight: 600;

        &._negative {
            color: $error__color;
        }
    }
}

.available-credit {
    float: right;
    display: none;

    strong {
        &+ span {
            padding: 0 $indent__s;
        }
    }
}

.change-result {
    clear: both;
    padding-top: $indent__base;

    strong {
        padding-left: $indent__s/2;
    }
}

//
//  Desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    .form-purchase-order {
        margin-bottom: 0;
        width: 40%;
    }

    .checkout-agreements-block {
        clear: both;
    }
}

//
//  Mobile
//  ---------------------------------------------

@include max-screen($screen__s) {
    .credit-balance-list {
        flex-direction: column;

        .credit-balance-item {
            margin-right: 0;
        }
    }

    .data-grid-cell-content {
        display: inline-block;
    }

    .company-credit-history-index {
        .quotes-grid-toolbar {
            .toolbar-amount {
                display: none;
            }
        }
    }
}
