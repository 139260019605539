.mega-menu {
    .menu-item__link {
        font-weight: $mega__text__font-weight;
        border-bottom: 0;
    }

    a {
        text-decoration: none;
    }

    p {
        margin: 0;
        padding: 0;
    }

    .pagebuilder-column {
        [data-content-type="text"] {
            display: flex;

            p {
                display: inline-block;
            }
        }
    }
}
