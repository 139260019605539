.two-column-editorial {
    [data-content-type="image"] img {
        max-height: 600px;
    }

    h2[data-content-type="heading"] {
        margin-bottom: 0;
    }

    h4[data-content-type="heading"] {
        margin: 25px 0;
        max-width: 350px;
    }

    [data-content-type="button-item"] a {
        width: 200px;
    }

    // Desktop
    @include min-screen($screen__m) {
        [data-content-type="column-group"] [data-content-type="column"]:last-child {
            padding-left: 30px !important; // override Page Builder
        }
    }

    // Mobile
    @include max-screen($screen__m) {
        padding: 50px $indent__s !important; // override Page Builder

        [data-content-type="image"] {
            text-align: center !important; // override Page Builder
        }

        h4[data-content-type="heading"] {
            margin: 15px 0;
        }

        [data-content-type="button-item"],
        [data-content-type="button-item"] a {
            width: 100%;
        }
    }
}
