@include max-screen($mobile-nav__breakpoint) {
    .mega-menu.accordion {
        padding: $mega__accordion__padding;
        margin: $mega__accordion__margin;

        .menu-level {
            .menu-item {
                position: relative;
                padding: $mega__accordion__first__menu-item__padding;
                margin: $mega__accordion__first__menu-item__margin;
                border-bottom: $mega__accordion__menu-item__bottom-border;
            }
        }

        .menu-item {
            .menu-item__link {
                display: inline-block;
                color: $mega__accordion__menu-item__text-color;
                padding: $mega__accordion__first__menu-item-link__padding;
                margin: $mega__accordion__first__menu-item-link__margin;

                &::before {
                    content: "";
                    width: 100%;
                    height: $mega__accordion__first__menu-item__height;
                    left: 0;
                    top: 0;
                    position: absolute;
                    z-index: 1;
                }
            }

            .menu-level.open-accordion {
                display: block;
                position: static;
                opacity: 1;
                visibility: visible;
                box-shadow: none;
                padding: $mega__accordion__dropdown__padding;
                margin: $mega__accordion__dropdown__margin;
            }

            // first level if have dropdown, add icon + general styles
            &.has-dropdown > .menu-item__link {
                @include icon-svg(
                    $icon       : $mega__accordion__icon__expand,
                    $fill       : $mega__accordion__icon__color,
                    $hideLabel  : true,
                    $pseudo     : after,
                    $width      : $mega__accordion__icon__width,
                    $height     : $mega__accordion__icon__height
                );
                
                &::after {
                    position: absolute;
                    top: 18px;
                    right: 0;
                    padding-right: $indent__s;
                }
            }

            &.open-menu-item {
                > .menu-item__link {
                    @include icon-svg(
                        $icon       : $mega__accordion__icon__collapse,
                        $fill       : $mega__accordion__icon__color,
                        $hideLabel  : true,
                        $pseudo     : after,
                        $width      : $mega__accordion__icon__width,
                        $height     : $mega__accordion__icon__height
                    );
    
                    &::after {
                        top: $indent__m;
                    }
                }

                > .menu-level {
                    display: block;
                    position: static;
                    opacity: 1;
                    visibility: visible;
                    box-shadow: none;
                }
            }

            .menu-item .menu-item__link {
                max-width: none;
            }

            > .menu-level {
                background: $mega__accordion__dropdown__bg-color;
                opacity: 0;
                visibility: hidden;
                position: absolute;
                top: 85px;
                left: 0;
            }

            // if simple dropdowns are present in the megamenu
            &.simple-dropdown {
                > .menu-level {
                    .menu-item, // second level
                    .menu-level .menu-item { // third level
                        border-bottom: none;
                    }
    
                    .menu-item { // second level
                        padding: $mega__accordion__second__menu-item__padding;
                        margin: $mega__accordion__second__menu-item__margin;

                        &.open-menu-item {
                            padding-bottom: 0;
                        }
    
                        .menu-item__link::after {
                            padding-right: 0;
                        }
                    }
    
                    .menu-level .menu-item { // third level
                        padding: $mega__accordion__thrid__menu-item__padding;
                        margin: $mega__accordion__thrid__menu-item__margin;

                        &.open-menu-item {
                            padding-bottom: 0;
                        }

                        .menu-item__link { // third level category title
                            padding: $mega__accordion__third__menu-item-link__padding;
                            margin: $mega__accordion__third__menu-item-link__margin;
                        }
                    }
                }

                &.open-menu-item {
                    padding-bottom: $indent__xs;
                }
            }
        }

        .menu-item__link {
            cursor: pointer;
        }

        .pagebuilder-column [data-content-type="text"] {
            justify-content: flex-start;
        }
    }

    .mobile-nav-bar.two-tab {
        justify-content: flex-start;
        height: 60px;

        &.account + .mega-menu {
            .header.links {
                width: 100%;
                padding: 0;
                margin: 0;

                .greet.welcome {
                    display: none;
                }

                li {
                    padding: $mega__accordion__acc-link-item__padding;
                    margin: $mega__accordion__acc-link-item__margin;
                    border-bottom: $mega__accordion__menu-item__bottom-border;

                    a {
                        color: $mega__accordion__menu-item__text-color;
                        display: block;
                        padding: $mega__accordion__acc-link__padding;
                        margin: $mega__accordion__acc-link__margin;
                        border-bottom: none;
                        font-weight: $font-weight__bold;
                    }
                }
            }

            .header.links, .header.links .authorization-link {
                display: block;
            }

            .menu-level {
                display: none;
            }
        }

        + .mega-menu > .header.links {
            display: none;
            height: 100%;
            background-color: $mega__acc-tab__links__bg-color;
        }

        .mobile-menu-btn, .mobile-account-btn {
            width: 100%;
        }

        .back-btn.hide {
            display: none;
        }

        .close-btn {
            display: $mega__accordion__close-btn__display;
        }
    }
}
