//
//  Checkout Estimated Total
//  _____________________________________________

.opc-estimated-wrapper {
    @include hide-for-desktop();
    @include lib-clearfix();
    border-bottom: $border-width__base solid $color-gray80;
    margin: 0 0 15px;
    padding: $indent__base 0;

    .estimated-block {
        font-size: $checkout-estimated-block-mobile__font-size;
        font-weight: $font-weight__regular;
        float: left;

        @include min-screen($screen__s) {
            font-size: $checkout-step-title-mobile__font-size;
            font-weight: $font-weight__bold;
        }

        .estimated-label {
            display: block;
            margin: 0 0 $indent__xs;
        }
    }

    .minicart-wrapper {
        .action {
            &.showcart {
                &:before {
                    color: $primary__color;
                }
            }
        }

        button {
            @include max-screen($screen__s) {
                font-size: $checkout-estimated-block-mobile__font-size;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-estimated-wrapper {
        display: none;
    }
}
