/**
 * Styling for a standard promotional bar, typically added as a widget
 */

 $promobar__background-color: $azule-gradient !default;
 $promobar__color: $color-white !default;
 $promobar__padding-top: 8px !default;
 $promobar__padding-bottom: 7px !default;

 .promobar {
     padding-top: $promobar__padding-top;
     padding-bottom: $promobar__padding-bottom;
     color: $promobar__color;
     background: $promobar__background-color;
     font-size: $font-size__base;

     @include max-screen($screen__m) {
         padding-right: $layout-indent__width;
         font-size: $font-size__s;
     }

     /**
      * Because widget placement doesn't allow for page exclusions,
      * we have to manually "exclude" pages by hiding on body class
      */
     .checkout-index-index &,
     .sales-order-print &,
     .page-layout-empty &,
     body[class*="multishipping-checkout"] &,
     .paypal-express-review & {
         display: none !important; // Overwrite inline styles via JS
     }

     &.js-hide {
         display: none;
     }

     .container {
         position: relative;
     }

     &__cta {
         text-decoration: underline;
         color: inherit;

         @include hover {
             color: $primary__color;
         }

         &:visited {
             color: inherit;
         }
     }

     &__close {
         @include button--transparent;
         @include vertically-center($position: absolute);
         @include button--close($fill-color: $promobar__color);
         right: 0;
         width: 15px;
         height: 25px;

         svg {
             display: none;
         }

         @include min-screen($screen__m) {
             right: $layout-indent__width;
         }

         svg {
             display: none;
         }

         &::after {
             right: 0;
         }
     }
 }

 [data-content-type="html"] {
    &.orange,
    &.yellow {
        .promobar {
            color: $midnight;

            &__close {
                @include button--close($fill-color: $midnight);
            }
        }
    }

    &.orange .promobar {
        background: #ff943d;
    }

    &.yellow .promobar {
        background: #f5c43d;
    }
 }
