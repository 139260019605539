// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Collage right appearance styles
//  _____________________________________________

[data-appearance='collage-right'] {
    .pagebuilder-overlay {
        justify-content: flex-end;

        .pagebuilder-collage-content {
          text-align: right;
        }
    }

    .pagebuilder-slide-wrapper {
        .pagebuilder-overlay {
            margin-left: auto;
        }
    }
}
