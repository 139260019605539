// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

$b3: #b3b3b3;
$color-pagebuilder-darkest-gray: #1c1918;

//
//  Slick slider styles
//  _____________________________________________

.slick-slider {
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-touch-action: pan-y;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    box-sizing: border-box;
    display: block;
    position: relative;
    touch-action: pan-y;
    user-select: none;
}
.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-track {
    display: block;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    [dir='rtl'] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        border: 1px solid transparent;
        display: block;
        height: auto;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-prev {
    left: 0;

    @include lib-icon-font(
        $icon-arrow-left-thin,
        $_icon-font: $icons__font-name,
        $_icon-font-size: 23px,
        $_icon-font-line-height: 1,
        $_icon-font-text-hide: true,
        $_icon-font-margin: 0,
        $_icon-font-position: before
    );
}

.slick-next {
    right: 0;

    @include lib-icon-font(
        $icon-arrow-right-thin,
        $_icon-font: $icons__font-name,
        $_icon-font-size: 23px,
        $_icon-font-line-height: 1,
        $_icon-font-text-hide: true,
        $_icon-font-margin: 0,
        $_icon-font-position: before
    );
}

.slick-prev,
.slick-next {
    $circle-diameter: 25px;

    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary__color;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 0;
    height: $circle-diameter;
    width: $circle-diameter;
    line-height: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    transition: box-shadow 150ms;
    z-index: 2;

    &.slick-disabled {
        opacity: .2;
    }

    &:not(.slick-disabled) {
      &:hover,
      &:focus {
          box-shadow: $midnight-shadow;
      }
  }

    &:before,
    &:after {
        @extend .pagebuilder-icon;
        position: absolute;
        left: 0;
        width: $circle-diameter;
        color: $white;
        font-size: 15px;
    }
}

.slick-dots {
    bottom: -30px;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 100%;

    li {
        cursor: pointer;
        display: inline-block;
        height: 3px;
        margin: 0 2px;
        padding: 0;
        position: relative;
        vertical-align: middle;
        width: 45px;

        @include max-screen($screen__m) {
            width: 25px;
        }

        button {
            background: $midnight;
            border: none;
            border-radius: 0;
            box-shadow: none;
            cursor: pointer;
            display: block;
            height: 100%;
            padding: 0;
            text-indent: -99999px;
            transition: .3s;
            width: 100%;

            &:active,
            &:focus {
                box-shadow: none;
                outline: none;
            }
        }

        &.slick-active {
            button {
                background: $primary__color;
            }
        }
    }
}
