@include min-screen($mobile-nav__breakpoint) {
    .mega-menu {
        display: flex;

        .header.links {
            display: none;
        }

        > .menu-level {
            z-index: 100; // megamenu dropdown to display above all other elements
            margin: 0;
            padding: 0;

            // only first level menu items to display inline
            > .menu-item {
                position: relative;
                list-style: none;
                display: inline-block;
            }
        }

        .menu-item {
            margin: 0;

            // first level title + general styles
            .menu-item__link {
                border-bottom: $mega__menu-item__first__bottom-border;
                padding: $mega__menu-item__first__padding;
                margin: $mega__menu-item__first__margin;
                color: $mega__menu-item__text-color;
                z-index: 1;
    
                @include hover {
                    border-bottom: $mega__menu-item__first__bottom-border__hover;
                }
            }

            // first level if have dropdown, add icon + general styles
            &.has-dropdown > .menu-item__link {
                position: relative;
                padding: $mega__menu-item__first__has-dropdown__padding;

                @include icon-svg(
                    $icon       : $mega__menu-item__icon,
                    $fill       : $mega__menu-item__icon__color,
                    $hideLabel  : true,
                    $pseudo     : after,
                    $width      : $mega__menu-item__icon__width,
                    $height     : $mega__menu-item__icon__height
                );

                &::after {
                    position: absolute;
                    top: $indent__xs;
                }
            }

            // first level dropdown + general styles
            .menu-level {
                padding: 0;
                background-color: $mega__dropdown__bg-color;
                border-top: $mega__dropdown__first__top-border;
                border-bottom: $mega__dropdown__first__bottom-border;
                box-shadow: $mega__dropdown__shadow;
                opacity: 0;
                visibility: hidden;
                transition: opacity .3s;
                position: absolute;
                top: 100%;
                min-width: $mega__simple-dropdown__width;
            }

            // when menu item title hovered, js adds .open-submenu class and shows dropdown
            &.open-submenu > .menu-level {
                opacity: 1;
                visibility: visible;
            }

            // if simple dropdowns are present in the megamenu
            &.simple-dropdown .menu-level {
                // second level title
                .menu-item__link {
                    display: block;
                    padding: $mega__menu-item__second__padding;
                    margin: $mega__menu-item__second__margin;
                    border-bottom: $mega__menu-item__second__bottom-border;

                    @include hover {
                        border-bottom: $mega__menu-item__second__bottom-border__hover;
                        background-color: $mega__dropdown__second__bg-color__hover;
                    }
                }

                // second level containter
                .menu-item {
                    position: relative;

                    // second level if have dropdown, add icon + general styles
                    &.has-dropdown > .menu-item__link {
                        margin: 0;
                        // can add a new icon to second level here with svg mixin
        
                        &::after {
                            // but in this case we are just rotating the existing icon
                            transform: rotate(-90deg);
                            top: $indent__xs;
                            right: 0;
                        }
                    }
                }

                // second level dropdown
                .menu-level {
                    left: 100%;
                    top: -9px;
                    margin-left: 0;

                    // third level dropdown styles can go here
                }
            }
        }

        .pagebuilder-column [data-content-type="text"] {
            justify-content: center;
        }

        @include hover {
            > .menu-level > .menu-item.open-submenu .menu-item.open-submenu:not([data-expand-desktop="true"]) > .menu-level {
                top: 50px;

                [data-content-type="text"] {
                    flex-direction: column;
                    padding: $indent__s;
                }
            }

            .menu-item[data-expand-desktop="true"] {
                .menu-item__link {
                    pointer-events: none;
                    padding-left: 0;
                    color: $color-black;

                    &::after {
                        display: none;
                    }
                }

                .menu-level {
                    display: block;
                    opacity: 1;
                    visibility: visible;
                    background-color: $color-white;
                    box-shadow: none;
                    position: relative;
                    top: 0 !important;
                    left: 0 !important;
                    padding: 0;
                    z-index: 100;
                    transition: none;

                    [data-element="inner"] {
                        padding: 0 !important; // override PageBuilder
                    }

                    [data-content-type="text"] {
                        flex-direction: column;
                    }
                }
            }
        }
    }

    .mobile-nav-bar {
        display: none;
    }
}
