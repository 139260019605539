//
//  Variables
//  _____________________________________________

$collapsible-nav-background: $color-white !default;
$collapsible-nav-color: $text__color !default;
$collapsible-nav-current-border: 2px solid transparent !default;
$collapsible-nav-current-border-color: $primary__color !default;
$collapsible-nav-current-color: $collapsible-nav-color !default;
$collapsible-nav-current-font-weight: 400 !default;
$collapsible-nav-delimiter__border-color: $color-gray82 !default;
$collapsible-nav-item-hover: transparent !default;
$collapsible-nav-box-shadow: $midnight-shadow !default;
$collapsible-nav-item-side-padding: 15px;

//
//  Common
//  _____________________________________________

//
//  Collapsible navigation
//  -----------------------------------------

.block-collapsible-nav {
    background-color: $collapsible-nav-background;
    box-sizing: border-box;

    .heading {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.block-collapsible-nav-title {
    &--disabled {
        @include min-screen($screen__m + 1) {
            background: 0;
            pointer-events: none;
            border: 0;
            color: inherit;

            span {
                @include lib-visibility-hidden();
            }
        }
    }
}

.block-collapsible-nav-content {
    padding: 15px 0;

    .item {
        margin: 3px 0 0;

        &:first-child {
            margin-top: 0;
        }

        a,
        > strong {
            border-left: 4px solid transparent;
            display: block;
            padding: 8px $collapsible-nav-item-side-padding 8px;
            font-weight: $collapsible-nav-current-font-weight;
        }

        a {
            color: $collapsible-nav-color;
            text-decoration: none;

            @include hover {
                color: $azule;
                background-color: $collapsible-nav-item-hover;
            }
        }

        &.current {
            a,
            > strong {
                border-color: $collapsible-nav-current-border-color;
                font-weight: $collapsible-nav-current-font-weight;
            }

            a {
                border-color: $collapsible-nav-current-border-color;
            }
        }

        .delimiter {
            border-top: 1px solid $collapsible-nav-delimiter__border-color;
            display: block;
            margin: $indent__s $collapsible-nav-item-side-padding;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .block-collapsible-nav {
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        box-shadow: $collapsible-nav-box-shadow;
        z-index: 0;
        height: auto;
        transform: none;

        .heading {
            width: 100%;
        }
    }

    .block.block-collapsible-nav .block-collapsible-nav-title {
        position: relative;
        width: 100%;
        padding: $indent__base;
        text-align: left;
        text-transform: uppercase;
        color: inherit;
        background: transparent;
        border: 0; // reset border
        margin-bottom: 0;
        font-size: $font-size__s;
        display: block;
        @include icon-svg(
            $icon     : expand,
            $fill     : $azule,
            $width    : 15px,
            $height   : auto,
            $pseudo   : after
        );

        &::after {
            @include vertically-center($position: absolute);
            right: $indent__base;
        }

        &[aria-expanded="true"] {
            border-bottom: 1px solid $cloud;

            @include icon-svg(
                $icon     : collapse,
                $fill     : $azule,
                $width    : 15px,
                $height   : auto,
                $pseudo   : after
            );
        }

        &:focus,
        &:active {
            box-shadow: none;
        }
    }

    .block-collapsible-nav-content {
        border-bottom: $border-width__base solid $border-color__base;

        hidden {
            display: none;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block-collapsible-nav {
        .title {
            @include lib-visually-hidden;
        }
    }
}
