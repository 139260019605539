// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________

@include max-screen($screen__m) {
    .pagebuilder-column-group {
        flex-wrap: wrap;
    }
}
