.pagebuilder-homepage-grid {
    display: -ms-grid;
    display: grid;
    margin: 0 auto !important;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, minmax(170px, auto));
    color: $white;

    h3 {
        margin: 0;
        align-self: baseline;
        width: auto;
    }

    [data-content-type="text"] {
        line-height: 24px;
    }

    [data-content-type="button-item"] .pagebuilder-button-link {
        padding-left: 0;
        padding-right: 0;
        color: $white;
    }

    [data-content-type="column-group"] {
        height: 100%;
        width: 100%;
        background: linear-gradient(to top, #13171C, transparent);
        background-position: 0 80px;
        background-repeat: no-repeat;
        transition: background-position 100ms;
    }

    [data-content-type="column"] {
        justify-content: flex-end !important; // override Page Builder
    }

    .pagebuilder-homepage-grid-item {
        &:nth-child(2),
        &:nth-child(3) {
            h2 {
                @include gradient-heading();
                align-self: baseline;
            }

            [data-content-type="text"] {
                font-size: 18px;
            }

            @include hover {
                [data-content-type="column-group"] {
                    background-position: 0 0;
                }

                h2 {
                    @include gradient-heading($active: true);
                }

                [data-content-type="button-item"] .pagebuilder-button-link {
                    color: $ice;
                }
            }
        }

        // Mobile
        @include max-screen($screen__l) {
            grid-column: 1 / 3;

            [data-content-type="column"] {
                padding: 25px !important; // override Page Builder
                width: 100% !important; // override Page Builder
                min-height: 200px;
            }

            [data-content-type="button-item"] {
                width: 100%;
            }

            // Large boxes
            &:nth-child(2),
            &:nth-child(3) {
                [data-content-type="column-group"] {
                    min-height: 450px;
                }
            }

            &:nth-child(4) {
                order: 1;
            }

            &:nth-child(3) {
                order: 2;
            }

            &:nth-child(4) {
                [data-content-type="column"] {
                    &:last-child {
                        order: 1;
                    }

                    &:first-child {
                        order: 2;
                    }
                }
            }
        }

        // Desktop:
        @include min-screen($screen__l) {
            [data-content-type="column"] {
                min-height: 350px;
            }

            // Large boxes
            &:nth-child(2),
            &:nth-child(3) {
                display: flex;
                align-items: flex-end;
            }

            &:nth-child(1) {
                grid-column: 1 / 2;
                grid-row: 1;
            }
            &:nth-child(2) {
                grid-column: 2 / 3;
                grid-row: 1 / 3;
            }
            &:nth-child(3) {
                grid-column: 1 / 2;
                grid-row: 2 / 4;
            }
            &:nth-child(4) {
                grid-column: 2 / 3;
                grid-row: 3;
            }
        }
    }
}
