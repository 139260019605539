//
//  Common
//  _____________________________________________

html,
body {
    @include min-screen($mobile-nav__breakpoint) {
        height: 100%;
    }
}

body {
    background-color: $page__background-color;
}

//
//  Authorization banner
//  ---------------------------------------------

.authorize-banner {
    padding: $indent__s;
    text-align: center;
    background: $smoke-gradient;

    p {
        margin: 0;
        line-height: 2;
        color: $midnight;
    }

    &-button {
        @include lib-button-outlined(
            $_button-color: $midnight,
            $_button-color-hover: $white,
            $_button-gradient-color-start-hover: $button__gradient-color-start,
            $_button-gradient-color-end-hover: $button__gradient-color-end
        );
        margin: 0 $indent__s;
        padding: 13px $indent__base;

        @include max-screen($screen__m) {
            margin: $indent__xs 0;
            width: 100%;
        }
    }
}

//
//  Header
//  ---------------------------------------------

.page-header {
    background: $midnight-gradient;
    border: 0;
    box-shadow: 0 10px 20px rgba($midnight, .3);
    z-index: 2;

    .cms-index-index & {
        margin-bottom: 0;
    }

    .switcher,
    .action {
        font-size: $font-size__base;
    }
}

//
//  Account Navigation & Currency Switcher
//  ---------------------------------------------
.panel.header {
    @include layout-container;

    @include min-screen($mobile-nav__breakpoint) {
        @include lib-clearfix();
        padding-bottom: $indent__s;
        padding-top: $indent__s;
    }

    @include min-screen($screen__l) {
        padding: 10px 20px;
    }
}

.panel.header > .links,
.panel.header .switcher {
    display: none;

    @include min-screen($mobile-nav__breakpoint) {
        display: inline-block;
    }
}

.header.panel > .links {
    @include lib-list-inline();
    float: right;
    font-size: 0;
    margin-left: auto;
    color: $white;

    .oauth-login {
        display: inline-block;

        .action {
            font-size: $font-size__s;
            cursor: pointer;
        }
    }

    a {
        color: inherit;
        font-weight: $font-weight__semibold;

        &:hover,
        &:focus {
            color: $ice;
        }
    }

    .greet {
        color: $color-black;
        font-weight: $font-weight__bold;
        letter-spacing: 0;
    }

    .counter {
        &::before {
            content: '(';
        }

        &::after {
            content: ')';
        }
    }

    > li {
        font-size: $font-size__s;
        margin: 0 0 0 15px;

        &.welcome,
        a {
            line-height: 1.4;
        }

        &.welcome a {
            padding-left: $indent__xs;
        }
    }

    > .authorization-link {
        margin: 0;

        &::before {
            content: attr(data-label);
            display: inline-block;
            margin: 0 $indent__s;
            color: $cloud;
        }
    }

    > .customer-welcome + .authorization-link {
        display: none;
    }
}

.header.panel .switcher {
    float: right;
    margin-left: 15px;
    margin-right: -6px;
}

.customer-name,
.customer-welcome + .authorization-link {
    @include max-screen($mobile-nav__breakpoint) {
        display: none;
    }
}

.customer-welcome {
    @include min-screen($mobile-nav__breakpoint) {
        @include lib-dropdown(
          $_toggle-selector: '.action.switch',
          $_options-selector: 'ul.header.links',

          $_dropdown-actions-padding: 10px 0,
          $_dropdown-list-item-padding: 8px $indent__base,
          $_dropdown-list-item-type-hover: $azule,
          $_dropdown-list-shadow: $midnight-shadow,
          $_dropdown-list-pointer: false,
          $_dropdown-list-position-right: 0,
          $_dropdown-list-item-hover: transparent,
          $_dropdown-list-border: none,

          $_dropdown-toggle-icon-content: '\e622',
          $_dropdown-toggle-active-icon-content: '\e621',

          $_icon-font-text-hide: true,
          $_icon-font-size: 25px,
          $_icon-font-line-height: 22px,
          $_icon-font-color: $white,
          $_icon-font-color-hover: $white,
          $_icon-font-color-active: $white,
        );

        &.active .action.switch:before {
            content: '\e621';
        }

        .customer-name {
            cursor: pointer;
        }

        .customer-menu {
            display: none;

            .links {
                color: $text__color;
            }
        }

        .action.switch {
            @include lib-button-reset();
        }

        ul.header.links {
            min-width: 210px;
            right: 0;
            padding: 10px 0;

            a {
                font-size: $font-size__s;
                font-weight: $font-weight__regular;

                &:not(.no-display) {
                    display: block;
                }

                &:hover,
                &:focus {
                    color: $azule;
                }
            }
        }

        .link.wishlist .counter {
            &::before {
                content: '('
            }

            &::after {
                content: ')'
            }
        }

        &.active .customer-menu {
            display: block;
        }

        .greet {
            display: none;
        }
    }
}

//
//  Main Header Area
//  ---------------------------------------------

.header.content {
    @include layout-container();
    @include lib-clearfix();
    padding-top: $indent__s;
    position: relative;
    height: inherit;

    @include min-screen($mobile-nav__breakpoint) {
        padding: ($layout__width-xs-indent / 3) $layout__width-xs-indent 0;
    }

    @media (min-width:768px) and (max-width:880px) {
        top: -40%; // This corrects a case where the icons fall out of the header when resizing the window.
    }
}

.logo {
    float: left;
    margin: 0 0 $indent__base ($indent__s * 1.5);
    position: relative;
    z-index: 5;

    @include min-screen($mobile-nav__breakpoint) {
        margin: 0 auto 20px 0;
    }

    .page-print & {
        float: none;
    }

    img {
        display: block;
        width: 12rem;

        @include min-screen($mobile-nav__breakpoint) {
            width: 20rem;
        }
    }
}

.page-main > .page-title-wrapper .page-title {
    margin-top: 40px;

    @include min-screen($mobile-nav__breakpoint) {
        display: inline-block;
        margin-top: 60px;
    }

    + .action {
        margin-top: $indent__l;

        @include min-screen($mobile-nav__breakpoint) {
            float: right;
            margin-top: $indent__base;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @include lib-visually-hidden();
    }

    &:focus {
        background: $color-gray94;
        padding: $indent__s;
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

//
//  Footer
//  ---------------------------------------------
.footer-fullbleed {
    position: relative;
    background: $midnight-gradient;
    color: $white;
    box-shadow: 0 -10px 20px rgba($midnight, .3);

    &::before {
        content: '';
        display: block;
        height: 2px;
        background: $ice-gradient;
    }
}

.page-footer {
    @include layout-container;
    padding-top: 25px;
    padding-bottom: 75px;
    background-color: $footer__background-color;
    width: 100%;

    @include min-screen($screen__m) {
        display: flex;
        justify-content: space-between;
    }

    a {
        text-decoration: none;
        font-size: $font-size__base;
    }

    .footer.content {
        width: 100%;

        @include min-screen($mobile-nav__breakpoint) {
            width: 60%;
        }
    }

    .pagebuilder-collapsible .coll-header {
        border: 0;
    }

    .pagebuilder-button-link {
        font-weight: 300;
    }
}

.footer {
    &__connect {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include min-screen($screen__m) {
            max-width: 405px;
            order: 2;
        }
    }

    .switcher-store,
    .links {
        @include min-screen($mobile-nav__breakpoint) {
            display: inline-block;
            vertical-align: top;
            padding-right: 50px;
        }
    }

    .links {
        @include lib-list-reset-styles();

        @include min-screen($mobile-nav__breakpoint) {
            padding: 0 50px 0 0;
        }

        [data-content-type="heading"] {
            margin-top: 0;
            font-size: $font-size__s;
            font-weight: $font-weight__bold;
            letter-spacing: $heading__letter-spacing__large;
            text-transform: uppercase;
            color: $primary__color;
        }

        a {
            font-weight: $font-weight__regular;
            color: $white;

            @include hover {
                color: $primary__color;
            }
        }

        > li {
            margin-bottom: 25px;
        }
    }

    .switcher-store {
        margin: 0 0 30px;
    }

    .copyright,
    .bugs {
        color: $c-dusty-grey;
        display: block;
        margin: 20px 0 0;
        font-size: $font-size__s;

        @include max-screen($screen__m) {
            font-size: 10px;
        }
    }

    .copyright {
        @include min-screen($mobile-nav__breakpoint) {
            @include lib-clearfix();
        }
    }
}

.page-header .switcher,
.page-footer .switcher {
    margin-right: 10px;

    .options {
        @include lib-dropdown(
          $_dropdown-actions-padding           : 0,
          $_dropdown-list-item-padding         : 0,
          $_dropdown-toggle-icon-content       : $icon-down,
          $_dropdown-toggle-active-icon-content: $icon-up,
          $_icon-font-text-hide                : true,
          $_icon-font-size                     : 22px,
          $_icon-font-line-height              : 22px,
          $_dropdown-list-min-width            : 160px
          );

        ul.dropdown a {
            display: block;
            padding: 8px;

            @include hover {
                text-decoration: none;
            }
        }

        .action.toggle::before {
            float: right;
        }
    }

    li {
        font-size: $font-size__s;
        margin: 0;
    }

    .label {
        @include lib-visually-hidden();
    }

    strong {
        font-weight: $font-weight__regular;
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar .widget.block:not(:last-child),
.sidebar .widget:not(:last-child) {
    @extend .abs-margin-for-blocks-and-widgets;
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header .widget.block,
.page-footer .widget.block {
    margin: $indent__base 0;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker td {
    padding: 0;
}

//
//  SSO email confirmation
//  ---------------------------------------------

.auth-index-emailentry .page-main {
    margin-top: 50px;
    margin-bottom: 50px;
}

//
//  hide cookie notice
//  ---------------------------------------------
.cookie-status-message {
    display: none;
}
