$swatch__border-width: 1px !default;
$swatch-diameter: 20px !default;

.clearfix:after {
    content: '';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.swatch-attribute-label {
    font-weight: bold;
    position: relative;

    &.required {
        padding-right: 10px;
    }

    &[data-required="1"]::after {
        content: '*';
        color: $form-field-label-asterisk__color;
        position: absolute;
        right: -11px;
        top: -2px;
        font-weight: bold;
        font-size: 1em;
    }
}

.swatch-attribute-selected-option {
    position: relative;
    margin-left: 3px;
    padding-left: 7px;

    &:not(:empty):before {
        content: ':';
        position: absolute;
        left: 0;
    }
}

.swatch-attribute div.mage-error {
    margin-top: 10px;
}

.swatch-attribute-options {
    margin-top: $indent__s;
}

.swatch-option {
    height: $swatch-diameter;
    width: $swatch-diameter;
    float: left;
    margin: 0 7px 5px 0;
    text-align: center;
    cursor: pointer;
    position: relative;
    border: 1px solid $page__background-color;
    box-shadow: 0 0 0 1px $swatch__border-color;
    border-radius: 50px;
    overflow: hidden;
    text-overflow: ellipsis;

    &.text {
        margin: 0 7px 7px 0;
        padding: 0 $indent__s;
        width: auto;
        min-width: 8px;
        background: $white;
        color: $swatch-color;
        font-family: $font-family__secondary;
        font-size: $font-size__s;
        font-weight: $font-weight__bold;
        line-height: $swatch-diameter;
        -webkit-font-smoothing: antialiased;
    }

    &.selected,
    &:focus {
        box-shadow: 0 0 0 1px $swatch__selected-border-color;
        outline: none;
        color: $c-dark-grey;
    }

    &.text.selected {
        background-color: $color-white;
    }

    &:not(.disabled) {
        @include hover {
            box-shadow: 0 0 0 1px $swatch__selected-border-color;
            color: $c-dark-grey;
        }
    }

    &.disabled {
        cursor: default;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(
                to left top,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0) 42%,
                rgba(255, 255, 255, 1) 43%,
                rgba(255, 255, 255, 1) 46%,
                rgba(255, 82, 22, 1) 47%,
                rgba(255, 82, 22, 1) 53%,
                rgba(255, 255, 255, 1) 54%,
                rgba(255, 255, 255, 1) 57%,
                rgba(255, 255, 255, 0) 58%,
                rgba(255, 255, 255, 0) 100%
            );
        }
    }
}

.swatch-attribute {
    &.manufacturer,
    &.size {
        .swatch-option {
            background: $color-white;

            &.selected {
                color: $color-black;
                background: $color-white;
                border: 1px solid $color-white;
            }
        }
    }
}

.swatch-option-tooltip {
    max-width: 140px;
    max-height: 100%;
    min-height: 20px;
    min-width: 20px;
    position: absolute;
    padding: 5px;
    background: $color-white;
    color: $c-border;
    border: 1px solid currentColor;
    display: none;
    z-index: 999;
    text-align: center;
}

.swatch-option-tooltip,
.swatch-option-tooltip-layered {
    .corner {
        left: 40%;
        position: absolute;
        bottom: 0;
        height: 8px;

        &::before {
            content: '';
            position: relative;
            top: 2px;
            left: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 8.5px 0 8.5px;
            border-color: $c-border transparent transparent transparent;
            font-size: 1px;
        }

        &::after {
            content: '';
            position: relative;
            top: 1px;
            left: -15px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7px 7.5px 0 7.5px;
            border-color: $color-white transparent transparent transparent;
            font-size: 1px;
        }
    }

    .image {
        display: block;
        height: 130px;
        width: 130px;
        margin: 0 auto;
    }
}

.swatch-option-tooltip .title {
    max-width: 140px;
    min-height: 20px;
    max-height: 200px;
    color: $c-dark-grey;
    text-align: center;
    display: block;
    overflow: hidden;
}

.swatch-opt {
    margin: 20px 0;
}

.swatch-more {
    display: inline-block;
    margin: 2px 0;
    text-decoration: none !important;
    position: static;
    z-index: 1;
}

// Layered Features
.swatch-option-link-layered {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
    background: none;
}

.swatch-option-tooltip-layered {
    width: 140px;
    position: absolute;
    background: $color-white;
    color: $c-medium-grey;
    border: 1px solid currentColor;
    display: none;
    z-index: 999;
    left: -47px;
}

.swatch-visual-tooltip-layered {
    height: 160px;
    top: -170px;
}

.swatch-textual-tooltip-layered {
    height: 30px;
    top: -40px;
}

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
    display: block;
}

.swatch-attribute-options .swatch-option-tooltip-layered .title {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: -5px;
    left: 0;
    color: $c-dark-grey;
    text-align: center;
    margin-bottom: 10px;
}

.swatch-option-disabled:after {
    content: '';
    position: absolute;
    width: 42px;
    height: 2px;
    background: $error__color;
    transform: rotate(-30deg);
    z-index: 995;
    left: -4px;
    top: 10px;
}

.swatch-option-disabled {
    outline: none !important;
    cursor: default;
    border: 0;
}

// Bugfix for Add To Cart button
.swatch-opt-listing {
    margin-bottom: 10px;
}
.swatch-option-loading {
    // TODO: Remove check in v3.x.x.
    @if variable-exists(map-paths) {
        content: url(#{map-get($map-paths, 'Magento_Swatches/images/loader-2.gif')});
    } @else {
        content: url(../Magento_Swatches/images/loader-2.gif);
    }
}

.swatch-input {
    left: -1000px;
    position: absolute;
    visibility: hidden;
}

.size-chart {
    display: none;

    a {
        @include button--tertiary();
        padding: $indent__s 0;
    }
}

.swatch-attribute.size .size-chart {
    display: block;
    float: right;
}
