.anniversary-page {
    margin: 0;
    padding: 0;
    min-height: 100vh;

    .row-full-width-inner {
        width: 100%;
        max-width: none !important; // override Page Builder
    }

    &__background img {
        width: 100%;
    }
}
