.instagram-slider {
    .slick-prev {
        left: 40px;
    }

    .slick-next {
        right: 40px;
    }

    .pagebuilder-slide-wrapper,
    .pagebuilder-overlay {
        min-height: unset !important; // override Page Builder
    }

    .slick-slide * {
        height: 100%;
    }

    @include max-screen($screen__m) {
        .slick-prev {
            left: $indent__s;
        }

        .slick-next {
            right: $indent__s;
        }
    }
}
